import { Drawer, SwipeableDrawer } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowBack, ExpandLess, ExpandMore } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link, matchPath } from 'react-router-dom';
import { compose, withState } from 'recompose';

const drawerWidth = 280;

const Sidebar = ({
  anchor,
  onClose,
  open,
  location,
  highlight,
  setHighlight,
  Header,
  Footer,
  links,
  match,
  classNames,
  styles,
  toggleMenu,
  setToggleMenu,
  separatorColor,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const [expanded, setExpanded] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(!isMd);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
    setExpanded(null);
  };

  const handleClick = label => {
    expanded === label ? setExpanded(null) : setExpanded(label);
  };

  const isSuperAdmin = match && match.path === '/su/:organizationId';

  const CustomDrawer = isMobile ? SwipeableDrawer : Drawer;

  return (
    <aside>
      <CustomDrawer
        anchor={anchor}
        open={open}
        onOpen={props.onOpen}
        onClose={onClose}
        variant={isMobile ? 'temporary' : 'permanent'}
        classes={{
          root: clsx(classes.drawer, {
            [classes.drawerOpen]: isMobile || drawerOpen,
            [classes.drawerClose]: !isMobile && !drawerOpen,
          }),
          paper: clsx({
            [classes.drawerOpen]: isMobile || drawerOpen,
            [classes.drawerClose]: !isMobile && !drawerOpen,
          }),
        }}
      >
        {isSuperAdmin && (
          <Box className={classes.superAdminButton}>
            <List>
              <ListItem component={Link} to={'/'} className={classes.prominent}>
                <ListItemIcon className={classes.prominentIcon}>
                  <ArrowBack fontSize={'small'} color={'primary'} />
                </ListItemIcon>
                <ListItemText>Back to superadmin</ListItemText>
              </ListItem>
            </List>
            <Divider />
          </Box>
        )}
        <Box pt={isSuperAdmin && !isMd ? 6 : 0}>
          {Header && <Header compressed={!isMobile && !drawerOpen} location={location} {...props} />}
        </Box>
        {!isMobile && (
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerOpen} aria-label={drawerOpen ? 'Collapse sidebar' : 'Expand sidebar'}>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        )}
        {links && (
          <List component="nav" aria-label="main navigation" key="links">
            {links.map(
              (
                {
                  to,
                  exact,
                  externalLink,
                  queryParams,
                  label,
                  subPages,
                  icon,
                  target,
                  separator,
                  hideOnMobile,
                  NotificationComponent,
                },
                index
              ) => (
                <Fragment key={to || index}>
                  {separator ? (
                    <Divider />
                  ) : to || externalLink ? (
                    <Fragment>
                      {!hideOnMobile && to && (
                        <ListItem
                          className={classes.listItem}
                          component={Link}
                          to={{
                            pathname: to,
                            search: queryParams || location.search,
                          }}
                          onClick={onClose}
                          selected={
                            (!!matchPath(location.pathname, {
                              path: to,
                              exact: exact || false,
                              strict: false,
                            }) &&
                              true) ||
                            undefined
                          }
                          target={target}
                        >
                          <ListItemIcon>
                            <Icon>{icon}</Icon>
                          </ListItemIcon>
                          <ListItemText>
                            {label} {!!NotificationComponent && <NotificationComponent />}
                          </ListItemText>
                        </ListItem>
                      )}
                      {!hideOnMobile && externalLink && (
                        <a
                          href={externalLink}
                          target={'_blank'}
                          rel="noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <ListItem className={classes.listItem}>
                            <ListItemIcon>
                              <Icon>{icon}</Icon>
                            </ListItemIcon>
                            <ListItemText>
                              {label} {!!NotificationComponent && <NotificationComponent />}
                            </ListItemText>
                          </ListItem>
                        </a>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <ListItem button onClick={() => handleClick(label)}>
                        <ListItemIcon>
                          <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>
                          {label} {!expanded && !!NotificationComponent && <NotificationComponent />}
                        </ListItemText>
                        {expanded === label ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                    </Fragment>
                  )}
                  {subPages && (
                    <Collapse in={expanded === label} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {subPages.map(({ to, icon, label, hideOnMobile, target, NotificationComponent }) => (
                          <Fragment key={to}>
                            {!hideOnMobile && (
                              <ListItem
                                component={Link}
                                target={target}
                                to={to}
                                icon={icon}
                                onClick={onClose}
                                selected={
                                  !!matchPath(location.pathname, {
                                    path: to,
                                    exact: true,
                                    strict: false,
                                  }) || undefined
                                }
                                className={classes.nested}
                              >
                                <ListItemIcon>
                                  <Icon>{icon}</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                  {label} {!!NotificationComponent && <NotificationComponent />}
                                </ListItemText>
                              </ListItem>
                            )}
                          </Fragment>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Fragment>
              )
            )}
          </List>
        )}
        {Footer && (
          <Box mt="auto">
            <Footer sidebarOpen={drawerOpen} {...props} />
          </Box>
        )}
      </CustomDrawer>
    </aside>
  );
};

const useStyles = makeStyles(theme => ({
  listItem: {
    color: 'inherit',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: 'inherit',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  prominent: {
    color: theme.palette.primary.main,
  },
  prominentIcon: {
    opacity: 1,
  },
  superAdminButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default compose(
  withRouter,
  withState('highlight', 'setHighlight'),
  withState('toggleMenu', 'setToggleMenu', false)
)(Sidebar);
