import React, { FC, Ref, useEffect, useRef, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import NoteAdd from '@material-ui/icons/NoteAdd';
import useClickAway from '../../../../../hooks/useClickAway';
import { useStaffSlotContext } from './StaffSlotContext';
import { useStyles } from './StaffShiftTime';
import { useStaffShiftStore } from './StaffShiftChip';
import { useStore } from 'zustand';

interface StaffMemberProps {
  assignedShiftId?: number;
  additionalText?: string;
  hideText?: boolean;
  editableStaff?: boolean;
}

const StaffShiftText: FC<StaffMemberProps> = ({ hideText, assignedShiftId, additionalText, editableStaff }) => {
  const classes = useStyles();
  const defaultValue = additionalText ? additionalText : '';
  const [localValue, setLocalValue] = useState<string | undefined>();

  useEffect(() => {
    if (localValue) {
      const timeout = setTimeout(() => {
        setLocalValue(undefined);
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [localValue]);

  const inputRef = useRef<HTMLInputElement>();

  const staffShiftStore = useStaffShiftStore();

  const mouseDownRef = useRef(false);

  const onTextClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    mouseDownRef.current = true;
    document.addEventListener(
      'mouseup',
      () => {
        mouseDownRef.current = false;

        const wrapper = wrapperRef.current;

        if (!wrapper) {
          return;
        }

        const input = wrapper.querySelector('input');

        if (!input) {
          return;
        }

        input.focus();
      },
      {
        once: true,
      }
    );
    editableStaff && staffShiftStore.setState({ editText: true, editTime: false });
  };

  const focusInput = () => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    const input = wrapper.querySelector('input');

    if (!input) {
      return;
    }

    if (document.activeElement === input) {
      return;
    }

    input.focus();
  };

  const onTimeTextClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    mouseDownRef.current = true;
    document.addEventListener(
      'mouseup',
      () => {
        mouseDownRef.current = false;

        focusInput();
      },
      {
        once: true,
      }
    );
    editableStaff && staffShiftStore.setState({ editText: true, editTime: false });
  };

  const onClickAway = async () => {
    const input = inputRef?.current;

    if (!mouseDownRef.current) {
      staffShiftStore.setState({ editText: false });
    }

    if (input) {
      setLocalValue(input.value);
      await saveAdditionalText(input.value);
    } else {
      const wrapper = wrapperRef.current;

      if (wrapper) {
        const input = wrapper.querySelector('input');

        if (input) {
          setLocalValue(input.value);
          await saveAdditionalText(input.value);
        }
      }
    }
  };

  const wrapperRef = useRef<HTMLElement>();
  useClickAway(wrapperRef, onClickAway);

  const editText = useStore(staffShiftStore, state => state.editText);

  useEffect(() => {
    if (editText) {
      requestAnimationFrame(() => {
        focusInput();
      });
    }
  }, [editText]);

  const showTextIcon = !hideText && !editText && assignedShiftId && !additionalText;
  const showText = !editText && (localValue || additionalText);

  const staffSlotContext = useStaffSlotContext();

  const saveAdditionalText = async (additionalText: string | undefined) => {
    assignedShiftId && (await staffSlotContext.setAdditionalText(assignedShiftId, additionalText));
  };

  const onInputChange = async (additionalText: string) => {
    setLocalValue(additionalText);
    await saveAdditionalText(additionalText);
    staffShiftStore.setState({ editText: false });
  };

  return (
    <>
      {showTextIcon && (
        <IconButton size="small" className={classes.noteIcon} onMouseDown={onTextClick}>
          <NoteAdd fontSize="small" />
        </IconButton>
      )}

      {editText && (
        <div ref={wrapperRef as Ref<HTMLDivElement>}>
          <input
            defaultValue={defaultValue}
            ref={inputRef as Ref<HTMLInputElement>}
            maxLength={15}
            onClick={e => e.stopPropagation()}
            onKeyPress={async (e: React.KeyboardEvent<HTMLInputElement>) => {
              const key = e.key;
              if (key === 'Enter') {
                //@ts-ignore
                await onInputChange(e.target?.value);
              }
            }}
            autoFocus={true}
            className={classes.noteInput}
          />
        </div>
      )}

      {showText && (
        <Typography className={classes.noteText} variant="caption" onClick={onTimeTextClick}>
          {localValue ?? additionalText}
        </Typography>
      )}
    </>
  );
};

export default StaffShiftText;
