import { compose, withProps } from 'recompose';
import { withLabel } from '../../../../se/components/Label';
import ReportRecipients from '../../sensor/ReportRecipients';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';

const AnalyticsConfigInputV1 = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),
  },
})(ObjectInput);

export default AnalyticsConfigInputV1;
