import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { listAllGateways } from '../../../graph/gateways';
import React from 'react';
import { getColumns } from '../../pages/gateway/Gateways';
import { withProps } from 'recompose';
import { DefaultTitle } from '../../../se/components/collection/CollectionList';
import Filters from '../../pages/analytics/Filters';
import { NamedRange } from '../../core/DatePicker';
import { TruncatedText } from '../../../se/components/typography';
import TitleAction from '../../../se/components/TitleAction';
import { HospitalGateway } from '../../../types/Gateway';

const Text = TruncatedText as any;

export default GraphQLCRUD({
  entityName: 'HospitalGateway',
  List: {
    TitleAndActions: () => <TitleAction Title={() => <DefaultTitle>Gateways</DefaultTitle>} />,
    Title: () => <div />,
    tableKey: 'HospitalGateway',
    columns: [
      {
        title: 'Hospital',
        lens: (data: HospitalGateway) => data.hospital?.name,
        Component: (props: { data: string }) => <Text>{props.data}</Text>,
      },
      ...getColumns(true),
    ],
    FilterComponent: withProps({
      hidePhysicianSelectInput: true,
      hideProcedureTypeSelectInput: true,
      hideHospitalSelectInput: false,
      hideDateFilter: true,
    })(Filters),
    defaultFilterValues: {
      dateRange: NamedRange.lastSevenDays(),
    },
  },
  scheme: {
    list: listAllGateways,
  },
});
