import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import { getPatient, mapGraphQLData, mapPatientData } from './pacuAndPrep/mapPatients';
import PatientScreen from './pacuAndPrep/PatientScreen';
import PatientList from './pacuAndPrep/PatientList';
import withAssignBedMutation from './pacuAndPrep/withAssignBedMutation';
import withSetPatientReadyInPACU from './pacuAndPrep/withSetPatientReadyInPACU';
import withSetPatientReadyInPREP from './pacuAndPrep/withSetPatientReadyInPREP';
import withSetPatientNotReadyInPREP from './pacuAndPrep/withSetPatientNotReadyInPREP';
import withSetPatientNotReadyInPACU from './pacuAndPrep/withSetPatientNotReadyInPACU';
import withDischargeMutation from './pacuAndPrep/withDischargeMutation';
import withDischargeLastPhysicianPatientMutation from './pacuAndPrep/withDischargeLastPhysicianPatient';
import { listActivePatients, listPacuPrepPostopPatients } from '../../../graph/patients';
import { item as roomItem } from '../../../graph/rooms';
import {
  getBeds,
  getLogEntries,
  isBlockNerveFn,
  isHoldProcedureFn,
  isPatientReady as isPatientReadyFn,
  isPatientReadyForSurgeonFn,
  isPreOpAsPacu,
  isReadyForFamilyPreOp as isReadyForFamilyPreOpFn,
  isReadyForPickupPACU as isReadyForPickupPACUFn,
  isReadyForPickupPOSTOP as isReadyForPickupPOSTOPFn,
} from './tablet/utils';
import ClientUpdater, { ClientUpdaterPresetTvInternal } from '../../ClientUpdater';
import { addFamilyReadyPACU, addFamilyReadyPOSTOP } from '../../entities/patient/transducers';
import withSetPatientReadyForSurgeon from './pacuAndPrep/withSetPatientReadyForSurgeon';
import withSetPatientNotReadyForSurgeon from './pacuAndPrep/withSetPatientNotReadyForSurgeon';
import withMoveToOrMutation from './pacuAndPrep/withMoveToOrMutation';
import get from 'lodash/get';
import update from 'lodash/fp/update';
import map from 'lodash/fp/map';
import withSetFamilyReadyForPickupPACU from './pacuAndPrep/withSetFamilyReadyForPickupPACU';
import styled from 'styled-components';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import readyToPickup from '../../../assets/sound/readyToPickup.mp3';
import { getNestedValue } from '../../../se/utilities/data/object';
import { soundAlert } from './monitor/Monitor';
import { screenConfiguration } from '../../../graph/screens';
import { PACU, POST_OP, PRE_OP } from '../../entities/room/enums';
import withHoldProcedure from './pacuAndPrep/withHoldProcedure';
import withUndoHoldProcedure from './pacuAndPrep/withUndoHoldProcedure';
import withBlockNerve from './pacuAndPrep/withBlockNerve';
import withUndoBlockNerve from './pacuAndPrep/withUndoBlockNerve';
import withSetPatientNotReadyInPOSTOP from './pacuAndPrep/withSetPatientNotReadyInPOSTOP';
import withSetPatientReadyInPOSTOP from './pacuAndPrep/withSetPatientReadyInPOSTOP';
import withSetFamilyReadyForPickupPOSTOP from './pacuAndPrep/withSetFamilyReadyForPickupPOSTOP';
import withAssignPriorityMutation from './pacuAndPrep/withAssignPriorityMutation';
import {
  withMarkAsNotReadyToSeeFamilyPreOp,
  withMarkAsReadyToSeeFamilyPreOp,
} from './pacuAndPrep/withMarkAsReadyToSeeFamilyPreOp';
import { withScope } from '../../../contexts/ScopeContext';

// Needed to solve safari overflow issues
const Wrap = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

class RoomTabletApp extends React.Component {
  state = { selectedPatient: null };

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (!this.props.loading) {
      if (this.props.roomType === PACU || this.props.roomType === POST_OP) {
        if (this.readyToPickupNumberFromProps(nextProps) > this.readyToPickupNumberFromProps(this.props)) {
          this.readyToPickupAlert();
        }
      }
    }
  }

  readyToPickupAlert = () => {
    soundAlert(readyToPickup, getNestedValue('screenConfiguration.readyToPickupSound', this.props));
  };

  readyToPickupNumberFromProps = props => props.patients.filter(_ => !!_.caretakerMessages).length;

  selectPatient = selectedPatient => this.setState({ selectedPatient });

  goBack = () => this.setState({ selectedPatient: null });

  assignBedToSelectedPatient = async bedNumber => {
    if (this.state.selectedPatient) {
      await this.props.assignBedMutation({
        variables: {
          id: this.state.selectedPatient,
          bed: `${bedNumber}`,
        },
      });
    }
  };

  assignPriorityToSelectedPatient = async priority => {
    if (this.state.selectedPatient) {
      await this.props.assignPriorityMutation({
        variables: {
          id: this.state.selectedPatient,
          priority: `${priority}`,
        },
      });
    }
  };

  markAsReady = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setPatientReadyInPREP;
    } else if (this.props.roomType === PACU) {
      fn = this.props.setPatientReadyInPACU;
    } else if (this.props.roomType === POST_OP) {
      fn = this.props.setPatientReadyInPOSTOP;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  markFamilyReady = async () => {
    let fn = undefined;
    if (this.props.roomType === PACU) {
      fn = this.props.setFamilyReadyPACU;
    } else if (this.props.roomType === POST_OP) {
      fn = this.props.setFamilyReadyPOSTOP;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  markAsNotReady = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setPatientNotReadyInPREP;
    } else if (this.props.roomType === PACU) {
      fn = this.props.setPatientNotReadyInPACU;
    } else if (this.props.roomType === POST_OP) {
      fn = this.props.setPatientNotReadyInPOSTOP;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  markAsReadyForSurgeon = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setPatientReadyForSurgeon;
    }

    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  markAsNotReadyForSurgeon = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setPatientNotReadyForSurgeon;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  dischargePatient = async () => {
    if (this.state.selectedPatient) {
      await this.props.dischargePatient({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  dischargePhysicianLastPatient = async () => {
    if (this.state.selectedPatient) {
      await this.props.dischargePhysicianLastPatient({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  moveToOr = async roomId => {
    if (this.state.selectedPatient) {
      await this.props.moveToOr({
        variables: {
          id: this.state.selectedPatient,
          roomId: roomId,
        },
      });
    }
  };

  holdProcedure = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setHoldProcedure;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  undoHoldProcedure = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setUndoHoldProcedure;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  blockNerve = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setBlockNerve;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  undoBlockNerve = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setUndoBlockNerve;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  setReadyToSeeFamilyPreOp = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setReadyToSeeFamilyPreOp;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  setNotReadyToSeeFamilyPreOp = async () => {
    let fn = undefined;
    if (this.props.roomType === PRE_OP) {
      fn = this.props.setNotReadyToSeeFamilyPreOp;
    }
    if (fn && this.state.selectedPatient) {
      await fn({
        variables: {
          id: this.state.selectedPatient,
        },
      });
    }
  };

  render() {
    const { patients, roomType, hospitalOverviewMode } = this.props;
    const patientPickupMessage = get(this.props, 'getPatientPickupMessage[0].content', false);
    const readyForFamilyMessage = get(this.props, 'getReadyForFamilyMessage[0].content', false);
    const hasNoteModule = this.props?.scope?.hospital?.modules?.noteTablet || false;
    const hasPatientCharting = this.props?.scope?.hospital?.modules?.patientCharting || false;

    const isPreOp = roomType === PRE_OP;
    const isPacu = roomType === PACU;
    const isPostOp = roomType === POST_OP;

    const hasPreOpPriorityModule = isPreOp
      ? this.props?.scope?.hospital?.modules?.preOpPriority?.hasPreOpPriority || false
      : false;
    const priorityCounter = isPreOp ? this.props?.scope?.hospital?.modules?.preOpPriority?.priorityCounter || 0 : false;

    if (this.state.selectedPatient) {
      const patient = getPatient(patients, this.state.selectedPatient);
      const patientLogs = getLogEntries(patient);
      const isPatientReady = isPatientReadyFn(patientLogs)(roomType);
      const isReadyToSeeFamilyPreOp = isReadyForFamilyPreOpFn(patientLogs)(roomType);
      const isReadyForPickupPACU = isReadyForPickupPACUFn(patientLogs);
      const isReadyForPickupPOSTOP = isReadyForPickupPOSTOPFn(patientLogs);
      const isReadyForSurgeon = isPreOp && isPatientReadyForSurgeonFn(patientLogs);
      const isHoldProcedure = isPreOp && isHoldProcedureFn(patientLogs);
      const isBlockNerve = (isPreOp || isPostOp || isPacu) && isBlockNerveFn(patientLogs);

      const hasCaretakerConsent = get(patient, 'caretakerConsent', false);

      return (
        <Wrap style={{ display: 'block' }}>
          <PatientScreen
            patientPickupMessage={patientPickupMessage}
            readyForFamilyMessage={readyForFamilyMessage}
            headerData={{ goBack: this.goBack, patient }}
            roomType={this.props.roomType}
            room={this.props.room}
            bodyData={{
              hasNoteModule,
              hasPreOpPriorityModule,
              hasPatientCharting,
              priorityCounter,
              assignBed: true,
              markAsReady: true,
              markAsFamilyReady: true,
              markAsFamilyReadyPreOp: isPreOpAsPacu(roomType, patientLogs),
              markAsReadyForSurgeon: isPreOp,
              dischargePatient: true,
              moveToOR: isPreOp,
              cancelVisit: isPreOp,
              holdProcedure: isPreOp,
              blockNerve: isPreOp || isPacu || isPostOp,
              isPatientReady,
              isReadyToSeeFamilyPreOp,
              isReadyForSurgeon,
              isFamilyReady: isPacu ? isReadyForPickupPACU : isPostOp ? isReadyForPickupPOSTOP : false,
              isHoldProcedure,
              isBlockNerve,
              onAssignBed: this.assignBedToSelectedPatient,
              onAssignPriority: this.assignPriorityToSelectedPatient,
              onMarkAsReady: this.markAsReady,
              hasCaretakerConsent,
              onMarkFamilyReady: isPacu || isPostOp ? this.markFamilyReady : undefined,
              onMarkAsNotReady: this.markAsNotReady,
              onMarkAsReadyForSurgeon: this.markAsReadyForSurgeon,
              onMarkAsNotReadyForSurgeon: this.markAsNotReadyForSurgeon,
              onHoldProcedure: this.holdProcedure,
              onUndoHoldProcedure: this.undoHoldProcedure,
              onBlockNerve: this.blockNerve,
              onUndoBlockNerve: this.undoBlockNerve,
              onMarkAsReadyToSeeFamilyPreOp: this.setReadyToSeeFamilyPreOp,
              onMarkAsNotReadyToSeeFamilyPreOp: this.setNotReadyToSeeFamilyPreOp,
              onDischargePatient: this.dischargePatient,
              onDischargePhysicianLastPatient: this.dischargePhysicianLastPatient,
              onMoveToOr: this.moveToOr,
              onCancelVisit: this.cancelVisit,
              goBack: this.goBack,
              beds: getBeds(patients, this.props.room.capacity),
            }}
          />
        </Wrap>
      );
    }
    return (
      <Fragment>
        <PatientList
          room={this.props.room}
          showReadyForSurgeon={isPreOp}
          showHoldProcedure={isPreOp}
          showBlockNerve={isPreOp}
          showBlockNervePacu={isPacu || isPostOp}
          patients={patients}
          selectPatient={this.selectPatient}
          roomName={this.props.room.name}
          roomType={this.props.roomType}
          hospitalOverviewMode={hospitalOverviewMode}
          hasPreOpPriorityModule={hasPreOpPriorityModule}
          hasNoteModule={hasNoteModule}
        />
        {patients && patients.length === 0 && <ClientUpdater {...ClientUpdaterPresetTvInternal} />}
      </Fragment>
    );
  }
}

export default roomType =>
  compose(
    mapProps(({ ...rest }) => ({
      ...rest,
      roomType,
    })),
    graphql(roomItem, {
      options: props => ({ variables: { id: Number(props.match.params.roomId || props.roomId) } }),
      skip: props => !props.match.params.roomId && !props.roomId,
    }),
    mapProps(({ data, ...rest }) => ({
      ...mapGraphQLData('room')(data),
      ...rest,
    })),
    roomType ? graphql(listPacuPrepPostopPatients) : graphql(listActivePatients),
    mapProps(({ data, ...rest }) => ({
      ...mapGraphQLData('patients')(data),
      ...rest,
    })),
    mapProps(({ patients, ...rest }) => ({
      patients: mapPatientData(patients),
      ...rest,
    })),
    graphql(getNotificationTemplate, {
      options: props => ({ variables: { trigger: 'FamilyReady' } }),
    }),
    mapProps(({ data, ...rest }) => ({
      ...mapGraphQLData('getNotificationTemplate', 'getPatientPickupMessage')(data),
      ...rest,
    })),
    graphql(getNotificationTemplate, {
      options: props => ({ variables: { trigger: 'PatientReadyForFamily' } }),
    }),
    mapProps(({ data, ...rest }) => ({
      ...mapGraphQLData('getNotificationTemplate', 'getReadyForFamilyMessage')(data),
      ...rest,
    })),
    graphql(screenConfiguration),
    mapProps(({ data, ...rest }) => ({
      ...mapGraphQLData('screenConfiguration')(data),
      ...rest,
    })),
    withAssignBedMutation,
    withAssignPriorityMutation,
    withSetPatientReadyInPACU,
    withSetPatientReadyInPOSTOP,
    withSetPatientReadyInPREP,
    withSetPatientReadyForSurgeon,
    withSetFamilyReadyForPickupPACU,
    withSetFamilyReadyForPickupPOSTOP,
    withHoldProcedure,
    withUndoHoldProcedure,
    withBlockNerve,
    withUndoBlockNerve,
    withMarkAsReadyToSeeFamilyPreOp,
    withMarkAsNotReadyToSeeFamilyPreOp,
    withSetPatientNotReadyForSurgeon,
    withSetPatientNotReadyInPREP,
    withSetPatientNotReadyInPACU,
    withSetPatientNotReadyInPOSTOP,
    withDischargeMutation,
    withDischargeLastPhysicianPatientMutation,
    withMoveToOrMutation,
    withScope,
    mapProps(({ patients, scope, ...props }) => ({
      scope,
      ...props,
      patients: props.roomType
        ? props.roomType === 'POST_OP'
          ? patients.filter(_ => _.room.type === props.roomType && _.room.name === props.room.name)
          : patients.filter(_ => _.room.type === props.roomType)
        : patients,
    })),
    mapProps(update('patients', map(addFamilyReadyPACU))),
    mapProps(update('patients', map(addFamilyReadyPOSTOP)))
  )(RoomTabletApp);
