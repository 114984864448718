import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const XAxis = ({ children }: { children: any }) => {
  const classes = useStyles();

  return <Box className={classes.xAxis}>{children}</Box>;
};

const useStyles = makeStyles((theme: Theme) => ({
  xAxis: {
    display: 'flex',
    flex: '1 0 0',
    marginLeft: '4em',
    marginBottom: '0.5em',
    zIndex: 7,

    [theme.breakpoints.down('md')]: {
      marginLeft: '2.5em',
    },
  },
}));

export default XAxis;
