import React, { Dispatch, FC, SetStateAction } from 'react';
import { getDisposition } from './Procedure';
import { Procedure as ProcedureT, Procedure } from '../../../../../types/Procedure';
import { differenceInHours, format, getHours, setHours, setMinutes, setSeconds } from 'date-fns';
import { currentDateInZone } from '../../../../../util/dateTime';
import { useScope } from '../../../../../hooks/useScope';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import StaffShifts from '../staff/StaffShifts';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import { StaffShiftRoomContext } from '../staff/StaffShiftContext';
import { StaffSlotRoomContext } from '../staff/StaffSlotContext';
import { Room } from '../../../../../types/Room';
import OperatingRoomProcedures from './OperatingRoomProcedures';
import { Time } from '../../../../entities/schedule/util/time';
import { Anesthesiologist } from '../anesthesiologist/PersonnelRoom';
import { fullNameToLastNameRest } from '../shared/procedureUtils';
import isEmpty from 'lodash/fp/isEmpty';
import { useStylesOverview } from '../overview/Overview';
import { PrimarySpecialty } from '../../../../../types/StaffMember';
import Root from './components/Root';
import Tick from './components/Tick';
import Grid from './components/Grid';
import XAxis from './components/XAxis';
import YAxis from './components/YAxis';
import Rooms from './components/Rooms';
import Cursor from './components/Cursor';
import Dot from './components/Dot';

const Timeline: FC<{
  currentOR?: string;
  isKiosk?: boolean;
  isFrontDesk?: boolean;
  editableStaff?: boolean;
  operationRooms: Room[];
  canUseScheduleViewProcedure?: boolean;
  isPowerUser?: boolean;
  date: Date;
  setEditProcedure?: Dispatch<SetStateAction<ProcedureT | null>>;
  showBedNumber?: boolean;
  scheduleStaffList?: any;
  showOverlay: boolean;
  editMode: boolean;
  openProcedureForm?: () => void;
  startTime: Time;
  duration: number | null | undefined;
  setStartTime?: (val: Time) => void;
  setDuration?: (val: number | null | undefined) => void;
  condensView?: boolean;
  unassignedProcedures: Procedure[];
  hasMoreThan6ORsView: boolean;
  showGraphs?: boolean;
  isAnesthesiologistSchedule?: boolean;
}> = ({
  currentOR,
  isFrontDesk,
  isKiosk,
  editableStaff,
  operationRooms,
  canUseScheduleViewProcedure,
  isPowerUser,
  date,
  setEditProcedure,
  showBedNumber,
  scheduleStaffList,
  showOverlay = false,
  editMode,
  openProcedureForm,
  startTime,
  duration,
  setStartTime,
  setDuration,
  condensView,
  unassignedProcedures,
  hasMoreThan6ORsView,
  showGraphs,
  isAnesthesiologistSchedule,
}) => {
  const canAddStaff = !isKiosk && editableStaff;
  const dayStart = setSeconds(setMinutes(setHours(date, 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(date, 19), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scope = useScope();
  const tz = scope?.hospital.timezone.id;
  const hospitalId = scope?.hospital.id;
  const editableAnesthesiologist = !isKiosk && !isFrontDesk && editableStaff;

  const hasMoreThan6ORs = isKiosk ? false : operationRooms.length + (!isEmpty(unassignedProcedures) ? 1 : 0) > 6;

  const classes = useStylesOverview();

  const timelineContent = (
    <Root isKiosk={isKiosk} hasMoreThan6ORs={hasMoreThan6ORs} hasMoreThan6ORsView={hasMoreThan6ORsView}>
      <Rooms
        columns={isMobile ? 1 : operationRooms.length}
        hasMoreThan6ORs={hasMoreThan6ORs}
        hasMoreThan6ORsView={hasMoreThan6ORsView}
      >
        {operationRooms
          .filter(room => (isMobile ? room.name === currentOR : true))
          .map(room => {
            const { id, name, procedures, staffShifts } = room;
            const anesthesiologistName = fullNameToLastNameRest(
              room?.anesthesiologistShifts?.[0]?.anesthesiologist?.name
            );
            const anesthesiologistShiftRoomId = room?.anesthesiologistShifts?.[0]?.id;
            const proceduresInRoom = procedures?.filter(procedure => procedure?.orId === room?.id);

            return (
              <StaffShiftRoomContext
                key={id || name}
                hospitalId={hospitalId}
                roomId={id}
                date={format(date, 'YYYY-MM-DD')}
                staffShifts={staffShifts || []}
              >
                <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                  <Box className={classes.staffWrapper}>
                    {/* @ts-ignore */}
                    <Box className={classes.roomHeader}>
                      {!isAnesthesiologistSchedule && (
                        <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                          {canAddStaff && (
                            <Box mr={1}>{!!id && <StaffShiftMenu defaultPrimarySpecialty={PrimarySpecialty.OR} />}</Box>
                          )}
                        </StaffSlotRoomContext>
                      )}
                      <Box flex={1} textAlign="center">
                        <Typography variant="body2">{name}</Typography>
                      </Box>
                      <Box mr={1}>
                        {!!id && (
                          <Anesthesiologist
                            date={date}
                            roomId={id}
                            condensView={condensView}
                            editableAnesthesiologist={editableAnesthesiologist}
                            anesthesiologistName={anesthesiologistName}
                            anesthesiologistShiftRoomId={anesthesiologistShiftRoomId}
                            procedureQuery={proceduresInRoom}
                            roomType={room?.type}
                          />
                        )}
                      </Box>
                    </Box>
                    {!isAnesthesiologistSchedule && (staffShifts || []).length !== 0 && (
                      <div style={{ marginRight: 20 }}>
                        <StaffShifts staffShifts={staffShifts || []} editableStaff={editableStaff} isKiosk={isKiosk} />
                      </div>
                    )}
                  </Box>
                </StaffSlotRoomContext>
              </StaffShiftRoomContext>
            );
          })}
      </Rooms>
      <Grid isKiosk={isKiosk} hasMoreThan6ORs={hasMoreThan6ORs} hasMoreThan6ORsView={hasMoreThan6ORsView}>
        <YAxis>
          {hours.map(tick => (
            <Tick key={tick}>{tick}</Tick>
          ))}
        </YAxis>
        <Cursor y={getDisposition(dayStart, currentDateInZone(tz), dayEnd)}>
          <Dot />
        </Cursor>
        <XAxis>
          {operationRooms.map((room, i) => (
            <OperatingRoomProcedures
              key={room?.id || room?.name}
              operationRooms={operationRooms}
              index={i}
              date={date}
              room={room}
              currentOR={currentOR}
              isKiosk={isKiosk}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
              isFrontDesk={isFrontDesk}
              isPowerUser={isPowerUser}
              showOverlay={showOverlay}
              editMode={editMode}
              showBedNumber={showBedNumber}
              setEditProcedure={setEditProcedure}
              dayStart={dayStart}
              dayEnd={dayEnd}
              openProcedureForm={openProcedureForm}
              startTime={startTime}
              duration={duration}
              setStartTime={setStartTime}
              setDuration={setDuration}
              anesthesiologistSchedule={isAnesthesiologistSchedule}
            />
          ))}
        </XAxis>
      </Grid>
    </Root>
  );

  if (isKiosk && scheduleStaffList) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: !!scheduleStaffList ? 'row' : 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {timelineContent}
        {scheduleStaffList}
      </div>
    );
  }
  return timelineContent;
};

export default Timeline;
