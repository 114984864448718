import { alpha, Card, CardContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

export const ListItemLink = ({ children, ...props }) => {
  return (
    <Card {...props}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 41%;
  i {
    font-size: 2rem;
    color: ${p => p.theme.textColor.string()};
  }
`;

export const ArrowIcon = () => (
  <IconWrapper>
    <KeyboardArrowRight fontSize={'inherit'} />
  </IconWrapper>
);
