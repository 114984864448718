import React, { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import useLocalStorage from 'use-localstorage-hook';
import { getSortedList, nextType } from './listHelpers';
import { isFunction } from '../../utilities/check';
import ListHeader from './ListHeader';
import MobileListView from './MobileListView';
import Pager from './Pager';
import SimpleTextFilter from './SimpleTextFilter';
import keyBy from 'lodash/keyBy';
import { isEmpty } from 'lodash';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import SelectionContext from '../../../features/selection/SelectionContext';
import SelectionView from '../../../features/selection/SelectionView';
import BulkPrintView from '../../../components/entities/procedures/BulkPrintView';

const useStyles = makeStyles(theme => ({
  flexWrapper: {
    minHeight: 0,
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  container: {
    height: '100%',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.secondary,
  },
  sortable: {
    cursor: 'pointer',
  },
}));

let prev;

const hasRenderFunction = node => !!node?.type?.render;

export const Scroller = ({ collection, id, children }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const thisRef = ref.current;
      function handleMouseUp() {
        prev = { collection, id };
      }
      thisRef.addEventListener('mouseup', handleMouseUp);
      return () => thisRef.removeEventListener('mouseup', handleMouseUp);
    }
  }, [collection, id]);

  useEffect(() => {
    if (prev && prev.collection === collection && prev.id === id) {
      if (ref.current) {
        if (ref.current.previousElementSibling) {
          ref.current.previousElementSibling.scrollIntoView();
        } else {
          ref.current.scrollIntoView();
        }
      }
    }
  }, [collection, id]);

  return React.cloneElement(children, { ref: hasRenderFunction(children) ? ref : null });
};

const List = ({
  Component,
  hideColumns,
  columns,
  defaultDisplayColumns,
  showUrlProvider,
  idProvider,
  onViewClick,
  getList,
  data,
  history,
  location,
  highlightedProvider,
  highlightedRowStyles,
  highlightedRowStyleProvider,
  containsSeparator,
  tableKey,
  useColumnSelection,
  useCSVExport,
  MobileItemComponent,
  simpleFilter,
  defaultSort,
}) => {
  const classes = useStyles();
  const handleViewClick = (data, index) => () => {
    if (isFunction(onViewClick)) {
      onViewClick(data, index);
    }
  };

  const nonHiddenColumns = columns.filter(e => !e?.hidden);
  const columnOptions = nonHiddenColumns.map(_ => ({ title: _.title }));
  const initialColumnOptions =
    defaultDisplayColumns && columnOptions.filter(_ => defaultDisplayColumns.includes(_.title));
  const columnKeys = keyBy(columnOptions, 'title');
  const [selectedColumns, setSelectedColumns] = useLocalStorage(tableKey, initialColumnOptions || columnOptions);
  const cleanSelectedColumns = selectedColumns.filter(col => col.title in columnKeys);
  const [_currentSort, setCurrentSort] = useLocalStorage(
    `${tableKey}-sort`,
    defaultSort || { type: undefined, title: undefined }
  );
  const currentSort = isEmpty(_currentSort) ? defaultSort || {} : _currentSort;

  if (window.innerWidth < 992 && MobileItemComponent) {
    return (
      <MobileListView
        Component={Component}
        hideColumns={hideColumns}
        columns={columns}
        showUrlProvider={showUrlProvider}
        idProvider={idProvider}
        onViewClick={onViewClick}
        getList={getList}
        data={data}
        history={history}
        location={location}
        highlightedProvider={highlightedProvider}
        highlightedRowStyles={highlightedRowStyles}
        highlightedRowStyleProvider={highlightedRowStyleProvider}
        containsSeparator={containsSeparator}
        tableKey={tableKey}
        useColumnSelection={useColumnSelection}
        useCSVExport={false}
        MobileItemComponent={MobileItemComponent}
      />
    );
  }
  const sortColumn = columns.find(_ => _.title === currentSort.title && currentSort.type);

  const list = sortColumn
    ? getSortedList(getList(data), containsSeparator, currentSort, sortColumn, data)
    : getList(data);

  const visibleColumns = useColumnSelection
    ? nonHiddenColumns.filter(({ title }) => !!selectedColumns.find(_ => _.title === title))
    : nonHiddenColumns;

  const selectionContext = useContext(SelectionContext);

  return (
    <Fragment>
      <ListHeader
        showColumnSelection={!hideColumns && useColumnSelection}
        showCSVExport={useCSVExport}
        selectedColumns={cleanSelectedColumns}
        setSelectedColumns={setSelectedColumns}
        columns={columns}
        columnOptions={columnOptions}
        tableKey={tableKey}
        list={list}
        data={data}
      >
        {selectionContext && (
          <SelectionView context={selectionContext} getItem={id => list.find(item => String(idProvider(item)) === id)}>
            {items => <BulkPrintView items={items} />}
          </SelectionView>
        )}
      </ListHeader>

      <SimpleTextFilter onChange={val => val} active={simpleFilter} data={list} columns={columns}>
        {filtered => (
          <Pager data={filtered} currentSort={currentSort} tableKey={tableKey}>
            {pagedData => (
              <Box className={classes.flexWrapper}>
                <TableContainer className={classes.container} tabIndex={0}>
                  <Table stickyHeader columns={cleanSelectedColumns.length} style={{ borderCollapse: 'collapse' }}>
                    {hideColumns ? null : (
                      <TableHead>
                        <TableRow>
                          {selectionContext && (
                            <TableCell className={classes.tableCell}>
                              <HeaderSelectAllCheckbox list={list} idProvider={idProvider}/>
                            </TableCell>
                          )}
                          {visibleColumns.map(({ title, sortDisabled, headerCellStyle }, i) => (
                            <TableCell
                              key={i}
                              disabled={sortDisabled}
                              className={clsx(classes.tableCell, {
                                [classes.sortable]: !sortDisabled,
                              })}
                              onClick={() =>
                                sortDisabled
                                  ? {}
                                  : setCurrentSort({
                                      type: title !== currentSort.title ? 'ASC' : nextType(currentSort.type),
                                      title,
                                    })
                              }
                            >
                              <Box display="flex" alignItems="center" style={headerCellStyle}>
                                <div>{title}</div>
                                <Box display="flex" alignItems="center">
                                  {currentSort.title === title && currentSort.type === 'ASC' && (
                                    <ArrowDropDown fontSize={'medium'} />
                                  )}
                                  {currentSort.title === title && currentSort.type === 'DESC' && (
                                    <ArrowDropUp fontSize={'medium'} />
                                  )}
                                  {currentSort.title !== title && <div style={{ width: 19, height: 19 }} />}
                                </Box>
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {pagedData.map((item, i) => (
                        <Scroller key={`${idProvider(item)}-${i}`} collection={location.pathname} id={idProvider(item)}>
                          <Component
                            history={history}
                            location={location}
                            columns={visibleColumns}
                            item={item}
                            context={{ data }}
                            index={i}
                            showUrlProvider={showUrlProvider}
                            idProvider={idProvider}
                            onClick={handleViewClick(item, i)}
                            highlighted={highlightedProvider ? highlightedProvider(item) : false}
                            highlightedRowStyles={
                              highlightedRowStyleProvider
                                ? highlightedRowStyleProvider(item)
                                : highlightedProvider && highlightedRowStyles && highlightedProvider(item)
                                ? highlightedRowStyles
                                : undefined
                            }
                          />
                        </Scroller>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Pager>
        )}
      </SimpleTextFilter>
    </Fragment>
  );
};

const HeaderSelectAllCheckbox = ({list, idProvider}) => {
  const selectionContext = useContext(SelectionContext);
  const setAllSelections = selectionContext.useSetAllSelections();

  const selectedKeys = selectionContext.useSelected();
  
  const allKeys = list.map(idProvider);
  const isChecked = selectedKeys.length === allKeys.length;
  const isIndeterminate = selectedKeys.length > 0 && selectedKeys.length < allKeys.length;

  const handleSelectAll = useCallback((e) => {    
    if (selectedKeys.length > 0) {
      setAllSelections(allKeys, false);
      return;
    }

    setAllSelections(allKeys, e.target.checked);
  }, [setAllSelections, allKeys, selectedKeys.length]);

  return (
    <Checkbox
      indeterminate={isIndeterminate}
      checked={isChecked}
      onChange={handleSelectAll}
    />
  );
};

export default List;
