import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import { Column, Label, Value, TwoItemSection } from '../../../se/components/collection/mobile/shared';
import RouterLink from '../../../se/components/RouterLink';
import { colorForValue, roundFixed, temperatureLabel } from './SensorList';
import { Text } from '../../../se/components/typography';
import { alertStatus, sensorIdColumn } from './SensorAlerts';

const Temperature = ({ value, unit, min, max }) => (
  <Value style={{ color: colorForValue(value, min, max) }}>{`${roundFixed(value)} ${temperatureLabel(unit)}`}</Value>
);

const Humidity = ({ value, min, max }) => (
  <Text style={{ color: colorForValue(value, min, max) }}>{value ? `${roundFixed(value)} %` : undefined}</Text>
);

const AlertMobileListItem = ({ location, columns, item, context, index: i, showUrlProvider, idProvider, onClick }) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.title === '#');
  const sensorColumn = sensorIdColumn;
  const locationColumn = columns.find(_ => _.title === 'Location');
  const temperatureColumn = columns.find(_ => _.title === 'Temp');
  const humidityColumn = columns.find(_ => _.title === 'Humidity');
  const statusColumn = columns.find(_ => _.title === 'Status');

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <TwoItemSection style={{ marginBottom: '0.75rem' }}>
          <Column>
            <Label>{nameColumn.title}</Label>
            <Value trimText>{nameColumn.lens(item, context)}</Value>
          </Column>
          <Column>
            <Label>{statusColumn.title}</Label>
            <Value style={{ color: statusColumn.lens(item, context) === alertStatus.RESOLVED ? 'green' : 'red' }}>
              {statusColumn.lens(item, context)}
            </Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.75rem' }}>
          <Column>
            <Label>{sensorColumn.title}</Label>
            <Value trimText>{sensorColumn.lens(item, context)}</Value>
          </Column>
          <Column>
            <Label>{locationColumn.title}</Label>
            <Value trimText>{locationColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection>
          <Column>
            <Label>{temperatureColumn.title}</Label>
            <Temperature {...temperatureColumn.lens(item, context)} />
          </Column>
          <Column>
            <Label>{humidityColumn.title}</Label>
            <Humidity {...humidityColumn.lens(item, context)} />
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default AlertMobileListItem;
