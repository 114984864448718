import { Query } from '@apollo/client/react/components';
import React from 'react';
import { compose, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import CollectionList, { DefaultTitle } from '../../../se/components/collection/CollectionList';

import schema from '../../../graph/bracelets';
import columns from './columns';
import { isDefinedAndNotNull } from '../../../se/utilities/check';
import { sortBy, sortNumberDesc } from '../../../se/utilities/data/array';
import TitleAction from '../../../se/components/TitleAction';
import RouterLink from '../../../se/components/RouterLink';
import { withSession } from '../../../state/Session';
import ConnectionProvider from '../../pages/kiosk/ConnectionProvider';
import { ArrowBack } from '@material-ui/icons';

const sortedBracelets = params => {
  const bracelets = get(params, 'data.bracelets');
  return isDefinedAndNotNull(bracelets) ? bracelets.sort(sortBy('batteryLevel')(sortNumberDesc)) : null;
};

const Bracelets = ({ match, accessToken }) => (
  <ConnectionProvider accessToken={accessToken} organizationId={match.params.organizationId}>
    <div>
      <TitleAction
        Back={() => (
          <RouterLink to={'/issues'}>
            <ArrowBack fontSize={'small'} />
            &nbsp;Issues
          </RouterLink>
        )}
        Title={() => <DefaultTitle>Bracelets</DefaultTitle>}
      />

      <Query query={schema.list}>
        {data => (
          <CollectionList
            entityName={'Bracelets'}
            idProvider={item => item.id}
            getList={sortedBracelets}
            TitleAndActions={() => null}
            columns={columns}
            data={data}
            location={{}}
            history={{}}
          />
        )}
      </Query>
    </div>
  </ConnectionProvider>
);

export default compose(
  withRouter,
  withState('isInFullScreen', 'setIsInFullScreen', false),
  withSession(({ session }) => ({ accessToken: session && session.token }))
)(Bracelets);
