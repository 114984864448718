export enum InstructionTemplateKey {
  HandAndFootSurgeryInstructions = 'HandAndFootSurgeryInstructions',
  GynecologySurgeryInstructions = 'GynecologySurgeryInstructions',
  ArthroscopicSurgeryInstructions = 'ArthroscopicSurgeryInstructions',
  PainManagementSurgeryInstructions = 'PainManagementSurgeryInstructions',
  PediatricSurgeryInstructions = 'PediatricSurgeryInstructions',
  GeneralSurgeryInstructions = 'GeneralSurgeryInstructions',
  CovidTestSurgeryInstructions = 'CovidTestSurgeryInstructions',

  // Extended keys for special hospitals:
  PreOpAbdominoplastySurgeryInstructions = 'PreOpAbdominoplastySurgeryInstructions',
  PostOpAbdominoplastySurgeryInstructions = 'PostOpAbdominoplastySurgeryInstructions',
  BlepharoplastySurgeryInstructions = 'BlepharoplastySurgeryInstructions',
  BreastAugmentationSurgeryInstructions = 'BreastAugmentationSurgeryInstructions',
  BreastLiftSurgeryInstructions = 'BreastLiftSurgeryInstructions',
  BreastReductionSurgeryInstructions = 'BreastReductionSurgeryInstructions',
  CalfImplantSurgeryInstructions = 'CalfImplantSurgeryInstructions',
  ChinOrJawAugmentationSurgeryInstructions = 'ChinOrJawAugmentationSurgeryInstructions',
  FaceLiftSurgeryInstructions = 'FaceLiftSurgeryInstructions',
  FacialContourSurgeryInstructions = 'FacialContourSurgeryInstructions',
  PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions = 'PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions',
  PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions = 'PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions',
  HairRestorationPreopInstructions = 'HairRestorationPreopInstructions',
  EyeLaserSurgeryInstructions = 'EyeLaserSurgeryInstructions',

  DayOfSurgeryInstructions = 'DayOfSurgeryInstructions',

  // Spanish
  SpanishGeneralSurgeryInstructions = 'SpanishGeneralSurgeryInstructions',
  SpanishHandAndFootSurgeryInstructions = 'SpanishHandAndFootSurgeryInstructions',
  SpanishEyeLaserSurgeryInstructions = 'SpanishEyeLaserSurgeryInstructions',
  SpanishArthroscopicSurgeryInstructions = 'SpanishArthroscopicSurgeryInstructions',
  SpanishPainSurgeryInstructions = 'SpanishPainSurgeryInstructions',
  SpanishPediatricSurgeryInstructions = 'SpanishPediatricSurgeryInstructions',
}

// ----------------- Global Base Labels (always used unless overridden) -----------
const baseTemplateLabels: Partial<Record<InstructionTemplateKey, string>> = {
  [InstructionTemplateKey.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
  [InstructionTemplateKey.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
  [InstructionTemplateKey.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
  [InstructionTemplateKey.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
  [InstructionTemplateKey.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
  [InstructionTemplateKey.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
  [InstructionTemplateKey.CovidTestSurgeryInstructions]: 'Covid Test Appointment Instructions',
};

interface HospitalEnvironment {
  /** For example, 'localhost', 'testing.ospitek.com', 'view4.ospitek.com', etc. */
  domain: string;
  /** The “environment hospitalId” used in that domain. */
  environmentHospitalId: number;
}

interface RealHospitalConfig {
  /** For your reference, e.g. "Hospital #15" or "Hospital #55". Not used in logic. */
  name: string;
  /** The overrides that belong to this real hospital. */
  overrides: Partial<Record<InstructionTemplateKey, string>>;
  /** All domain/hospitalId combos under which this real hospital is viewed. */
  environments: HospitalEnvironment[];
  /** Default Instruction Template Key that will appear on instructions initial state */
  defaultTemplateType?: InstructionTemplateKey;
  /** A boolean flag indicating whether the hospital configuration should prioritize
   * dynamically derived labels from templates (e.g., using t.label, t.title, or t.name)
   * over predefined static labels. This is used for hospitals requiring more flexible or
   * template-driven label generation logic. */
  useTemplateLabelFallback?: boolean;
}

const hospitals: RealHospitalConfig[] = [
  {
    name: 'View - Hospital2',
    overrides: {
      [InstructionTemplateKey.GynecologySurgeryInstructions]: 'GI Instruction',
      [InstructionTemplateKey.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
      [InstructionTemplateKey.EyeLaserSurgeryInstructions]: 'Eye Laser Pre-Op Instructions',
      [InstructionTemplateKey.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
      [InstructionTemplateKey.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
      [InstructionTemplateKey.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
      [InstructionTemplateKey.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',

      // Spanish
      [InstructionTemplateKey.CovidTestSurgeryInstructions]: 'GI Instruction (Spanish)',
      [InstructionTemplateKey.SpanishHandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishEyeLaserSurgeryInstructions]: 'Eye Laser Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishPainSurgeryInstructions]: 'Pre-Op Instructions For Pain Management (Spanish)',
      [InstructionTemplateKey.SpanishPediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishGeneralSurgeryInstructions]: 'General Pre-Op Instructions (Spanish)',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 67 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 67 },
      { domain: 'view.ospitek.com', environmentHospitalId: 2 },
    ],
  },
  {
    name: 'View - Hospital31',
    overrides: {
      [InstructionTemplateKey.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
      [InstructionTemplateKey.GynecologySurgeryInstructions]: 'Cataract Pre-Op Instructions',
      [InstructionTemplateKey.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
      [InstructionTemplateKey.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
      [InstructionTemplateKey.PediatricSurgeryInstructions]: 'Opthalmology Pre-Op Instructions',
      [InstructionTemplateKey.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
      [InstructionTemplateKey.SpanishHandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishEyeLaserSurgeryInstructions]: 'Cataract Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishPainSurgeryInstructions]: 'Pre-Op Instructions For Pain Management (Spanish)',
      [InstructionTemplateKey.SpanishPediatricSurgeryInstructions]: 'Opthalmology Pre-Op Instructions (Spanish)',
      [InstructionTemplateKey.SpanishGeneralSurgeryInstructions]: 'General Pre-Op Instructions (Spanish)',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 93 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 93 },
      { domain: 'view.ospitek.com', environmentHospitalId: 31 },
    ],
  },
  {
    name: 'View - Hospital55',
    overrides: {
      [InstructionTemplateKey.CovidTestSurgeryInstructions]: 'Voicemail Notification',
      [InstructionTemplateKey.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
      [InstructionTemplateKey.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
      [InstructionTemplateKey.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
      [InstructionTemplateKey.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
      [InstructionTemplateKey.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
      [InstructionTemplateKey.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 98 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 98 },
      { domain: 'view.ospitek.com', environmentHospitalId: 55 },
    ],
    defaultTemplateType: InstructionTemplateKey.CovidTestSurgeryInstructions,
  },
  {
    name: 'View2 - Hospital15',
    overrides: {
      [InstructionTemplateKey.PreOpAbdominoplastySurgeryInstructions]:
        'Tummy Tuck (Abdominoplasty) Pre-op Instructions',
      [InstructionTemplateKey.PostOpAbdominoplastySurgeryInstructions]:
        'Tummy Tuck (Abdominoplasty) Post-op Instructions',
      [InstructionTemplateKey.BlepharoplastySurgeryInstructions]:
        'Eyelid Surgery (Blepharoplasty) Post-Op Instructions',
      [InstructionTemplateKey.BreastAugmentationSurgeryInstructions]: 'Breast Augmentation Post-op Instructions',
      [InstructionTemplateKey.BreastLiftSurgeryInstructions]: 'Breast Lift (Mastopexy) Post-Op Instructions',
      [InstructionTemplateKey.BreastReductionSurgeryInstructions]: 'Breast Reduction Post-Op Instructions',
      [InstructionTemplateKey.CalfImplantSurgeryInstructions]: 'Calf Implant Post-Op Instructions',
      [InstructionTemplateKey.ChinOrJawAugmentationSurgeryInstructions]:
        'Chin or Jaw Augmentation Post-Op Instructions',
      [InstructionTemplateKey.FaceLiftSurgeryInstructions]: 'Face & Neck Lift Post-Op Instructions',
      [InstructionTemplateKey.FacialContourSurgeryInstructions]: 'Facial Contour Post-Op Instructions',
      [InstructionTemplateKey.PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions]:
        'Facial Laser Resurfacing (CO2 laser) Pre-Op Instructions',
      [InstructionTemplateKey.PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions]:
        'Facial Laser Resurfacing (CO2 laser) Post-Op Instructions',
      [InstructionTemplateKey.HairRestorationPreopInstructions]: 'Hair Restoration Pre-op Instructions',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 59 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 59 },
      { domain: 'view2.ospitek.com', environmentHospitalId: 15 },
    ],
  },
  {
    name: 'View2 - Hospital8',
    overrides: {
        [InstructionTemplateKey.CovidTestSurgeryInstructions]: baseTemplateLabels.CovidTestSurgeryInstructions,
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 83 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 83 },
      { domain: 'view2.ospitek.com', environmentHospitalId: 8 },
    ],
    useTemplateLabelFallback: true,
  },
  {
    name: 'View2 - Hospital22',
    overrides: {
      [InstructionTemplateKey.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
      [InstructionTemplateKey.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
      [InstructionTemplateKey.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
      [InstructionTemplateKey.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
      [InstructionTemplateKey.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
      [InstructionTemplateKey.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
      [InstructionTemplateKey.CovidTestSurgeryInstructions]: 'Covid Test Appointment Instructions',

      // Spanish
      [InstructionTemplateKey.SpanishGeneralSurgeryInstructions]: 'GLP-1 PreOperative Instructions',
      [InstructionTemplateKey.SpanishHandAndFootSurgeryInstructions]: 'Pre Surgery Instructions/ERAS Protocol',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 84 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 84 },
      { domain: 'view2.ospitek.com', environmentHospitalId: 22 },
    ],
  },
  {
    name: 'View2 - Hospital28',
    overrides: {
      ...baseTemplateLabels,
      [InstructionTemplateKey.CovidTestSurgeryInstructions]: 'Locals only',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 96 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 96 },
      { domain: 'view2.ospitek.com', environmentHospitalId: 28 },
    ],
  },
  {
    name: 'View4 - Hospital 2/3',
    overrides: {
      ...baseTemplateLabels,
      [InstructionTemplateKey.SpanishGeneralSurgeryInstructions]: 'Guidelines Prior to Procedure',
      [InstructionTemplateKey.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
      [InstructionTemplateKey.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
      [InstructionTemplateKey.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
      [InstructionTemplateKey.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
      [InstructionTemplateKey.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
      [InstructionTemplateKey.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
      [InstructionTemplateKey.CovidTestSurgeryInstructions]: 'Covid Test Appointment Instructions',
      [InstructionTemplateKey.DayOfSurgeryInstructions]: 'Day of Surgery Instructions',
    },
    environments: [
      { domain: 'localhost', environmentHospitalId: 134 },
      { domain: 'testing.ospitek.com', environmentHospitalId: 134 },
      { domain: 'view4.ospitek.com', environmentHospitalId: 2 },
      { domain: 'view4.ospitek.com', environmentHospitalId: 3 },
    ],
    defaultTemplateType: InstructionTemplateKey.SpanishGeneralSurgeryInstructions,
  },
];

// Find which real hospital (if any) has an environment that matches (domain, hospitalId)
// In some edge cases, multiple hospitals might match the same combo if misconfigured, but typically it's 1:1
function findHospitalConfiguration(hospitalId: number): RealHospitalConfig | undefined {
  const domain = window.location.hostname;

  return hospitals.find(hosp =>
    hosp.environments.some(env => env.domain === domain && env.environmentHospitalId === hospitalId)
  );
}

export function getSurgeryInstructionsDefaultTemplateType(hospitalId: number): InstructionTemplateKey {
  const matchingHospital = findHospitalConfiguration(hospitalId);
  return matchingHospital?.defaultTemplateType ?? InstructionTemplateKey.GeneralSurgeryInstructions;
}

// ------------------ The final function to get the merged labels ------------------
export function getInstructionLabels(hospitalId: number): Partial<Record<InstructionTemplateKey, string>> {
  const matchingHospital = findHospitalConfiguration(hospitalId);

  // If found, use the overrides for that hospital
  if (matchingHospital) {
    return matchingHospital.overrides;
  }

  return baseTemplateLabels;
}

export function getInstructionTemplateLabels(hospitalId: number, templates: any): Record<string, string> {
  const matchingHospital = findHospitalConfiguration(hospitalId);
  const labels = getInstructionLabels(hospitalId);

  if (matchingHospital?.useTemplateLabelFallback) {
    return templates.reduce(
      (acc: any, t: any) => ({
        ...acc,
        [t.type]: t.label ?? t.title ?? t.name ?? labels[t.type] ?? t.type,
      }),
      {}
    );
  }

  return labels;
}
