import React from 'react';
import { openPdf } from '../../../../../../entities/procedures/pdf/shared';
import { format, startOfWeek, addDays } from 'date-fns';
import { createPdf } from '../../../../../../../vendor/pdfMake';
import { Room, RoomType } from '../../../../../../../types/Room';
import { Procedure } from '../../../../../../../types/Procedure';

import StaffShift from '../../../../../../../types/StaffShift';

export interface StaffShiftExtended extends StaffShift {
  isProcedure?: boolean;
}

export type RoomWithProcedureFlag = Omit<Room, 'staffShifts'> & { staffShifts: Array<StaffShiftExtended> };

const getOrdinalSuffix = (day: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return suffix[(v - 20) % 10] || suffix[v] || suffix[0];
};

const formatDateWithOrdinal = (date: Date) => {
  const day = format(date, 'DD');
  const dayWithSuffix = `${day}${getOrdinalSuffix(parseInt(day, 10))}`;
  const month = format(date, 'MMMM');
  const year = format(date, 'YYYY');
  const weekday = format(date, 'dddd');
  return `${weekday}, ${month} ${dayWithSuffix} ${year}`;
};

const getContent = (
  hospitalName: string,
  date: Date,
  rooms: RoomWithProcedureFlag[],
  operationRooms: Room[],
  procedures: Procedure[]
) => {
  // Filter procedures for the specific date
  const dateProcedures = procedures.filter(p => new Date(p.startTime).toDateString() === date.toDateString());

  const orRooms = operationRooms.flatMap(room => {
    // Filter procedures for the current room
    const roomProcedures = dateProcedures.filter(p => p.orId === room.id);

    return [
      [
        {
          text: hospitalName || '',
          alignment: 'left',
          fillColor: '#cccccc',
          colSpan: 3,
        },
        '',
        '',
        {
          text: `${room?.name || ''} (${roomProcedures?.length || 0} procedures)`,
          alignment: 'center',
          fillColor: '#cccccc',
          colSpan: 3,
        },
        '',
        '',
        {
          text: format(date, 'MM.DD.YYYY'),
          alignment: 'right',
          fillColor: '#cccccc',
          colSpan: 3,
        },
        '',
        '',
      ],
      [
        {
          text: 'Time',
          fillColor: '#cccccc',
        },
        {
          text: 'Patient',
          colSpan: 2,
          fillColor: '#cccccc',
        },
        '',
        {
          text: 'Physician',
          fillColor: '#cccccc',
        },
        {
          text: 'Procedure',
          colSpan: 2,
          fillColor: '#cccccc',
        },
        '',
        {
          text: 'Laterality',
          fillColor: '#cccccc',
        },
        {
          text: 'Anesthesia Type',
          fillColor: '#cccccc',
        },
        {
          text: 'Appointment Type',
          fillColor: '#cccccc',
        },
      ],
      ...roomProcedures.flatMap((p, i) => [
        [
          {
            text:
              (p?.or || '') +
              '\n' +
              `${p?.startTimeText || ''} to ${p?.endTimeText}` +
              '\n' +
              `${p?.duration || '0'} min`,
            alignment: 'left',
            fillColor: p?.color ? p.color : i % 2 === 1 ? '#eeeeee' : undefined,
            border: [true, true, true, true],
            borderColor: p?.color ? [p.color, p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
          },
          {
            text:
              `${p?.patientName || ''} (${p?.patientAge || ''} ${p?.patientSex || ''})` +
              '\n' +
              `${p?.patientDateOfBirth || ''}` +
              '\n' +
              `MRN# ${p?.patientHstId || '-'}` +
              '\n' +
              `Phone: ${p?.patientCellPhone || '-'}`,
            colSpan: 2,
            fillColor: i % 2 === 1 ? '#eeeeee' : undefined,
            border: [true, true, true, true],
            borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
          },
          '',
          {
            text: p?.physician?.name || '',
            fillColor: i % 2 === 1 ? '#eeeeee' : undefined,
            border: [true, true, true, true],
            borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
          },
          {
            text: p?.procedureType || '',
            colSpan: 2,
            fillColor: i % 2 === 1 ? '#eeeeee' : undefined,
            border: [true, true, true, true],
            borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
          },
          '',
          {
            text: p?.bilaterally || '',
            fillColor: i % 2 === 1 ? '#eeeeee' : undefined,
            color: p?.bilaterally?.toLowerCase()?.includes('left')
              ? 'blue'
              : p?.bilaterally?.toLowerCase()?.includes('right')
              ? 'red'
              : undefined,
            bold: true,
            border: [true, true, true, true],
            borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
          },
          {
            text: p?.anesthesiaType || '',
            fillColor: i % 2 === 1 ? '#eeeeee' : undefined,
            border: [true, true, true, true],
            borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
          },
          {
            text: '',
            fillColor: i % 2 === 1 ? '#eeeeee' : undefined,
            border: [true, true, true, true],
            borderColor: p?.color ? ['black', p.color, p.color, p.color] : ['black', 'black', 'black', 'black'],
          },
        ],
        [
          {
            text: '',
            colSpan: 9,
            border: [false, false, false, false],
            margin: [0, 0.1, 0, 0.1],
          },
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
      ]),
    ];
  });

  return {
    stack: [
      {
        table: {
          widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*'],
          body: orRooms,
        },
      },
    ],
  };
};

export const WeeklyORSchedulePdfGenerator = (
  hospitalName: string,
  date: Date,
  rooms: RoomWithProcedureFlag[],
  operationRooms: Room[],
  procedures: Procedure[]
) => {
  console.log(procedures);
  const weekStart = startOfWeek(date, { weekStartsOn: 0 });
  const weekDates = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  const content = weekDates.flatMap(currentDate => [
    {
      text: formatDateWithOrdinal(currentDate),
      style: 'subheader',
      alignment: 'center',
      margin: [0, 10, 0, 10],
    },
    getContent(
      hospitalName,
      currentDate,
      rooms.map(room => ({
        ...room,
        staffShifts: room.staffShifts.filter(staff => !!staff.staff?.name),
      })),
      operationRooms,
      procedures
    ),
    { text: '', pageBreak: 'after' },
  ]);

  if (content.length > 0 && content[content.length - 1]?.pageBreak === 'after') {
    content.pop();
  }

  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Weekly OR Schedule` },
    content,
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify',
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10],
      },
      tableHeaderRoomTitle: {
        bold: true,
        fontSize: 16,
        color: 'black',
        fillColor: '#CCCCCC',
        margin: [2, 0, 0, -10],
      },
    },
  };

  openPdf(createPdf(docDefinition), `Weekly_OR_Schedule_${format(date, 'MM_DD_YYYY_HH_mm_ss')}`);
};
