import React, { Fragment } from 'react';
import styled from 'styled-components';
import { getSurgeryInstructionsTemplates, updateSurgeryInstructionsTemplate } from '../../../graph/procedures';
import { withLabel } from '../../../se/components/Label';
import { CenteredSpinner } from '../../../se/components/Spinner';
import RichTextField from './RichTextField';
import { withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import Form from '../../../se/components/Form';
import Button from '../../../se/components/Button';
import LinkButton from '../../../se/components/LinkButton';
import { H3 } from '../../../se/components/typography';
import { useMutation, useQuery } from '@apollo/client';
import { useScope } from '../../../hooks/useScope';
import { getInstructionTemplateLabels } from '../../../util/InstructionTemplatesService';

const helper = 'You can use standard shortcuts for text manipulation or undo / redo';
const TemplateInstructionsInput = withProps({
  schema: {
    content: withLabel('Default Instructions', {}, helper)(RichTextField),
  },
})(ObjectInput);

const InstructionsInput = withProps({
  schema: {
    content: withLabel('', {}, helper)(RichTextField),
  },
})(ObjectInput);

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Footer = ({ busy, isDirty, onCancel }: any) =>
  isDirty ? (
    <Actions>
      <Button busy={undefined} disabled={busy}>
        Save
      </Button>
      <LinkButton disabled={busy} onClick={onCancel}>
        Cancel
      </LinkButton>
    </Actions>
  ) : null;

export const InstructionsForm = ({
  value,
  mutation,
  refetchQueries,
  variables = {},
  isTemplate,
  disabled,
  templateType,
}: any) => {
  const [save, { loading }] = useMutation(mutation);
  return (
    <Form
      label={''}
      initialValue={{ content: value }}
      input={isTemplate ? TemplateInstructionsInput : InstructionsInput}
      onSubmit={async (value: any) => {
        await save({
          variables: {
            templateType: templateType,
            content: JSON.stringify({ content: value?.content }),
            ...variables,
          },
          refetchQueries,
        });
      }}
      withoutReset={true}
      busy={loading}
      Footer={Footer}
      disabled={disabled}
    />
  );
};

export default () => {
  const scope: any = useScope();
  const hospitalId = scope?.hospital?.id;

  const { data, loading } = useQuery(getSurgeryInstructionsTemplates);
  const templates = data?.surgeryInstructionsTemplates || [];
  const labels = getInstructionTemplateLabels(hospitalId, templates);

  return (
    <>
      {!loading ? (
        templates.map((template: any, index: number) => (
          <Fragment key={index}>
            <H3 style={{ marginTop: '2em' }}>{labels[template.type]}</H3>
            <InstructionsForm
              value={JSON.parse(template?.content || '')?.content}
              refetchQueries={[{ query: getSurgeryInstructionsTemplates }]}
              mutation={updateSurgeryInstructionsTemplate}
              templateType={template.type}
              isTemplate={true}
            />
          </Fragment>
        ))
      ) : (
        <CenteredSpinner size={undefined} strokeAccessor={undefined} />
      )}
    </>
  );
};
