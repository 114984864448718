import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import useSystemWideRecentlyOfflineScreens from './useSystemWideRecentlyOfflineScreens';
import useSystemWideRecentlyInactiveGateways from './useSystemWideRecentlyInactiveGateways';
import useSystemWideActorStatuses from './useSystemWideActorStatuses';
import { Skeleton } from '@material-ui/lab';
import { DefaultTitle } from '../../se/components/collection/CollectionList';
import DashboardCard from './DashboardCard';

interface SectionProps extends RouteComponentProps {
  systemWideRecentlyOfflineScreens: ReturnType<typeof useSystemWideRecentlyOfflineScreens>;
  systemWideRecentlyInactiveGateways: ReturnType<typeof useSystemWideRecentlyInactiveGateways>;
  systemWideActorStatuses: ReturnType<typeof useSystemWideActorStatuses>;
}

const Home = ({
  match,
  history,
  systemWideRecentlyOfflineScreens,
  systemWideRecentlyInactiveGateways,
  systemWideActorStatuses,
}: SectionProps) => {
  const offlineScreens = systemWideRecentlyOfflineScreens.data?.systemWideRecentlyOfflineScreens.length ?? 0;
  const inactiveGateways = systemWideRecentlyInactiveGateways.data?.systemWideRecentlyInactiveGateways.length ?? 0;
  const hospitals = systemWideActorStatuses.data?.hospitals;
  const [unresponsiveActors, affectedHospitals] = useMemo<[number, number[]]>(() => {
    if (!Array.isArray(hospitals)) {
      return [0, []];
    }

    var unresponsiveActors = 0;
    const affectedHospitals = new Set<number>();

    for (let org of hospitals) {
      const orgId = org.id;

      if (!org) {
        continue;
      }

      if (org['snapshot-router']) {
        const snapshotRouter = org['snapshot-router'];

        if (snapshotRouter === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        } else {
          if (snapshotRouter.processors) {
            for (let processorKey in snapshotRouter.processors) {
              const processor = snapshotRouter.processors[processorKey];

              if (!processor) {
                continue;
              }

              if (processor === 'UNRESPONSIVE') {
                unresponsiveActors += 1;
                affectedHospitals.add(orgId);
              } else {
                if (processor.downstream === 'UNRESPONSIVE') {
                  unresponsiveActors += 1;
                  affectedHospitals.add(orgId);
                }
                if (processor.downstreamFilter === 'UNRESPONSIVE') {
                  unresponsiveActors += 1;
                  affectedHospitals.add(orgId);
                }
                if (processor.patientActionDeferrer === 'UNRESPONSIVE') {
                  unresponsiveActors += 1;
                  affectedHospitals.add(orgId);
                }
              }
            }
          }
        }
      }

      if (org['enrollment-reader']) {
        const enrollmentReader = org['enrollment-reader'];

        if (enrollmentReader === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['exit-gateway']) {
        const exitGateway = org['exit-gateway'];

        if (exitGateway === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['sensor-monitor']) {
        const sensorMonitor = org['sensor-monitor'];

        if (sensorMonitor === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['subscription-registry']) {
        const subscriptionRegistry = org['subscription-registry'];

        if (subscriptionRegistry === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['gateway-router']) {
        const gatewayRouter = org['gateway-router'];

        if (gatewayRouter === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }
    }

    return [unresponsiveActors, [...affectedHospitals.values()]];
  }, [hospitals]);

  return (
    <>
      <Box mb={2}>
        <DefaultTitle>Dashboard</DefaultTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DashboardCard onClick={() => history.push(match.path + '/screens')}>
            {systemWideRecentlyOfflineScreens.loading ? (
              <Skeleton variant="text" width="26ch" />
            ) : systemWideRecentlyOfflineScreens.error ? (
              <pre>{JSON.stringify(systemWideRecentlyOfflineScreens.error)}</pre>
            ) : offlineScreens > 0 ? (
              `${offlineScreens} offline screen${offlineScreens === 1 ? '' : 's'} in the last 24 hours`
            ) : (
              'No offline screens in the last 24 hours'
            )}
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard onClick={() => history.push(match.path + '/gateways')}>
            {systemWideRecentlyInactiveGateways.loading ? (
              <Skeleton variant="text" width="25ch" />
            ) : systemWideRecentlyInactiveGateways.error ? (
              <pre>{JSON.stringify(systemWideRecentlyInactiveGateways.error)}</pre>
            ) : inactiveGateways > 0 ? (
              `${inactiveGateways} inactive gateway${inactiveGateways === 1 ? '' : 's'} in the last week`
            ) : (
              'No inactive gateways in the last week'
            )}
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard onClick={() => history.push(match.path + '/actors')}>
            {systemWideActorStatuses.loading ? (
              <Skeleton variant="text" width="18ch" />
            ) : systemWideActorStatuses.error ? (
              <pre>{JSON.stringify(systemWideActorStatuses.error)}</pre>
            ) : unresponsiveActors > 0 ? (
              `${unresponsiveActors} unresponsive actor${unresponsiveActors === 1 ? '' : 's'} in${' '}
                        ${affectedHospitals.length} hospital${affectedHospitals.length === 1 ? '' : 's'}`
            ) : (
              'All actors are operational'
            )}
          </DashboardCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
