import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface ListSeparatorProps {
  title: string;
}

const ListSeparator = ({ title }: ListSeparatorProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.separator}>
      <Typography>{title}</Typography>
      <Box flex={1}>
        <Divider />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  separator: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default ListSeparator;
