import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ListItemLink } from '../../../../se/components/collection/mobile/ListItem';
import RouterLink from '../../../../se/components/RouterLink';
import ListSeparator from '../../../ListSeparator';

const PatientMobileListItem = ({
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return <ListSeparator title={item.label} />;
  }

  const theme = useTheme();
  const nameColumn = columns.find(_ => _.key === 'name');
  const physicianColumn = columns.find(_ => _.key === 'physician');
  const procedureTypeColumn = columns.find(_ => _.key === 'procedureType');
  const braceletIdColumn = columns.find(_ => _.key === 'braceletId');
  const roomOrDateOfVisit = columns.find(_ => _.key === 'roomOrDateOfVisit');

  return (
    <ListItemLink
      component={showUrlProvider ? RouterLink : 'div'}
      to={
        showUrlProvider
          ? {
              pathname: showUrlProvider(idProvider(item)),
              search: location.search,
            }
          : undefined
      }
      onClick={onClick}
    >
      <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }} mb={2}>
        <nameColumn.Component data={nameColumn.lens(item, context)} />
        {roomOrDateOfVisit && (
          <Typography variant="body2" color="textSecondary">
            ({roomOrDateOfVisit.lens(item, context)})
          </Typography>
        )}
      </Box>

      <Box mb={1}>
        <Typography variant="body2" color="textSecondary">
          {physicianColumn.title}
        </Typography>
        <Typography>{physicianColumn.lens(item, context)}</Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body2" color="textSecondary">
          {procedureTypeColumn.title}
        </Typography>
        <Typography>{procedureTypeColumn.lens(item, context) || '-'}</Typography>
      </Box>
      {braceletIdColumn && (
        <Box>
          <Typography variant="body2" color="textSecondary">
            {braceletIdColumn.title}
          </Typography>
          <Typography>
            <braceletIdColumn.Component data={braceletIdColumn.lens(item, context)} />
          </Typography>
        </Box>
      )}
    </ListItemLink>
  );
};

export default PatientMobileListItem;
