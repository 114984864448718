import Box from '@material-ui/core/Box';
import { differenceInHours, getHours, setHours, setMinutes, setSeconds } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import Tick from '../timeline/components/Tick';
import YAxis from '../timeline/components/YAxis';
import Day from './Day';

export const WeeklyGrid = styled(Box)`
  display: flex;
  flex: 1 0 0;
  position: relative;
  overflow: hidden;
  gap: 0.5em;
  padding-left: 4em;
  padding-right: 1.5em;
`;

const Week = ({
  dates = [],
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  hideRoomName,
}) => {
  const dayStart = setSeconds(setMinutes(setHours(dates[0], 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(dates[0], 18), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  return (
    <WeeklyGrid>
      <YAxis style={{ paddingTop: hideRoomName ? 70 : 112 }}>
        {hours.map(tick => (
          <Tick key={tick}>{tick}</Tick>
        ))}
      </YAxis>
      {dates.map((date, i) => (
        <Day
          procedures={procedures}
          dateSelection={dateSelection}
          rooms={rooms}
          showDayNames={showDayNames}
          firstWeek={firstWeek}
          setEditProcedureMonthly={setEditProcedureMonthly}
          openProcedureForm={openProcedureForm}
          canUseScheduleViewProcedure={canUseScheduleViewProcedure}
          date={date}
          hideRoomName={hideRoomName}
        />
      ))}
    </WeeklyGrid>
  );
};

export default Week;
