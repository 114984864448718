import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PatientEventIcon from './PatientEventIcon';

export const getEventText = (event, procedureStep) => {
  switch (event?.type) {
    case 'Created':
      return 'Admitted';
    case 'Entered':
      return `Entered ${event?.room?.name || event?.room?.type}`;
    case 'Exited':
      return `Exited ${event?.room?.name || event?.room?.type}`;
    case 'ProcedureStatusSet':
      if (event?.status?.toUpperCase() === 'READY') {
        return 'Physician Notified from OR';
      }
      if (event?.status?.toUpperCase() === 'TIMEOUT2') {
        return 'Time Out 2';
      }
      return procedureStep?.name ? procedureStep?.name : `Procedure Status ${event?.status?.toUpperCase()}`;
    case 'NoteChange':
      return `Note: ${event?.value}` || 'Deleted Note';
    case 'CaretakerConsentChange':
      return `Caretaker consent ${event?.value === 'true' ? 'granted' : 'denied'}`;
    case 'ReadyForSurgeonNotify':
      return 'Physician Notified from PreOp';
    case 'ReadyForAnesthesiologistNotify':
      return 'Anesthesiologist Notified from PreOp';
    case 'BecameReady':
      return 'Became Ready';
    case 'BecameReadyInPACU':
      return 'Marked as Ready in PACU';
    case 'BecameReadyInPREP':
      return 'Marked as Ready For OR';
    case 'NotReadyInPREP':
      return 'Marked as not Ready For OR';
    case 'FamilyReady':
      return 'Marked as Family Ready';
    case 'NotReadyInPACU':
      return 'Marked as not Ready in PACU';
    case 'ReadyForSurgeon':
      return 'Marked as Ready For Surgeon';
    case 'NotReadyForSurgeon':
      return 'Marked as Not Ready For Surgeon';
    case 'FamilyReadyPACU':
      return 'Marked as Ready for Pick up';
    case 'BedChange':
      return `Assigned Bed ${event?.value} in ${event?.room?.name}`;
    case 'MessageDelivery@FamilyTrackingInvite':
      return `Family Sms ${event?.status}`;
    case 'UndoBlockNerve':
      return `Undo Block Nerve`;
    case 'BlockNerve':
      return `Block Nerve`;
    case 'CleaningCompleted':
      return `OR Room Ready`;
    default:
      return event?.type;
  }
};

const PatientEventText = ({ event, eventText }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1), marginRight: 'auto' }}>
      <PatientEventIcon event={event} />
      <Typography>{eventText}</Typography>
    </Box>
  );
};

export default PatientEventText;
