import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import RouterLink from '../../../se/components/RouterLink';

const UserMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.key === 'name');
  const emailColumn = columns.find(_ => _.key === 'email');
  const roleColumn = columns.find(_ => _.key === 'role');
  const theme = useTheme();

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <Box display="flex" flexDirection="column" style={{ gap: theme.spacing(0.5) }}>
          <Typography>{nameColumn.lens(item, context)}</Typography>
          <Typography>{emailColumn.lens(item, context)}</Typography>
          <Box ml={-0.5}>
            <Chip variant="outlined" size="small" label={roleColumn.lens(item, context)} />
          </Box>
        </Box>
      </ListItemLink>
    </Fragment>
  );
};

export default UserMobileListItem;
