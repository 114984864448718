import { useApolloClient, useQuery } from '@apollo/client';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { debounce } from 'lodash';
import get from 'lodash/get';
import omit from 'lodash/omit';
import Mousetrap from 'mousetrap';
import React, { useEffect, useMemo, useState } from 'react';
import { withProps } from 'recompose';
import hospitals from '../../../graph/hospitals';
import { listGroup } from '../../../graph/partners';
import { list as physicians } from '../../../graph/physicians';
import { listLastHundred } from '../../../graph/procedureTypes';
import { notUtilityRooms } from '../../../graph/rooms';
import SelectInput from '../../../se/components/inputs/SelectInput';
import withExchangeOnly from '../../../util/withExchangeOnly';
import { createRanges, NamedRange, toLocalDate } from '../../core/DatePicker';
import { intakeStatusLabels, procedureStatuseLabels } from '../../entities/procedures/ProcedureEventLog';
import { ConsultationStatuses } from '../../entities/surgeonOffice/consultations/enums';
import { SurgeryStatusesHospital, SurgeryStatusesSurgeonOffice } from '../../entities/surgeonOffice/surgeries/enums';
import graphqlSelectInput from '../../inputs/graphqlSelectInput';
import { InlineOmniSearchWithRouter, useOmniSearch } from '../../OmniSearch';
import CategoryPicker from './CategoryPicker';
import DateFilter from './DateFilter';
import TextSearch from './TextSearch';

// TODO refactor this component to use Material UI theme and components

const RoomSelectInput = graphqlSelectInput({
  entityName: 'Room',
  label: 'Room',
  placeholder: 'Room',
  graphqlList: notUtilityRooms,
  getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
});

const PhysicianSelectInput = graphqlSelectInput({
  entityName: 'Physician',
  placeholder: 'Physician',
  graphqlList: physicians,
  sortOptions: true,
  menuWidthFollowContent: false,
});

const ProcedureTypeSelectInput = graphqlSelectInput({
  entityName: 'ProcedureType',
  placeholder: 'Procedure',
  graphqlList: listLastHundred,
  menuWidthFollowContent: false,
  sortOptions: true,
});

const HospitalSelectInput = graphqlSelectInput({
  entityName: 'Hospital',
  placeholder: 'Hospital',
  graphqlList: hospitals.list,
  sortOptions: true,
});

const PartnersSelectInput = graphqlSelectInput({
  entityName: 'Partner',
  placeholder: 'Hospital',
  graphqlList: listGroup,
  sortOptions: true,
});

const ProcedureStatusSelectInput = withProps({
  placeholder: 'Status',
  options: procedureStatuseLabels,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'status',
})(SelectInput);

const IntakeStatusSelectInput = withProps({
  placeholder: 'Registration Package Status',
  options: intakeStatusLabels,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'intakeStatus',
})(SelectInput);

const ConsultationStatusSelectInput = withProps({
  placeholder: 'Status',
  options: ConsultationStatuses,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'procedureStatus',
})(SelectInput);

const SurgeryStatusHospitalSelectInput = withProps({
  placeholder: 'Status',
  options: SurgeryStatusesHospital,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'scheduleTransferState',
})(SelectInput);

const SurgeryStatusSurgeonOfficeSelectInput = withProps({
  placeholder: 'Status',
  options: SurgeryStatusesSurgeonOffice,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'scheduleTransferState',
})(SelectInput);

const SpecialitySelectInput = ({ value, onChange, ...rest }) => {
  const { data, loading } = useQuery(physicians);
  const options = useMemo(
    () =>
      omit(
        get(data, 'physicians', []).reduce((acc, { speciality }) => ({ ...acc, [speciality]: speciality }), {}),
        ['', null, undefined]
      ),
    [data]
  );

  return (
    <SelectInput
      {...rest}
      onChange={onChange}
      value={value}
      options={options}
      menuWidthFollowContent={false}
      loading={loading}
      sortOptions={true}
      placeholder={'Speciality'}
    />
  );
};

const defaults = { dateRange: NamedRange.lastSevenDays() };

const DebouncedTextInput = ({ value, onChange }) => {
  const [internal, setInternal] = useState(value);

  const throttled = useMemo(() => debounce(onChange, 500), [onChange]);

  const onChangeInternal = val => {
    setInternal(val);
    throttled(val);
  };

  return <TextSearch autoComplete="off" onChange={onChangeInternal} value={internal} />;
};

const Form = props => {
  const {
    value,
    now = new Date(),
    searchSource,
    showNameSearchInput,
    hidePhysicianSelectInput,
    hideProcedureTypeSelectInput,
    hideHospitalSelectInput,
    hidePartnersSelectInput,
    hideProcedureStatusSelectInput,
    hideIntakeStatusSelectInput,
    hideConsultationStatusSelectInput,
    hideSurgeryStatusHospitalSelectInput,
    hideSurgeryStatusSurgeonOfficeSelectInput,
    hideCategorySelectInput,
    hideSpecialitySelectInput,
    hideRoomSelectInput,
    futurePicker,
    symmetricPicker,
    customPicker,
    dateTimePickerTitle,
    hideDateFilter,
    hideCustomFilterTabs,
    CustomFilterTabs,
    onChange,
    reload,
    dontReloadOn,
    history,
    location,
    match,
    defaultValue,
    datePickerRanges,
  } = props;

  const classes = useStyles();

  const onFilterChange = key => val => {
    onChange({ ...props.value, [key]: val });
    ((dontReloadOn || []).filter(e => e === key) || []).length === 0 && reload && window.location.reload();
  };

  const clearAll = () => {
    onChange({ dateRange: defaultValue?.dateRange });
    reload && window.location.reload();
  };

  const asParsedInt = fn => val => fn(parseInt(val));
  const today = toLocalDate(now);
  const ranges = createRanges({ datePickerRanges, today, future: futurePicker, symmetric: symmetricPicker });
  const { openSource } = useOmniSearch();
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (searchSource) {
      const mousetrap = new Mousetrap(document.body);

      mousetrap.bind('s', e => {
        if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(e.target.tagName ?? '') >= 0) {
          return;
        }

        e.preventDefault();

        openSource(searchSource);
      });

      return () => {
        mousetrap.reset();
      };
    }
  }, [openSource, searchSource]);

  return (
    <Box className={classes.form}>
      <Box className={classes.stack}>
        <Box className={classes.stackRow}>
          <Box flex={1} className={classes.inputStack}>
            {!hideCustomFilterTabs && (
              <CustomFilterTabs value={value && value.dateRange} onChange={onFilterChange('dateRange')} />
            )}

            {searchSource && (
              <InlineOmniSearchWithRouter
                source={[apolloClient, searchSource]}
                context={{
                  physician: value.physician,
                  speciality: value.speciality,
                  dateRange: value.dateRange,
                  status: value.procedureStatus,
                }}
                history={history}
                location={location}
                match={match}
              />
            )}
            {showNameSearchInput && (
              <DebouncedTextInput value={value && value.name ? value.name : ''} onChange={onFilterChange('name')} />
            )}
            {!hidePhysicianSelectInput && (
              <PhysicianSelectInput
                onChange={asParsedInt(onFilterChange('physician'))}
                value={value && value.physician ? value.physician : ''}
                menuWidthFollowContent
              />
            )}
            {!hideProcedureTypeSelectInput && (
              <ProcedureTypeSelectInput
                onChange={asParsedInt(onFilterChange('procedureType'))}
                value={value && value.procedureType ? value.procedureType : ''}
                variables={{ physician: value && value.physician }}
                menuWidthFollowContent
              />
            )}
            {!hideSpecialitySelectInput && (
              <SpecialitySelectInput
                onChange={onFilterChange('speciality')}
                value={value && value.speciality ? value.speciality : ''}
                menuWidthFollowContent
              />
            )}

            {!hideRoomSelectInput && (
              <RoomSelectInput
                placeholder="Room"
                onChange={onFilterChange('room')}
                value={value && value.room ? value.room : ''}
              />
            )}

            {!hideHospitalSelectInput && (
              <HospitalSelectInput
                onChange={asParsedInt(onFilterChange('hospital'))}
                value={value && value.hospital}
                variables={{ hospital: value && value.hospital ? value.hospital : '' }}
                menuWidthFollowContent
              />
            )}

            {!hidePartnersSelectInput && (
              <PartnersSelectInput
                onChange={asParsedInt(onFilterChange('partner'))}
                value={value && value.partner}
                variables={{
                  partner: value && value.partner ? value.partner : '',
                }}
                menuWidthFollowContent
              />
            )}

            {!hideProcedureStatusSelectInput && (
              <ProcedureStatusSelectInput onChange={onFilterChange('status')} value={value && value.status} />
            )}

            {!hideIntakeStatusSelectInput && (
              <IntakeStatusSelectInput onChange={onFilterChange('intakeStatus')} value={value && value.intakeStatus} />
            )}

            {!hideConsultationStatusSelectInput && (
              <ConsultationStatusSelectInput
                onChange={onFilterChange('procedureStatus')}
                value={value && value.procedureStatus}
              />
            )}

            {!hideSurgeryStatusHospitalSelectInput && (
              <SurgeryStatusHospitalSelectInput
                onChange={onFilterChange('scheduleTransferState')}
                value={value && value.scheduleTransferState}
              />
            )}

            {!hideSurgeryStatusSurgeonOfficeSelectInput && (
              <SurgeryStatusSurgeonOfficeSelectInput
                onChange={onFilterChange('scheduleTransferState')}
                value={value && value.scheduleTransferState}
              />
            )}

            {props.children}
            {!hideDateFilter && (
              <DateFilter
                title={dateTimePickerTitle}
                today={today}
                value={value && value.dateRange}
                onChange={onFilterChange('dateRange')}
                ranges={ranges}
                customPicker={customPicker}
              />
            )}
          </Box>

          <Box flex="none">
            <Button onClick={clearAll}>Clear All</Button>
          </Box>
        </Box>

        {!hideCategorySelectInput && (
          <CategoryPicker value={value && value.category} onChange={onFilterChange('category')} />
        )}
      </Box>
    </Box>
  );
};

const Filters = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  return isMobile ? (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Show filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Form {...props} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <Form {...props} />
  );
};

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flex: 1,
    },
  },
  stack: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flex: 1,
  },
  stackRow: {
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  inputStack: {
    display: 'flex',
    gap: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& > *': {
      flex: 1,
    },
  },
}));

Filters.defaultProps = {
  defaultValue: defaults,
  showNameSearchInput: false,
  hidePhysicianSelectInput: false,
  hideProcedureTypeSelectInput: false,
  hideHospitalSelectInput: true,
  hidePartnersSelectInput: true,
  hideProcedureStatusSelectInput: true,
  hideIntakeStatusSelectInput: true,
  hideCategorySelectInput: true,
  hideSpecialitySelectInput: true,
  hideRoomSelectInput: true,
  hideConsultationStatusSelectInput: true,
  hideSurgeryStatusHospitalSelectInput: true,
  hideSurgeryStatusSurgeonOfficeSelectInput: true,
  hideMobile: false,
  hideDateFilter: false,
  hideCustomFilterTabs: true,
  reload: true,
};

export default withExchangeOnly(Filters);
