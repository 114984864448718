import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const YAxis = ({ children, style }: { children: any; style?: any }) => {
  const classes = useStyles();

  return (
    <Box className={classes.yAxis} style={style}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  yAxis: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    fontSize: '0.8125em',
    pointerEvents: 'none',
  },
}));

export default YAxis;
