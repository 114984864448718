import { FormType } from '../../../types/Form';

export const formSaveTypeLabels = {
  Filled: 'Patient Forms',
  Uploaded: 'Uploaded Photos',
  Pdf: 'Pdf Forms',
};

export const formTypeLabels = {
  [FormType.PreOpForm]: 'PreOp Forms',
  [FormType.InTakeForm]: 'Registration Packages',
  [FormType.PreOpChartForm]: 'PreOp Chart Forms',
  [FormType.OrChartForm]: 'Or Chart Forms',
  [FormType.PacuChartForm]: 'PACU Chart Forms',
  [FormType.PhysicianChartForm]: 'Physician Chart Forms',
  [FormType.AnesthesiologistPreOpChartForm]: 'Anesthesia PreOp Chart Forms',
  [FormType.AnesthesiologistOrChartForm]: 'Anesthesia Or Chart Forms',
  [FormType.AnesthesiologistPacuChartForm]: 'Anesthesia PACU Chart Forms',
};
