import React from 'react';
import person from '../../../../assets/images/icons/person.png';
import { alpha, Box, Chip, Link } from '@material-ui/core';
import { formatDuration } from './shared/procedureUtils';
import { getColor } from './timeline/Procedure';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withSession } from '../../../../state/Session';
import { unpackSessionObject } from '../../unpackSessionObject';

export const PatientName = ({
  id,
  name,
  age,
  sex,
  isPatientIconVisible,
  condensView,
  inline,
  hospitalId,
  isTimeline,
  ...props
}: any) => {
  const classes = useStyles();
  const isSuperAdmin = props.isSuperAdmin;

  return (
    <Box
      className={clsx(classes.wrapper, {
        [classes.inline]: inline,
        [classes.condensedView]: condensView,
      })}
    >
      {isPatientIconVisible && (
        <img src={person} alt="icon" style={{ opacity: 0.5, width: '1em', marginRight: '.3333em' }} />
      )}
      {id ? (
        <Link
          component={RouterLink}
          to={`${isSuperAdmin ? `/su/${hospitalId}` : ''}/patients/${id}`}
          onClick={e => e.stopPropagation()}
          style={{ color: 'inherit' }}
        >
          {name}&nbsp;{age} {sex}
        </Link>
      ) : isTimeline ? (
        <div style={{ fontSize: '.9rem' }}>{`${name}${age}${sex}`}</div>
      ) : (
        `${name} ${age} ${sex}`
      )}
    </Box>
  );
};

const PatientNameWithSession = compose(withRouter, withSession(unpackSessionObject))(PatientName);

const Label = ({ text, backgroundColor }: { text: string; backgroundColor: string }) => (
  <Box borderRadius={4} px={1} my={-1} bgcolor={backgroundColor} style={{ fontWeight: 'bold' }}>
    {text}
  </Box>
);

const Patient = ({
  id,
  name,
  age,
  sex,
  isPatientIconVisible,
  status,
  inOrStatus,
  roomTime,
  bed,
  condensView,
  hospitalId,
}: any) => {
  const color = getColor(status, hospitalId);
  const classes = useStyles();

  return (
    <Box display="flex" flex="auto">
      <PatientNameWithSession
        id={id}
        condensView={condensView}
        name={name}
        age={age}
        sex={sex}
        isPatientIconVisible={isPatientIconVisible}
        hospitalId={hospitalId}
      />
      <Box
        className={clsx(classes.patientStat, {
          [classes.right]: true,
        })}
      >
        {!!roomTime &&
          formatDuration(roomTime, new Date(), false, {
            color: 'rgba(255, 255, 255, .5)',
            fontSize: '0.75rem',
            fontFamily:
              'Rubik,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
          })}{' '}
        <Label backgroundColor={color} text={inOrStatus ? inOrStatus : status} /> {!!bed && <Bed bed={bed} />}
      </Box>{' '}
    </Box>
  );
};

export const Bed = ({ bed }: any) => (
  <Chip
    size="small"
    variant="outlined"
    icon={
      <div
        className="material-icons"
        style={{
          color: 'rgba(255, 255, 255, 0.33)',
          fontSize: '1.3em',
          marginLeft: '0.4em',
        }}
      >
        hotel{' '}
      </div>
    }
    label={bed}
  />
);

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontSize: '1.16666em',
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
  },
  inline: {
    fontSize: 'inherit',
    padding: 0,
    backgroundColor: 'transparent',
    flex: '0 1 auto',
  },
  condensedView: {
    flex: 3,
  },
  patientStat: {
    display: 'flex',
    gap: '0.25em',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    flex: '3',
    marginRight: '1px',
    padding: '0.25em 0.375em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
  patientStatInline: {
    backgroundColor: 'transparent',
    flex: '0 1 auto',
    padding: '0',
  },
  left: {
    justifyContent: 'flex-start',
  },
  right: {
    justifyContent: 'flex-end',
  },
  center: {
    justifyContent: 'center',
  },
}));

export default Patient;
