import Icon from '../../../se/components/Icon';
import React from 'react';
import RouterLink from '../../../se/components/RouterLink';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const BackButton = ({ name, pathname, location }) => (
  <RouterLink to={{ pathname, search: location.search }}>
    <ArrowBack fontSize={'small'} />
    &nbsp;{name}
  </RouterLink>
);

const TabNavigation = ({ tabs, location, withName = false }) => {
  const current = tabs.find(tab => tab.pathname === location.pathname) || {};
  const alertPage = tabs.find(_ => _.id === 'alert');

  return (
    <>
      {current.name ? withName && <label>{current.name}</label> : <BackButton {...alertPage} location={location} />}
      <Tabs indicatorColor="primary" value={location.pathname} variant="scrollable" scrollButtons="auto">
        {tabs.map(({ id, pathname, icon, name }) => (
          <Tab
            key={id}
            component={Link}
            to={{ pathname: pathname, search: location.search }}
            icon={icon ? <Icon>{icon}</Icon> : undefined}
            value={pathname}
            label={name}
          />
        ))}
      </Tabs>
    </>
  );
};

export default TabNavigation;
