import React, { Fragment, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '../../theme';
import { slice } from 'lodash';
import LinkButton from '../LinkButton';
import useLocalStorage from 'use-localstorage-hook';
import Typography from '@material-ui/core/Typography';
import SelectionContext from '../../../features/selection/SelectionContext';

const PageControl = styled.div`
  display: flex;

  padding: 0.5em 1em;
  justify-content: end;
  align-items: center;

  > :first-child {
    margin-right: 1em;
  }

  > :last-child {
    margin-left: 1em;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageButton = styled(LinkButton)`
  background: ${withTheme((theme, props) =>
    props.active ? theme.button.primary.backgroundColor.alpha(0.7).string() : 'none'
  )};
  color: ${withTheme(theme => theme.sidebar.textColor.string())};
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;

  ${props =>
    props.active &&
    css`
      :hover {
        color: white;
      }
    `}
`;

const PageInfo = ({ total, from, to, selectedCount }) => (
  <Typography variant="body2" color="textSecondary">{`Showing from ${from} to ${Math.min(
    to,
    total
  )} out of ${total} entries${selectedCount ? ` (${selectedCount} selected)` : ''}`}</Typography>
);

const Pager = ({ data = [], currentSort, defaultPage = 0, pageSize = 50, children, tableKey }) => {
  const [page, setPage] = useLocalStorage(`${tableKey}-page`, defaultPage);
  const [before, setBefore] = useState([]);
  const [frame, setFrame] = useState([]);
  const selectionContext = useContext(SelectionContext);
  const selectedKeys = selectionContext?.useSelected?.() || [];

  useEffect(() => {
    const offset = page * pageSize;
    setBefore(slice(data, 0, offset));
    setFrame(slice(data, offset, offset + pageSize));
  }, [data, page, pageSize, setFrame, currentSort]);

  const ghostsBefore = useMemo(() => before.filter(item => item && !item.id).length, [before]);
  const ghostsFrame = useMemo(() => frame.filter(item => item && !item.id).length, [frame]);
  const ghostsTotal = useMemo(() => data.filter(item => item && !item.id).length, [data]);

  const maxPage = useCallback(() => Math.ceil(data.length / pageSize) - 1, [data, pageSize]);

  useEffect(() => {
    if (page > maxPage()) {
      setPage(0);
    }
  }, [maxPage, page, data, setPage, currentSort]);

  const onBack = () => setPage(Math.max(0, page - 1));
  const onNext = () => setPage(Math.min(maxPage(), page + 1));

  const getPageOptions = () => {
    const max = maxPage();
    const pages = [...Array(max + 1)].map((_, i) => i);
    const HRANGE = 3;
    const from = Math.max(0, page - HRANGE);
    const to = Math.min(2 * HRANGE + from, max + 1);
    const extendedFrom = Math.min(from - (HRANGE - (to - page)), from);
    const trim = slice(pages, Math.max(to - from < 2 * HRANGE ? extendedFrom : from, 0), to);
    return [...trim].map(p => ({ label: p + 1, page: p }));
  };

  const pages = getPageOptions();

  return (
    <Fragment>
      {children(frame)}
      {pages.length > 1 && (
        <Container>
          <PageInfo
            total={data.length - ghostsTotal}
            from={page * pageSize + 1 - ghostsBefore}
            to={Math.min(page * pageSize + pageSize - ghostsFrame, data.length - ghostsTotal)}
            selectedCount={selectedKeys.length}
          />
          <PageControl>
            <LinkButton onClick={onBack} disabled={page === 0}>
              Prev
            </LinkButton>
            {pages.map(el => (
              <PageButton key={el.page} secondary active={el.page === page} onClick={() => setPage(el.page)}>
                {el.label}
              </PageButton>
            ))}
            <LinkButton onClick={onNext} disabled={page === maxPage()}>
              Next
            </LinkButton>
          </PageControl>
        </Container>
      )}
    </Fragment>
  );
};

export default Pager;
