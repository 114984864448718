export function convertSecondsToHoursAndMinutes(durationInSeconds: number): string {
  const durationInMinutes = durationInSeconds / 60;
  return convertMinutesToHoursAndMinutes(durationInMinutes);
}

export function convertMinutesToHoursAndMinutes(durationInMinutes: number): string {
  if (durationInMinutes <= 0) return '-';

  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor(durationInMinutes % 60);

  const hoursText = hours >= 10 ? `${hours}` : hours > 0 ? `0${hours}` : '00';
  const minutesText = minutes >= 10 ? `${minutes}` : minutes > 0 ? `0${minutes}` : '00';

  return `${hoursText}:${minutesText}`;
}

export function getMinutes(durationInMinutes: number): string {
  if (durationInMinutes === 0 || Number.isNaN(durationInMinutes) || durationInMinutes === undefined) return '-';

  const value = Math.round(durationInMinutes);

  return value > 1 || value < 1 ? `${value} mins` : `${value} min`;
}
