import React, { FC, useCallback, useEffect, useState } from 'react';
import { Destination } from '../../../../../types/NotificationTemplate';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';
import { SendNotificationDialog as SendNotificationDialogNew } from '../../schedule/SendNotificationDialog';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleAccess from '../ScheduleAccess';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Vendor from '../../../../../types/Vendor';
import SendNotificationDialogTitle from '../scheduleNotificationDialog/DialotTitle';

export type Contact = {
  id: number;
  phoneNumbers: string[];
};

export type Recipient = Vendor;

type State = {
  [recipientId: string]: {
    value?: boolean;
    representatives?: boolean[];
  };
};

const SendNotificationDialog: FC<{
  date: Date;
  open: boolean;
  handleClose: () => void;
  recipients: Recipient[];
  sendNotifications: (recipients: Contact[], message: String) => Promise<void>;
  destination: Destination;
}> = ({ date, open, handleClose, recipients, sendNotifications, destination }) => {
  const { data: notification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ScheduleCreated', destination: destination },
  });
  const theme = useTheme();

  const notificationText = notification?.getNotificationTemplate[0]?.content || '';
  const [message, setMessage] = useState<string>('');

  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  useEffect(() => {
    setMessage(notificationText);
  }, [notificationText]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  const classes = useStyles();

  useEffect(() => {
    setSent(false);
  }, [open]);

  const [checkboxes, setCheckboxes] = useState<State>({});

  const changeAllCheckboxes = useCallback(
    turnOn => {
      recipients?.length &&
        setCheckboxes(
          recipients.reduce(
            (acc: any, v: Recipient) => ({
              ...acc,
              [v.id]: {
                value: turnOn ? v.hasScheduleAccess : false,
                representatives: v?.representatives?.map(e =>
                  turnOn ? v.hasScheduleAccess && !!e.phoneNumber : false
                ),
              },
            }),
            {}
          )
        );
    },
    [setCheckboxes, recipients]
  );

  useEffect(() => {
    changeAllCheckboxes(true);
  }, [recipients]);

  const handleChangeParent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: {
        value: event.target.checked,
        representatives: checkboxes?.[event.target.name]?.representatives?.map(_ => event.target.checked),
      },
    });
  };

  const handleChangeChild = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [parent, child] = event.target.name?.split('-');
    const representatives = checkboxes?.[parent]?.representatives?.map((e, i) =>
      i === Number(child) ? event.target.checked : e
    );
    setCheckboxes({
      ...checkboxes,
      [parent]: {
        value: representatives?.some(e => e),
        representatives,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const doSend = async () => {
    setSending(true);
    setSent(false);

    await sendNotifications(
      recipients
        ?.filter(e => checkboxes?.[e.id]?.value)
        ?.map(e => {
          const reps: string[] =
            e?.representatives
              ?.filter((r, i) => r?.phoneNumber && checkboxes?.[e.id]?.representatives?.[i])
              ?.map(r => r.phoneNumber as string) || [];
          return {
            id: e.id,
            phoneNumbers: reps,
          };
        }),
      message
    );

    setSending(false);
    setSent(true);
  };

  const selectAll = () => changeAllCheckboxes(true);

  const deselectAll = () => changeAllCheckboxes(false);

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <DialogTitle>
        <Typography variant="h6">
          Send notification of the new schedule to the{' '}
          {`${destination.split(/(?=[A-Z])/).map(word => word.toLowerCase())}`} representative
        </Typography>
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={7}>
            <Box display="flex" style={{ gap: theme.spacing(1) }}>
              <Button color="primary" variant="text" onClick={selectAll} style={{ width: 'fit-content' }}>
                Select all
              </Button>
              <Button color="primary" variant="text" onClick={deselectAll} style={{ width: 'fit-content' }}>
                Deselect all
              </Button>
            </Box>
            <TableContainer style={{ width: '100%' }}>
              <Table aria-labelledby="selectRecipientTable" aria-label="table of recipients" size="small">
                <TableHead>
                  <TableCell></TableCell>
                  <TableCell>Select Anesthesiologist</TableCell>
                  <TableCell>Current Access</TableCell>
                  <TableCell>Change Access</TableCell>
                </TableHead>
                <TableBody>
                  {recipients.map((e: Recipient) => (
                    <>
                      <TableRow key={e.id} hover>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={(checkboxes as any)[e.id]?.value || false}
                            onChange={handleChangeParent}
                            name={`${e.id}`}
                            disabled={!e.hasScheduleAccess}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>{e.name}</Typography>
                          <Typography>{e.phoneNumber || '(No Phone Number)'}</Typography>
                        </TableCell>
                        <ScheduleAccess date={date} scheduleUser={e} destination={destination} />
                      </TableRow>
                      {(checkboxes as any)[e.id]?.representatives?.map((r: boolean, i: number) => (
                        <TableRow key={i} hover>
                          <TableCell colSpan={1} />
                          <TableCell colSpan={3}>
                            <FormControlLabel
                              control={
                                <Checkbox checked={r || false} onChange={handleChangeChild} name={`${e.id}-${i}`} />
                              }
                              label={
                                e?.representatives?.[i]?.phoneNumber !== null &&
                                e?.representatives?.[i]?.phoneNumber !== undefined ? (
                                  `${e?.representatives?.[i]?.name} (${e?.representatives?.[i]?.phoneNumber})`
                                ) : (
                                  <Typography>
                                    {`${e?.representatives?.[i]?.name} `}{' '}
                                    <Box display="inline" color="error">
                                      (x)
                                    </Box>
                                  </Typography>
                                )
                              }
                              disabled={!(e.hasScheduleAccess && e?.representatives?.[i]?.phoneNumber)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Typography gutterBottom>
              Make sure to enter Anesthesiologist Members with their phone numbers, in order for them to receive
              messages&#40;add in anesthesiologist member profile&#41;
            </Typography>
            <TextField
              label="This is the message that will be sent to the vendor representative"
              multiline
              minRows={10}
              variant="filled"
              fullWidth={true}
              value={message}
              onChange={handleMessageChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.action}>
        {sent && (
          <Box className={classes.success}>
            <DoneAllIcon style={{ color: 'green' }} />
            <Typography style={{ color: 'green' }}>Notifications Sent</Typography>
          </Box>
        )}
        <Button variant="contained" color="primary" onClick={doSend} fullWidth={false} disabled={sending}>
          Send Notifications
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    minWidth: '75rem',
    minHeight: '40rem',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '8fr .2fr 6fr',
    marginBottom: theme.spacing(1),
  },
  action: {
    margin: theme.spacing(1),
  },
  success: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default SendNotificationDialog;
