import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Cursor = ({ y, children }: { y: number | string; children: any }) => {
  const classes = useStyles(y);

  return <Box className={classes.cursor}>{children}</Box>;
};

const useStyles = makeStyles((theme: Theme) => ({
  cursor: {
    position: 'absolute',
    top: y => `calc(${y}%)`,
    left: '4em',
    right: '0',
    borderTop: '1px solid rgba(232, 29, 29, 0.5)',
    zIndex: 8,
    [theme.breakpoints.down('md')]: {
      left: '2.5em',
    },
  },
}));

export default Cursor;
