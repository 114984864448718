import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import HtmlInput from '../html/Input';

import { withLabel } from '../Label';
import LinkButton from '../LinkButton';
import { withTheme } from '../../theme';
import InputError from '../errors/InputError';
import { withDescription } from '../Description';
import Box from '@material-ui/core/Box';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextInput from './TextInput';
import { withProps } from 'recompose';

const PasswordHtmlInput = styled(HtmlInput)`
  font-family: ${withTheme((theme, { fixedWidth }) => (fixedWidth ? theme.fontFamily.fixedWidth : 'inherit'))};
  flex: 1;
`;

const VisiblityButton = styled(LinkButton)`
  padding: 0 ${withTheme(theme => theme.input.padding)};
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 1.25em;
`;

const PasswordInput = ({
  Input = HtmlInput,
  Error = InputError,
  name,
  autoFocus,
  value,
  onChange,
  isDisabled,
  error,
  hasError,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = e => {
    e.preventDefault();

    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      <Box display="flex" position="relative">
        <PasswordHtmlInput
          id={name}
          fixedWidth={showPassword}
          type={showPassword ? 'text' : 'password'}
          name="password"
          autoComplete="current-password"
          autoCapitalize="off"
          autoCorrect="off"
          placeholder="e.g., •••••••••"
          autoFocus={autoFocus || false}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          disabled={isDisabled}
          error={error}
          hasError={hasError}
          {...rest}
        />
        <VisiblityButton type="button" onClick={toggleVisibility} disabled={isDisabled}>
          {showPassword ? <VisibilityOff fontSize={'inherit'} /> : <Visibility fontSize={'inherit'} />}
        </VisiblityButton>
      </Box>
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </Fragment>
  );
};

const GeneralPasswordInput = withLabel('Password')(PasswordInput);

export const OneTimePasswordInput = withProps({ autoFocus: true, autoComplete: 'off' })(
  withLabel('One-time Password')(TextInput)
);

export const CreatePasswordInput = withDescription(
  'Password must be at least 8 characters long and contain numbers, lowercase and uppercase letters'
)(GeneralPasswordInput);

export default GeneralPasswordInput;
