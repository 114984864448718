import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { dischargePhysicianLastPatient } from '../../../../graph/patients';

const withDischargeLastPhysicianPatientMutation = Component => props =>
  (
    <Mutation mutation={dischargePhysicianLastPatient}>
      {withDischargeLastPhysicianPatient => (
        <Component {...props} dischargePhysicianLastPatient={withDischargeLastPhysicianPatient} />
      )}
    </Mutation>
  );

export default withDischargeLastPhysicianPatientMutation;
