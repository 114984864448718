import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import { Row, SubtleValue } from '../../../se/components/collection/mobile/shared';
import RouterLink from '../../../se/components/RouterLink';

const SuggestionBox = styled.div`
  max-height: 200px;
  padding: 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

const FeedbackMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const patientIdColumn = columns.find(_ => _.key === 'patientId');
  const ratingColumn = columns.find(_ => _.key === 'rating');
  const dateSubmittedColumn = columns.find(_ => _.key === 'submittedAt');
  const suggestionColumn = columns.find(_ => _.key === 'suggestion');
  const suggestionValue = suggestionColumn.lens(item, context);

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <Row style={{ marginBottom: '0.75rem' }}>
          <patientIdColumn.Component data={patientIdColumn.lens(item, context)} />
          {dateSubmittedColumn && (
            <SubtleValue>
              <dateSubmittedColumn.Component data={dateSubmittedColumn.lens(item, context)} />
            </SubtleValue>
          )}
        </Row>

        <Row style={{ marginBottom: '0.75rem' }}>
          <ratingColumn.Component data={ratingColumn.lens(item, context)} />
        </Row>

        {suggestionValue ? (
          <Row style={{ marginBottom: '0.75rem', maxWidth: 400 }}>
            <SuggestionBox>{suggestionValue}</SuggestionBox>
          </Row>
        ) : (
          ''
        )}
      </ListItemLink>
    </Fragment>
  );
};

export default FeedbackMobileListItem;
