import React, { useEffect, useRef } from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireValue } from '../hooks';
import toString from '../../../../../questionnaire/toString';

const styles = {
  inputField: {
    border: 0,
    outline: 0,
    background: 'lightgrey',
    width: '100%',
    resize: 'none',
    padding: '.25em .5em',
    boxSizing: 'border-box',
  },
};
// Change this if needed in the future, currently they only requested for allergies1
const emptyOrNoneValue = (allEmpty, name) => (name === 'allergies1' && !allEmpty ? 'NKA' : '');

const NamedInput = ({ name, path, paths = [], reducer, warning, fontSize }) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValue(reducer || toString, ...[path, ...paths].filter(path => path));
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [value, questionnaireValue]);

  return (
    <textarea
      ref={textareaRef}
      name={name}
      rows={1}
      style={{
        ...styles.inputField,
        color: warning ? 'red' : undefined,
        fontSize: fontSize,
        paddingBottom:'.8rem'
      }}
      value={
        value ||
        questionnaireValue ||
        defaultValue ||
        emptyOrNoneValue(value === undefined && questionnaireValue === undefined && defaultValue === undefined, name)
      }
      onChange={e => setValue(e.target.value || ' ')} // had to write ' '(blank charter) so we could delete value of input
    />
  );
};

const MedicalPassportTextArea = ({
  label,
  name,
  path,
  paths,
  reducer,
  style,
  labelStyle,
  warning,
  fontSize = undefined,
}) => (
  <div style={style}>
    {label && <div style={{ margin: '.25em .5em', ...labelStyle }}>{label}:</div>}
    <NamedInput name={name} path={path} paths={paths} reducer={reducer} warning={warning} fontSize={fontSize} />
  </div>
);

export default MedicalPassportTextArea;
