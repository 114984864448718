import React, { useMemo, useState } from 'react';
import { Box, Icon, makeStyles, MenuItem, MenuList, Popover, TextField, Chip } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import Spinner from '../../../../../se/components/Spinner';
import { useMutation, useQuery } from '@apollo/client';
import { list as listAnesthesiologists } from '../../../../../graph/anesthesiologists';
import { addAnesthesiologistShiftRoom } from '../../../../../graph/rooms';
import { removeAnesthesiologistShiftRoom } from '../../../../../graph/rooms';
import anesthesiaSvgIcon from '../../../../../assets/images/anesthesia.svg';
import { format } from 'date-fns';
import { Room } from '../../../../../types/Room';

const AnesthesiologistNameRoom = ({
  date,
  roomId,
  roomType,
  room,
  isKiosk,
}: {
  date?: Date;
  roomId: number;
  roomType?: string;
  room?: Room;
  isKiosk?: boolean;
}) => {
  const classes = useStyles();

  const searchEl = React.useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [search, setSearch] = useState('');
  const popoverId = open ? 'simple-popover' : undefined;

  const { data, loading } = useQuery(listAnesthesiologists);
  const anesthesiologists = useMemo(() => data?.anesthesiologists || [], [data]);

  const filteredAnesthesiologists = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return anesthesiologists.filter(a => chunks.every(chunk => a.name.toLowerCase().includes(chunk)));
  }, [search, anesthesiologists]);

  const [setRoomAnesthesiologist] = useMutation(addAnesthesiologistShiftRoom);
  const [removeRoomAnesthesiologist] = useMutation(removeAnesthesiologistShiftRoom);

  const openEditor = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const selectAnesthesiologist = async anesthesiologist => {
    try {
      date &&
        (await setRoomAnesthesiologist({
          variables: {
            id: roomId,
            date: format(date, 'YYYY-MM-DD'),
            anesthesiologistId: anesthesiologist.id,
            roomTypeString: roomType,
          },
        }));
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const removeSelectedAnesthesiologist = async anesthesiologist => {
    try {
      date &&
        (await removeRoomAnesthesiologist({
          variables: { id: anesthesiologist.id, roomTypeString: roomType },
        }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        {!isKiosk && (
          <Box onClick={openEditor} className={classes.addIcon}>
            <Icon>
              <img src={anesthesiaSvgIcon} alt="Add Anesthesiologist" width={22} height={22} />
            </Icon>
          </Box>
        )}

        {room?.anesthesiologistShifts?.map(a => (
          <Chip
            key={a.id}
            label={a?.anesthesiologist.name.split(' ').slice(-1)}
            onClick={!isKiosk ? () => removeSelectedAnesthesiologist(a) : undefined}
            onDelete={!isKiosk ? () => removeSelectedAnesthesiologist(a) : undefined}
            deleteIcon={!isKiosk ? <RemoveCircleOutline className={classes.iconRemove} /> : undefined}
            className={classes.chip}
          />
        ))}
      </Box>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {loading ? (
          <Box className={classes.spinnerContainer}>
            <Spinner />
          </Box>
        ) : (
          <Box className={classes.root}>
            <TextField
              size="small"
              variant="outlined"
              value={search}
              onChange={handleSearchUpdate}
              inputRef={searchEl}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
              }}
              className={classes.textFieldRoot}
            />
            <MenuList className={classes.menu}>
              {filteredAnesthesiologists.map(a => (
                <MenuItem key={a.id} onClick={() => selectAnesthesiologist(a)}>
                  {a.name}
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        )}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: { maxHeight: '38rem', overflow: 'hidden' },
  menu: { maxHeight: '28rem', overflowY: 'scroll' },
  textFieldRoot: { margin: '1rem', marginBottom: 0 },
  searchIcon: { marginLeft: '0.25em' },
  spinnerContainer: { padding: '1rem' },
  addIcon: { cursor: 'pointer', marginRight: '0.5rem' },
  chip: { marginLeft: '0.5rem' },
  iconRemove: { color: 'red' },
}));

export default AnesthesiologistNameRoom;
