import React, { useContext, useState } from 'react';
import ORPerformancePieChart from './v2/charts/ORPerformancePieChart';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import { ORUtilizationAnalyticsQuery, physicianUtilizationAnalyticsQuery } from '../../../graph/dashboard';
import { format, subDays } from 'date-fns';
import { CircularProgress, Paper } from '@material-ui/core';
import { toPieChartData } from './ORUtilizationAnalytics';
import TableWithColumnSelector, { OnClickFn, TableType } from '../../core/TableWithColumnSelector';
import { PhysicianUtilizationTableData } from '../../../types/Analytics';
import { getMinutes } from './util';
import { TimelineColumnLegend } from './components/ColumnLegend';
import imagePIPO from '../../../assets/images/illustrations/info/PIPO.svg';
import imagePOPI from '../../../assets/images/illustrations/info/PHYSICIAN_POPI.svg';
import startToClose from '../../../assets/images/illustrations/info/start_to_close.svg';
import poToCut from '../../../assets/images/illustrations/info/po_to_cut.svg';
import { ChartWrapper } from './v2/PieCharts';
import { useScope } from '../../../hooks/useScope';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ArrowBack } from '@material-ui/icons';
import PhysicianPatientsAnalytics from './PhysicianPatientsAnalytics';
import { Aggregation, AggregationContext } from './Aggregation';

const toTableData = (data: PhysicianUtilizationTableData[], onClick?: OnClickFn): TableType => {
  const baseHeaders = [
    'Physician',
    'Procedures',
    'PIPO',
    'Physician Turnover',
    'Start to Close',
    'Closing',
    'Patient Out to First Cut',
  ];

  return {
    configHeader: [...baseHeaders],
    headers: [
      {
        id: 'h1',
        columns: [
          'Physician',
          'Procedures',
          {
            text: 'PIPO',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={imagePIPO}
                title="PIPO"
                description='Average operating room time for patients under the care of the same physician. Operating room time is "wheels-in, wheels-out".'
              />
            ),
          },
          {
            text: 'Physician Turnover',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={imagePOPI}
                title="Physician Turnover"
                description="Time of Patient Procedure End to next patient First Cut, by surgeon"
              />
            ),
          },
          {
            text: 'Start to Close',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={startToClose}
                title="Start to Close"
                description="Time from case start to start of closing"
              />
            ),
          },
          'Closing',
          {
            text: 'Patient Out to First Cut',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={poToCut}
                title="PO to cut"
                description="Time from surgeons previous cases patient out of room, to time of next procedures start time."
              />
            ),
          },
        ],
      },
    ],
    rows: (data || []).map(e => ({
      id: e?.id.toString(),
      columns: [
        e.physician,
        e.numberOfProcedures.toString(),
        getMinutes(e.pipoAvg),
        getMinutes(e.popiPhysicianAvg),
        getMinutes(e.startToCloseAvg),
        getMinutes(e.closingAvg),
        getMinutes(e.poToCutAvg),
      ],
      onClick,
    })),
  };
};

const MiniRow = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box display="flex">
      <Typography style={{ flex: 1 }}>{title}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

const AnalyticsScreen = () => {
  const toDate = new Date();
  const to = toDate.toISOString().split('T')[0];
  const fromDate = subDays(new Date(), 7);
  const from = fromDate.toISOString().split('T')[0];
  const dateRange = { from, to };

  const scope: any = useScope();

  const { data: orAnalyticsData, loading: loading1 } = useQuery(ORUtilizationAnalyticsQuery, {
    variables: {
      filter: {
        dateRange: dateRange,
      },
    },
  });

  const { data: physicianAnalyticsData, loading: loading2 } = useQuery(physicianUtilizationAnalyticsQuery, {
    variables: {
      filter: {
        dateRange: dateRange,
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const aggregation = useContext(AggregationContext);

  const orRoomsAnalytics = orAnalyticsData?.ORUtilizationAnalytics || [];
  const orRoomsAnalyticsPieData = toPieChartData(orAnalyticsData?.ORUtilizationAnalytics || [], null, aggregation);
  const physicianAnalytics = physicianAnalyticsData?.physicianUtilizationAnalytics || [];

  const onPhysicianClick = (physicianId: string) => {
    const id = physicianId?.split('#')?.[0];
    setPhysicianId({ idNumber: Number(id), idString: physicianId });
  };

  const table: TableType = toTableData(physicianAnalytics, onPhysicianClick);

  const [physicianId, setPhysicianId] = useState<{
    idNumber: number;
    idString: string;
  } | null>(null);

  if (loading1 || loading2) return <CircularProgress />;

  const onBack = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPhysicianId(null);
  };

  if (physicianId) {
    const data = physicianAnalytics.find(e => e?.id === physicianId.idString);

    return (
      <>
        <Button variant="contained" startIcon={<ArrowBack />} onClick={onBack} style={{ marginBottom: 8 }}>
          Back to Physician Utilization Analytics
        </Button>
        <PhysicianPatientsAnalytics
          physicianId={physicianId.idNumber}
          physicianName={data?.physician}
          dateRange={{ from: fromDate, to: toDate }}
          filter={{
            dateRange: dateRange,
          }}
        />
      </>
    );
  }

  return (
    <Box m={2} display="flex" flexDirection="column" style={{ gap: '0.5em' }}>
      <Box display="flex" style={{ gap: '0.5em' }}>
        {orRoomsAnalyticsPieData.map((orRoom, index) => (
          <ChartWrapper title={`${orRoom?.title} Performance`}>
            <Box display="flex" flexDirection="column" style={{ gap: 8, flex: 1, minHeight: 0 }}>
              <ORPerformancePieChart
                data={orRoomsAnalyticsPieData}
                activeIndex={index}
                name={`${orRoomsAnalyticsPieData[index].title}`}
                subtitle={'performance'}
                aspect={1}
                minWidth={200}
                isAnimationActive={false}
                innerRadius={'50%'}
                outerRadius={'80%'}
                outerRingInnerRadius={'85%'}
                outerRingOuterRadius={'90%'}
                orTurnoverInnerRadius={'95%'}
                orTurnoverOuterRadius={'100%'}
                displayLegend={false}
              />
              <Divider />
              <Box display="flex" flexDirection="column" style={{ gap: 4 }}>
                <MiniRow title="Canceled" value={orRoomsAnalytics[index]?.canceled || 0} />
                <Divider />
                <MiniRow title="OR Turnover" value={getMinutes(orRoom?.turnover || 0)} />
                <Divider />
                <MiniRow title="PIPO" value={getMinutes(orRoomsAnalytics[index]?.pipoAvg || 0)} />
                <Divider />
                <MiniRow title="Surgery Time" value={getMinutes(orRoom?.inSurgery || 0)} />
                <Divider />
                <MiniRow title="Cleaning Time" value={getMinutes(orRoom?.cleaning || 0)} />
              </Box>
            </Box>
          </ChartWrapper>
        ))}
      </Box>

      <Box>
        <TableWithColumnSelector
          configName="physician-table-config"
          tableId="Physician-Analysis-table"
          tableName={`Analysis by Physician`}
          withExportFile={false}
          {...table}
        />
      </Box>

      <Paper style={{ width: '100%' }}>
        <Box p={2} display="flex" flexDirection="column" alignItems="center" style={{ gap: '0.5em' }}>
          <Box>
            Analytics for the period from {format(fromDate, 'D MMMM')} to {format(toDate, 'D MMMM')}
          </Box>
          <Box>{scope?.hospital?.name || ''}</Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AnalyticsScreen;
