import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { MenuRounded } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    minWidth: 0,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default ({ Sidebar, children, styles, ...props }) => {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  return (
    <Box className={classes.root}>
      <Fab onClick={toggleDrawer(true)} color="primary" aria-label="toggle menu" className={classes.fab}>
        <MenuRounded />
      </Fab>
      {Sidebar && (
        <Sidebar anchor={'left'} open={state} onOpen={toggleDrawer(true)} onClose={toggleDrawer(false)} {...props} />
      )}
      <main role="main" className={classes.content}>
        {React.Children.only(children)}
      </main>
    </Box>
  );
};
