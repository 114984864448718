import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import RouterLink from '../../../se/components/RouterLink';

const HospitalMobileListItem = ({
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.key === 'name');
  const addressColumn = columns.find(_ => _.key === 'address');
  const contactColumn = columns.find(_ => _.key === 'contact');

  return (
    <ListItemLink
      component={showUrlProvider ? RouterLink : undefined}
      to={
        showUrlProvider
          ? {
              pathname: showUrlProvider(idProvider(item)),
              search: location.search,
            }
          : undefined
      }
      onClick={onClick}
    >
      {nameColumn && (
        <Typography gutterBottom>
          <nameColumn.Component data={nameColumn.lens(item, context)} />
        </Typography>
      )}
      <Typography gutterBottom>
        <addressColumn.Component data={addressColumn.lens(item, context)} />
      </Typography>
      <Typography>
        <contactColumn.Component data={contactColumn.lens(item, context)} />
      </Typography>
    </ListItemLink>
  );
};

export default HospitalMobileListItem;
