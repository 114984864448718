import React, { FormEvent, ReactNode, Reducer, useEffect, useReducer, useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAccountBoxMultiple } from '@mdi/js';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Room } from '../../../../../types/Room';
import StaffShift from '../../../../../types/StaffShift';
import { Alert } from '@material-ui/lab';
import { gql, useMutation } from '@apollo/client';
import Spinner from '../../../../../se/components/Spinner';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

interface StaffShiftCopyButtonProps {
  rooms: Room[];
}

const StaffShiftCopyButton = ({ rooms }: StaffShiftCopyButtonProps) => {
  const [open, setOpen] = useState(false);

  const [copyStaffShiftRooms, { called, error, loading }] = useMutation(gql`
    mutation copyStaffShiftRooms($ids: [Long!]!, $numberOfDays: Int!) {
      copyStaffShiftRooms(ids: $ids, numberOfDays: $numberOfDays)
    }
  `);

  const [copyStaffShiftRoomsWeekly] = useMutation(gql`
    mutation copyStaffShiftRoomsWeekly($ids: [Long!]!, $numberOfWeeks: Int!) {
      copyStaffShiftRoomsWeekly(ids: $ids, numberOfWeeks: $numberOfWeeks)
    }
  `);

  const [numberOfDays, setNumberOfDays] = useState('');
  const [numberOfDaysError, setNumberOfDaysError] = useState('');

  const [copyType, setCopyType] = useState<'days' | 'weeks'>('days');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!numberOfDays) {
      setNumberOfDaysError(`Number of following ${copyType} cannot be empty.`);
      return;
    }

    if (!numberOfDays.match(/^\d+$/)) {
      setNumberOfDaysError(`Number of following ${copyType} must be a natural number.`);
      return;
    }

    const n = Number(numberOfDays);
    if (n > 52 && copyType === 'weeks') {
      setNumberOfDaysError('Number of weeks cannot be greater than 52.');
      return;
    }

    if (n > 365 && copyType === 'days') {
      setNumberOfDaysError('Number of days cannot be greater than 365.');
      return;
    }

    if (!state.selection) {
      return;
    }

    const ids = state.selection.roomSelections.flatMap(roomSelection =>
      roomSelection.staffShiftSelections.flatMap(staffShiftSelection =>
        staffShiftSelection.selected ? [staffShiftSelection.staffShift.id] : []
      )
    );

    console.log(ids);
    console.log(n);
    const mutation = copyType === 'days' ? copyStaffShiftRooms : copyStaffShiftRoomsWeekly;

    await mutation({
      variables: {
        ids,
        [copyType === 'days' ? 'numberOfDays' : 'numberOfWeeks']: n,
      },
    });

    setOpen(false);
  };

  const [state, dispatch] = useReducer(reduceEvents, { interactions: [] });

  const anyChecked = state.selection?.selected !== false;

  useEffect(() => {
    if (open) {
      dispatch({ type: 'Reset' });
    }
  }, [open]);

  useEffect(() => {
    dispatch({
      type: 'Restart',
      rooms,
    });
  }, [rooms]);

  const theme = useTheme();

  return (
    <>
      <Tooltip title="Staff Shift Duplication">
        <IconButton onClick={() => setOpen(true)} color="primary">
          <Icon path={mdiAccountBoxMultiple} size={1} />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth={true} open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>
          Apply This Day’s Staff Members to the Following {copyType === 'days' ? 'Days' : 'Weeks'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body2" gutterBottom>
                Select staff members and enter the number of future {copyType === 'days' ? 'days' : 'weeks'} to apply
                them to.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Excluding weekends. Existing assignments will not be altered.
              </Typography>
            </DialogContentText>
            {state.rooms ? (
              state.selection ? (
                <>
                  {error && <Alert severity="error">An unexpected error occurred. Please try again.</Alert>}
                  <Box mt={4} mb={4} display="flex" alignItems="center">
                    <ToggleButtonGroup
                      value={copyType}
                      exclusive
                      onChange={(e, value) => setCopyType(value)}
                      aria-label="Copy Type"
                    >
                      <ToggleButton value="days">Days</ToggleButton>
                      <ToggleButton value="weeks">Weeks</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <TextField
                    error={Boolean(numberOfDaysError)}
                    helperText={numberOfDaysError}
                    variant="filled"
                    type="number"
                    label={copyType === 'days' ? 'Number of following days' : 'Number of weeks'}
                    autoFocus
                    fullWidth
                    value={numberOfDays}
                    onChange={e => setNumberOfDays(e.target.value)}
                    onFocus={() => setNumberOfDaysError('')}
                  />

                  <Selector
                    name="All staff members"
                    selected={state.selection.selected}
                    onSelect={() =>
                      dispatch({
                        type: 'Interact',
                        interaction: {
                          type: 'SelectAll',
                        },
                      })
                    }
                    onDeselect={() =>
                      dispatch({
                        type: 'Interact',
                        interaction: {
                          type: 'DeselectAll',
                        },
                      })
                    }
                  >
                    {state.selection.roomSelections.map(({ selected, room, staffShiftSelections }) => (
                      <Selector
                        key={room.id}
                        name={room.name}
                        selected={selected}
                        onSelect={() =>
                          dispatch({
                            type: 'Interact',
                            interaction: {
                              type: 'SelectRoom',
                              roomId: room.id,
                            },
                          })
                        }
                        onDeselect={() =>
                          dispatch({
                            type: 'Interact',
                            interaction: {
                              type: 'DeselectRoom',
                              roomId: room.id,
                            },
                          })
                        }
                      >
                        {staffShiftSelections.map(({ selected, staffShift }) =>
                          staffShift.staff ? (
                            <Selector
                              key={staffShift.id}
                              name={staffShift.staff.name}
                              selected={selected}
                              onSelect={() =>
                                dispatch({
                                  type: 'Interact',
                                  interaction: {
                                    type: 'SelectStaffShift',
                                    staffShiftId: staffShift.id,
                                  },
                                })
                              }
                              onDeselect={() =>
                                dispatch({
                                  type: 'Interact',
                                  interaction: {
                                    type: 'DeselectStaffShift',
                                    staffShiftId: staffShift.id,
                                  },
                                })
                              }
                            />
                          ) : null
                        )}
                      </Selector>
                    ))}
                  </Selector>
                </>
              ) : (
                <Alert severity="error">
                  <Typography gutterBottom>There are no staff shifts to apply.</Typography>
                  <Typography>Close this dialog and review this day’s assignments.</Typography>
                </Alert>
              )
            ) : (
              <Typography>Loading…</Typography>
            )}
          </DialogContent>
          <DialogActions style={{ position: 'sticky', bottom: 0, background: theme.palette.background.paper }}>
            <Button type="reset" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Tooltip
              title="Please select staff members first."
              disableFocusListener={anyChecked}
              disableHoverListener={anyChecked}
              disableTouchListener={anyChecked}
            >
              <Button
                type="submit"
                disabled={loading || !anyChecked || !state.selection}
                onClick={() => {}}
                color="primary"
              >
                Apply
              </Button>
            </Tooltip>

            {loading && (
              <Spinner
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '0.5rem',
                }}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default StaffShiftCopyButton;

interface State {
  rooms?: Room[];
  selection?: Selection;
  interactions: Interaction[];
}

interface Selection {
  selected: boolean | null;
  roomSelections: RoomSelection[];
}

interface RoomSelection {
  selected: boolean | null;
  room: Room;
  staffShiftSelections: StaffShiftSelection[];
}

interface StaffShiftSelection {
  selected: boolean;
  staffShift: StaffShift;
}

type Interaction = SelectAll | SelectRoom | SelectStaffShift | DeselectAll | DeselectRoom | DeselectStaffShift;

interface SelectAll {
  type: 'SelectAll';
}

interface SelectRoom {
  type: 'SelectRoom';
  roomId: any;
}

interface SelectStaffShift {
  type: 'SelectStaffShift';
  staffShiftId: any;
}

interface DeselectAll {
  type: 'DeselectAll';
}

interface DeselectRoom {
  type: 'DeselectRoom';
  roomId: any;
}

interface DeselectStaffShift {
  type: 'DeselectStaffShift';
  staffShiftId: any;
}

type Action = Reset | Restart | Interact;

interface Reset {
  type: 'Reset';
}

interface Restart {
  type: 'Restart';
  rooms: Room[];
}

interface Interact {
  type: 'Interact';
  interaction: Interaction;
}

const reduceEvents: Reducer<State, Action> = (prevState, action) => {
  switch (action.type) {
    case 'Reset': {
      if (prevState.rooms) {
        return {
          rooms: prevState.rooms,
          selection: createSelection(prevState.rooms),
          interactions: [],
        };
      }

      return { interactions: [] };
    }
    case 'Restart': {
      const initialSelection = createSelection(action.rooms);

      if (initialSelection) {
        return {
          rooms: action.rooms,
          selection: prevState.interactions.reduce(applyInteraction, initialSelection),
          interactions: prevState.interactions,
        };
      }

      return {
        rooms: action.rooms,
        interactions: prevState.interactions,
      };
    }
    case 'Interact': {
      const interactions = [...prevState.interactions, action.interaction];
      return {
        rooms: prevState.rooms,
        selection: prevState.selection ? applyInteraction(prevState.selection, action.interaction) : undefined,
        interactions,
      };
    }
    default:
      return prevState;
  }
};

const applyInteraction = (selection: Selection, interaction: Interaction): Selection => {
  switch (interaction.type) {
    case 'SelectAll':
      return {
        selected: true,
        roomSelections: selection.roomSelections.map(({ room, staffShiftSelections }) => ({
          selected: true,
          room,
          staffShiftSelections: staffShiftSelections.map(({ staffShift }) => ({
            selected: true,
            staffShift,
          })),
        })),
      };
    case 'SelectRoom': {
      const roomSelections = selectRoom(selection.roomSelections, interaction.roomId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    case 'SelectStaffShift': {
      const roomSelections = selectStaffShift(selection.roomSelections, interaction.staffShiftId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    case 'DeselectAll':
      return {
        selected: false,
        roomSelections: selection.roomSelections.map(({ room, staffShiftSelections }) => ({
          selected: false,
          room,
          staffShiftSelections: staffShiftSelections.map(({ staffShift }) => ({
            selected: false,
            staffShift,
          })),
        })),
      };
    case 'DeselectRoom': {
      const roomSelections = deselectRoom(selection.roomSelections, interaction.roomId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    case 'DeselectStaffShift': {
      const roomSelections = deselectStaffShift(selection.roomSelections, interaction.staffShiftId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    default:
      return selection;
  }
};

const selectRoom = (roomSelections: RoomSelection[], roomId: any): RoomSelection[] =>
  roomSelections.map(({ selected, room, staffShiftSelections }) => ({
    selected: room.id === roomId ? true : selected,
    room,
    staffShiftSelections:
      room.id === roomId
        ? staffShiftSelections.map(({ selected, staffShift }) => ({
            selected: true,
            staffShift,
          }))
        : staffShiftSelections,
  }));

const selectStaffShift = (roomSelections: RoomSelection[], staffShiftId: any): RoomSelection[] =>
  roomSelections.map(({ room, staffShiftSelections: prevStaffShiftSelections }) => {
    const staffShiftSelections = prevStaffShiftSelections.map(({ selected, staffShift }) => ({
      selected: staffShift.id === staffShiftId ? true : selected,
      staffShift,
    }));

    return {
      selected:
        staffShiftSelections.every(staffShiftSelection => staffShiftSelection.selected) ||
        (staffShiftSelections.every(staffShiftSelection => !staffShiftSelection.selected) ? false : null),
      room,
      staffShiftSelections,
    };
  });

const deselectRoom = (roomSelections: RoomSelection[], roomId: any): RoomSelection[] =>
  roomSelections.map(({ selected, room, staffShiftSelections }) => ({
    selected: room.id === roomId ? false : selected,
    room,
    staffShiftSelections:
      room.id === roomId
        ? staffShiftSelections.map(({ selected, staffShift }) => ({
            selected: false,
            staffShift,
          }))
        : staffShiftSelections,
  }));

const deselectStaffShift = (roomSelections: RoomSelection[], staffShiftId: any): RoomSelection[] =>
  roomSelections.map(({ selected, room, staffShiftSelections: prevStaffShiftSelections }) => {
    const staffShiftSelections = prevStaffShiftSelections.map(({ selected, staffShift }) => ({
      selected: staffShift.id === staffShiftId ? false : selected,
      staffShift,
    }));

    return {
      selected:
        staffShiftSelections.every(staffShiftSelection => staffShiftSelection.selected) ||
        (staffShiftSelections.every(staffShiftSelection => !staffShiftSelection.selected) ? false : null),
      room,
      staffShiftSelections,
    };
  });

const createSelection = (rooms: Room[]): Selection | undefined => {
  const roomSelections = rooms
    .map(room => ({
      selected: true,
      room,
      staffShiftSelections: room.staffShifts
        .filter(staffShift => staffShift.staff)
        .map(staffShift => ({
          selected: true,
          staffShift,
        })),
    }))
    .filter(room => room.staffShiftSelections.length > 0);

  if (roomSelections.length > 0) {
    return {
      selected: true,
      roomSelections,
    };
  }

  return undefined;
};

interface SelectorProps {
  name: string;
  selected: boolean | null;
  onSelect: () => void;
  onDeselect: () => void;
  children?: ReactNode;
}

const Selector = ({ name, selected, onSelect, onDeselect, children }: SelectorProps) => (
  <Box>
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(selected)}
          indeterminate={selected === null}
          onChange={e => (e.target.checked ? onSelect() : onDeselect())}
        />
      }
      label={name}
    />
    <Box ml={2}>{children}</Box>
  </Box>
);
