import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';
import { alpha, Box, Button } from '@material-ui/core';
import clsx from 'clsx';

interface MobileORNavigationProps extends RouteComponentProps {
  rooms?: { id: string; name: string }[];
  alignRight: boolean;
}

const MobileORNavigation: React.FC<MobileORNavigationProps> = ({ rooms = [], location, alignRight }) => {
  const classes = useStyles({ alignRight });
  const current = decodeURIComponent(location.hash).replace('#', '');

  return (
    <Box className={classes.root}>
      {rooms.map(({ id, name }, i) => (
        <Button
          key={name}
          component={HashLink}
          to={`${location.search || ''}#${name}`}
          color={current === name || (i === 0 && current === '') ? 'primary' : 'default'}
          className={clsx(classes.tab, {
            [classes.active]: current === name || (i === 0 && current === ''),
          })}
          variant="contained"
          size="small"
          smooth
        >
          {name}
        </Button>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: alpha(theme.palette.text.primary, 0.07),
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0 !important',
    },
    justifyContent: (props: { alignRight: boolean }) => (props.alignRight ? 'flex-end' : 'flex-start'),
  },
  tab: {
    textAlign: 'center',
    marginRight: theme.spacing(2),
    position: 'relative',
  },
  active: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '0.375em solid transparent',
      borderRight: '0.375em solid transparent',
      borderTop: '0.375em solid #00a7f7',
      bottom: '-0.375em',
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  },
}));

export default withRouter(MobileORNavigation);
