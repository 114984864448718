import React, { ReactNode } from 'react';
import { Theme, makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';

interface GridProps {
  isKiosk?: boolean;
  hasMoreThan6ORs?: boolean;
  hasMoreThan6ORsView?: boolean;
  children: ReactNode;
}

const Grid = ({ isKiosk, hasMoreThan6ORs, hasMoreThan6ORsView, children }: GridProps) => {
  const classes = useStyles({ hasMoreThan6ORs, hasMoreThan6ORsView, isKiosk });

  return (
    <Box
      className={clsx(classes.grid, {
        [classes.hasMoreThan6ORsView]: hasMoreThan6ORsView,
        [classes.notHasMoreThan6ORsView]: !hasMoreThan6ORsView,
      })}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flex: '1 0 auto',
    position: 'relative',
    overflow: 'hidden',
  },
  hasMoreThan6ORsView: {
    height: props => (props.isKiosk ? 'auto' : '100vh'),
    minHeight: props => (props.isKiosk ? 0 : '70rem'),
  },
  notHasMoreThan6ORsView: {
    width: props => (props.hasMoreThan6ORs ? 'calc(100% + 1.625 * 100%)' : '100%'),
    height: props => (props.isKiosk ? 'auto' : '100vh'),
    minHeight: props => (props.isKiosk ? 0 : '70rem'),
  },
}));

export default Grid;
