import { format } from 'date-fns';
import get from 'lodash/get';
import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../../se/components/collection/mobile/ListItem';
import {
  Column,
  Label,
  Row,
  SubtleValue,
  TwoItemSection,
  Value,
  WrapValue,
} from '../../../../se/components/collection/mobile/shared';
import RouterLink from '../../../../se/components/RouterLink';
import ListSeparator from '../../../ListSeparator';

const ScheduledProcedureMobileListItem = ({
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return <ListSeparator title={item.label} />;
  }
  const nameColumn = columns.find(_ => _.title === 'Name');
  const physicianColumn = columns.find(_ => _.title === 'Physician');
  const procedureTypeColumn = columns.find(_ => _.title === 'Procedure Type');
  const roomOrDateOfVisit = columns.find(_ => _.title === 'Date');
  const status = columns.find(_ => _.title === 'Status');
  const formFulfillment = columns.find(_ => _.title === 'Questionnaire %');

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <Row style={{ marginBottom: '0.75rem', alignItems: 'center' }}>
          <nameColumn.Component data={nameColumn.lens(item, context)} />
          <SubtleValue>{`[ ${get(item, 'visit')} ]`}</SubtleValue>
          {roomOrDateOfVisit && (
            <SubtleValue>{format(roomOrDateOfVisit.lens(item, context), 'MM/DD/YYYY')}</SubtleValue>
          )}
        </Row>

        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{physicianColumn.title}</Label>
            <Value>{physicianColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{procedureTypeColumn.title}</Label>
            <WrapValue>{procedureTypeColumn.lens(item, context)}</WrapValue>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{status.title}</Label>
            <Value>
              <status.Component data={status.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection>
          <Column>
            <Label>{formFulfillment.title}</Label>
            <Value>
              <formFulfillment.Component data={formFulfillment.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default ScheduledProcedureMobileListItem;
