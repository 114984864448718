import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core';

const DashboardCard = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography>{children}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DashboardCard;
