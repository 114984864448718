import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    width: '100%',
    display: 'grid',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      border: 'none',
      gap: theme.spacing(2),
    },
  },
  mainWrapperLg: {
    gridTemplateColumns: props => (props.numberOfColumns > 1 ? 'repeat(auto-fill, minmax(22vw, 1fr))' : '1fr'),
    alignSelf: 'flex-start',
  },
  mainWrapperSm: {
    gridTemplateColumns: '1fr 1fr',
  },
  mainWrapperXs: {
    gridTemplateColumns: '1fr',
  },
  dataField: {
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      border: 0,
    },
  },
  span: {
    gridColumn: props => `span ${props.span}`,
  },
}));

export const DataField = ({ span = 1, children }) => {
  const classes = useStyles({ span });

  return <Box className={clsx(classes.dataField, classes.span)}>{children}</Box>;
};

export const MainWrapper = ({ numberOfColumns, children }) => {
  const classes = useStyles({ numberOfColumns });
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <div
      className={clsx(
        classes.mainWrapper,
        { [classes.mainWrapperLg]: !isSm && !isXs },
        { [classes.mainWrapperSm]: isSm },
        { [classes.mainWrapperXs]: isXs }
      )}
    >
      {children}
    </div>
  );
};

export const DataTitle = ({ children, ...rest }) => (
  <Typography color="textSecondary" {...rest}>
    {children}
  </Typography>
);

export const DataValue = styled.div`
  margin: 0;
  min-width: 0;
`;

export default ({ columns = [], data }) => (
  <MainWrapper numberOfColumns={columns.length}>
    {columns.map(({ title, lens, Component, span, style }, index) => (
      <DataField key={index} span={span} style={style}>
        {title && (
          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
        )}
        {lens(data) ? <Component data={lens(data)} /> : <Typography>–</Typography>}
      </DataField>
    ))}
  </MainWrapper>
);
