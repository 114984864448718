import React, { Fragment } from 'react';
import MultiSelectInput from './../../se/components/inputs/MultiSelectInput';

type Option = { value: string; label: 'Staff Schedule v1' | 'Staff Schedule v2' | 'OR Schedule v1' | 'OR Schedule v2' };

export const schedulePdfsOptions = [
  {
    label: 'Staff Schedule v1',
    value: 'Staff Schedule v1',
  },
  {
    label: 'Staff Schedule v2',
    value: 'Staff Schedule v2',
  },
  {
    label: 'Schedule list view',
    value: 'OR Schedule v1',
  },
  {
    label: 'ACJS format',
    value: 'OR Schedule v2',
  },
  {
    label: 'Schedule Timeline format',
    value: 'OR Schedule v3',
  },
  {
    label: 'Schedule list view V2',
    value: 'OR Schedule v4',
  },
  {
    label: 'Schedule list view V3',
    value: 'OR Schedule v5',
  },
  {
    label: 'Week Schedule List view',
    value: 'Week Schedule',
  },
];

const SchedulePdfsMultipleSelectInput = ({
  value,
  onChange,
}: {
  value: Option[];
  onChange: (value: Option[]) => void;
}) => {
  return (
    <Fragment>
      <MultiSelectInput
        name={`SchedulePDFs`}
        options={schedulePdfsOptions}
        onChange={onChange}
        value={schedulePdfsOptions.filter(e => !!(value || [])?.find(el => e?.value === el?.value))}
        loading={false}
        disabled={false}
      />
    </Fragment>
  );
};

export default SchedulePdfsMultipleSelectInput;
