import React, { FC, ReactNode, useMemo } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import StaffShiftT from '../../../../../types/StaffShift';
import StaffShiftChip from './StaffShiftChip';
import { sortBy } from 'lodash';
import { Procedure } from '../../../../../types/Procedure';

interface StaffShiftsProps {
  staffShifts: StaffShiftT[];
  inlineText?: boolean;
  procedure?: Procedure;
  isKiosk?: boolean;
  editableStaff?: boolean;
  isFooter?: boolean;
  type: 'RoomShift' | 'ProcedureShift';
  children?: ReactNode;
  showOnlyStaffMember?: boolean;
}

const StaffShifts: FC<StaffShiftsProps> = ({
  staffShifts,
  inlineText,
  procedure,
  isKiosk,
  isFooter = true,
  editableStaff,
  type,
  showOnlyStaffMember,
  children,
}) => {
  const classes = useStyles({ isFooter });

  const sorted = useMemo(() => sortBy(staffShifts, 'id'), [staffShifts]);

  return (
    <Box className={classes.container}>
      <Box>{children}</Box>
      {sorted.map(staffShift => (
        <StaffShiftChip
          key={staffShift.id}
          inlineText={inlineText}
          {...staffShift}
          from={showOnlyStaffMember ? '' : staffShift.from}
          to={showOnlyStaffMember ? '' : staffShift.to}
          shiftState={staffShift.shiftState}
          additionalText={showOnlyStaffMember ? '' : staffShift.additionalText}
          procedure={procedure}
          isKiosk={isKiosk}
          type={type}
          editableStaff={editableStaff}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isFooter: boolean }>(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: props => (props.isFooter ? 'row' : 'column'),
    gap: theme.spacing(0.5),
  },
}));

export default StaffShifts;
