import React from 'react';
import Select from 'react-select';
import { withTheme } from 'styled-components';
import { withTheme as withSpicyTheme } from '../../../se/theme';
import selectStyles from './selectStyles';

const MultiSelectInput = ({ name, value, onChange, loading, options, disabled, hasError, ...rest }) => {
  const theme = withSpicyTheme(theme => theme)({ ...rest });
  const customStyles = selectStyles({ theme, hasError });
  return (
    <Select
      id={name}
      name={name}
      isMulti={true}
      options={options}
      onChange={onChange}
      value={value}
      styles={customStyles}
      isLoading={loading}
      menuPlacement={'auto'}
      disabled={disabled}
      {...rest}
    />
  );
};

export default withTheme(MultiSelectInput);
