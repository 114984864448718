import { PACU, POST_OP, PRE_OP, UTILITY } from '../../../entities/room/enums';
import { Room } from '../../../../types/Room';

export const getSortedRooms = (rooms: Array<Room>, myProceduresChecked: boolean, staffId?: number): Array<Room> =>
  staffId && myProceduresChecked
    ? [
        ...rooms
          .filter(({ type }) => type === PRE_OP)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
        ...rooms
          .filter(({ type }) => type === PACU)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
        ...rooms
          .filter(({ type }) => type === POST_OP)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
        ...rooms
          .filter(({ type }) => type === UTILITY)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
      ]
    : [
        ...rooms.filter(({ type }) => type === PRE_OP),
        ...rooms.filter(({ type }) => type === PACU),
        ...rooms.filter(({ type }) => type === POST_OP),
        ...rooms.filter(({ type }) => type === UTILITY),
      ];
