import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import config from '../config';
import Application from '../se/components/Application';
import ExternalSchedule from './pages/ExternalSchedule';
import Inject from './pages/Inject';
import Kiosk from './pages/Kiosk';
import Print from './pages/Print';
import SuperAdminOrganization from './pages/SuperAdminOrganization';
import User from './pages/User';
import Initialization from './pages/user/Initialization';

const useStyles = makeStyles(theme => ({
  applicationVersion: {
    position: 'fixed',
    right: '0.25rem',
    bottom: '0.25rem',
    color: alpha(theme.palette.text.primary, 0.125),
    fontSize: '0.5rem',
    fontWeight: 600,
    zIndex: 1000,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Application title={'Ospitek View'} Loading={Initialization}>
      <Switch>
        <Route path="/schedule" component={ExternalSchedule} />
        <Route exact path="/kiosk" render={() => <Redirect to="/kiosk/1" />} />
        <Route path="/kiosk/:organizationId" component={Kiosk} />
        <Route path="/inject/:organizationId" component={Inject} />
        <Route path="/print/:organizationId" component={Print} />
        <Route path="/su/:organizationId" component={SuperAdminOrganization} />
        <Route component={User} />
      </Switch>
      {config.appVersion && <Box className={classes.applicationVersion}>{config.appVersion.substring(0, 8)}</Box>}
    </Application>
  );
};
