import { alpha, Box, Theme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface TickProps {
  children: string;
}

const Tick = ({ children }: TickProps) => {
  const classes = useStyles();

  return <Box className={classes.tick}>{children}</Box>;
};

const useStyles = makeStyles((theme: Theme) => ({
  tick: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    paddingLeft: '1em',
    color: theme.palette.text.primary,
    opacity: 0.5,
    margin: '0 0.25em',

    '&:after': {
      borderTop: `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
      opacity: 0.9,
      right: 0,
      content: '""',
      left: '4em',
      position: 'absolute',
      top: '0.4rem',
      flex: 1,

      [theme.breakpoints.down('md')]: {
        left: '2.5em',
      },
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: '0.5em',
    },
  },
}));

export default Tick;
