import styled from 'styled-components';
import { differenceInDays } from 'date-fns';

export const hasUserCancelledPWA = PWAInfo =>
  PWAInfo.canceledAt && differenceInDays(new Date(), new Date(PWAInfo.canceledAt)) < 3 + PWAInfo.cancelCount * 3;

export const isInStandaloneMode = () =>
  (window && window.navigator && window.navigator.standalone) ||
  (window && window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
  (window && window.navigator && window.navigator.standalone);

export const isIPhone = () => /iPhone/i.test(navigator.userAgent);

export const isIPad = () => /iPad/i.test(navigator.userAgent);

export const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isSafari = () => !!navigator.userAgent.match(/Version\/[\d]+.*Safari/);

export const isAndroidDevice = () => /Android/i.test(navigator.userAgent);

export const isApplicableMobileDevice = () => isIOS() || isAndroidDevice();

export const Box = styled.div`
  background-color: ${p => p.theme.button.default.backgroundColor.string()};
  padding: 0.75em 1.25em;
`;

export const AppIcon = styled.img`
  border: 2px solid #000c3f;
  border-radius: 12px;
  box-sizing: content-box;
  height: 32px;
  padding: 1.25rem 0.5rem;
  box-shadow: #000c3f 0 0.1rem 0.75rem;
  background-color: #000c3f;
`;
