import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './components/Header';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import {
  age65AndOlderNo,
  age65AndOlderYes,
  toBooleanNegative,
  toBooleanPositive,
} from '../../../../../questionnaire/fallRiskReducers';
import FallRiskCheckbox from '../components/FallRiskCheckbox';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"]');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 0 };
    }, {})
  );
}

const Page11 = ({ value, defaultValue }) => {
  const [values1, setValues1] = useState({});
  const [values2, setValues2] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);
  const total = total1 + useMemo(() => Object.values(values2).reduce((acc, value) => acc + value, 0), [values2]);

  const root1Ref = useRef(undefined);
  const root2Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
    initializeValues(root2Ref, setValues2);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div id="page11" className="page" style={{fontFamily:'Arial', fontSize:'15px'}}>
      <Header pageNumber={12} pageCount={12} />
      <br></br>
      <div>
        <div ref={root1Ref}>
          <div style={styles.oneColumn}>Fall Risk Assessment</div>
          <div style={styles.threeNotEqualColumnsBorder2}>
            <div style={{ textAlign: 'center', padding: '0.3rem', borderRight: '1px solid black' }}>Risk Factor(s)</div>
            <div style={{ textAlign: 'center', padding: '0.3rem', borderRight: '1px solid black' }}>Scale</div>
            <div style={{ textAlign: 'center', padding: '0.3rem' }}>Score</div>
          </div>
          <div style={styles.threeNotEqualColumnsBorder2}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Have you fallen in the last 6 months?
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.historyOfFallsYes"
                label="Yes"
                value={25}
                path={['medicalConditions', 'muscularSkeletal', 'muscularSkeletal_q6']}
                reducer={toBooleanPositive}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
                color: value?.historyOfFallsYes ? 'red' : 'black',
              }}
            >
              25
            </div>
            <div style={{ borderRight: '1px solid black', padding: '2px' }}>
              <MedicalPassportCheckbox
                name="fallRiskAssessment.historyOfFallsNo"
                label="No"
                value={0}
                path={['medicalConditions', 'muscularSkeletal', 'muscularSkeletal_q6']}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '2px' }}>0</div>
          </div>
          <div style={styles.threeNotEqualColumnsBorder2}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Secondary Diagnosis {'('}2 or more medical diagnoses{')'}
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.secondaryYes"
                label="Yes"
                value={25}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              25
            </div>
            <div style={{ borderRight: '1px solid black', padding: '2px' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.secondaryNo"
                label="No"
                value={0}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '2px' }}>0</div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '20% 40% 20% 20%',
              border: '1px solid black',
              borderBottom: 0,
              lineHeight: '1.6em',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 4',
              }}
            >
              Ambulatory Aid
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              None/Nurse Assist/Wheelchair
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.ambulatory1Yes"
                label="Yes"
                value={0}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              0
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Crutches/Cane/Walker
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.ambulatory2Yes"
                label="Yes"
                value={15}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              15
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
              }}
            >
              Furniture- clutches ot ambulate
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.ambulatory3Yes"
                label="Yes"
                value={30}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '2px',
              }}
            >
              30
            </div>
          </div>
          <div style={styles.threeNotEqualColumnsBorder2}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 4',
              }}
            >
              IV or IV Access
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.IVYes"
                label="Yes"
                value={25}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              25
            </div>
            <div style={{ borderRight: '1px solid black', padding: '2px' }}>
              <FallRiskCheckbox
                name="fallRiskAssessment.IVNo"
                label="No"
                value={0}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '2px',
              }}
            >
              0
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '20% 40% 20% 20%',
              border: '1px solid black',
              borderBottom: 0,
              lineHeight: '1.6em',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 4',
              }}
            >
              Gait
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                padding: '2px',
              }}
            >
              Normal/Wheelchair
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.gait1Yes"
                label="Yes"
                value={0}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              0
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                padding: '2px',
              }}
            >
              Weak
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.gait2Yes"
                label="Yes"
                value={10}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              10
            </div>
            <div
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                padding: '2px',
              }}
            >
              Impaired
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.gait3Yes"
                label="Yes"
                value={20}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '2px',
              }}
            >
              20
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '20% 40% 20% 20%',
              border: '1px solid black',
              borderBottom: 0,
              lineHeight: '1.6em',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 4',
              }}
            >
              Mental Status
            </div>
            <div
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              Knows own limits
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.mental1Yes"
                label="Yes"
                value={0}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '2px',
              }}
            >
              0
            </div>
            <div
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                padding: '2px',
              }}
            >
              Overestimates or forgets limits
            </div>
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallRiskAssessment.mental2Yes"
                label="Yes"
                value={15}
                reducer={toBooleanNegative}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '2px',
              }}
            >
              15
            </div>
          </div>
        </div>
        <div style={styles.twoNotEqualColumns4}>
          <div style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid black' }}>Total Score:</div>
          <div style={{ textAlign: 'center', padding: '5px' }}>
            <MedicalPassportField name="fallRiskAssessment.totalScore" value={total1} />
          </div>
        </div>
        <div style={styles.threeNotEqualColumnsBorder2}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
            }}
          >
            Add DOS if applicable: Peripheral Nerve Block Lower Extremeity
          </div>
          <div
            style={{
              padding: '2px',
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.dosYes"
              label="Yes"
              value={20}
              reducer={toBooleanNegative}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              padding: '2px',
            }}
          >
            20
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              padding: '2px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.dosNo"
              label="No"
              value={0}
              reducer={toBooleanNegative}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              padding: '2px',
            }}
          >
            0
          </div>
        </div>
        <div style={styles.twoNotEqualColumns4}>
          <div style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid black' }}>New Total Score:</div>
          <div style={{ textAlign: 'center', padding: '5px' }}>
            <MedicalPassportField name="fallRiskAssessment.totalScore2" value={total} />
          </div>
        </div>
      </div>
      <br />
      <div>To obtain the Morse Fall Score, add the score from each category.</div>
      <br />
      <table style={{ fontSize: '.80rem', borderCollapse: 'collapse', width: '30%' }}>
        <tr>
          <td colSpan={2} style={{ border: '1px solid black', padding: '2px' }}>
            <b>Morse Fall Risk</b>
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '2px' }}>High Risk</td>
          <td style={{ border: '1px solid black', padding: '2px' }}>45 and higher</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '2px' }}>Moderate Risk</td>
          <td style={{ border: '1px solid black', padding: '2px' }}>25-44</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid black', padding: '2px' }}>Low Risk</td>
          <td style={{ border: '1px solid black', padding: '2px' }}>0-24</td>
        </tr>
      </table>
      <div style={styles.signatureBox1}>
        <div style={{ paddingTop: '1.5rem' }}>Pre-Op Nurse Signature: __________________________________</div>
        <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
      </div>
      <div style={styles.signatureBox1}>
        <div style={{ paddingTop: '1.5rem' }}>Post-Op Nurse Signature: _________________________________</div>
        <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div style={{ textAlign: 'center' }}>
        All patients at Polaris Surgery Center are considered high risk for Falls.
      </div>
    </div>
  );
};

export default Page11;
