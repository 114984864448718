import React, { useMemo,useEffect, useState, useRef  } from 'react';
import createReducer from '../../../../../../questionnaire/reducer';
import { format } from 'date-fns';
import styles from '../../styles';
import { useValue } from '../../ValueContext';
import createSelector from '../../../../../../questionnaire/selector';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toStringWithoutQuestions from '../../../../../../questionnaire/toStringWithoutQuestions';
import { capitalize } from 'lodash';
import getAgeAndBmi from './pageUtils';
import Barcode from '../../../Barcode';

const medicalHistoryReducer = createReducer({
  Sections: sections => null,
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0 ? null : visibleFields.length === 1 ? (
      visibleFields[0].child
    ) : (
      <ul>
        {visibleFields.map((field, i) => (
          <li key={i}>{field.child}</li>
        ))}
      </ul>
    );
  },
  Text: (label, text) =>
    text ? (
      <p>
        {label}: {text}
      </p>
    ) : null,
  LongText: (label, text) =>
    text ? (
      <p>
        {label}: {text}
      </p>
    ) : null,
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);
    return visibleCheckboxes.length === 0 ? null : visibleCheckboxes.length === 1 ? (
      <div>
        <div>{visibleCheckboxes[0].label}</div>
        {visibleCheckboxes[0].child && <div>{visibleCheckboxes[0].child}</div>}
      </div>
    ) : (
      <ul style={{ listStyleType: 'circle' }}>
        {visibleCheckboxes.map((checkbox, i) => (
          <li key={i}>
            <div>{checkbox.label}</div>
            {checkbox.child && <div>{checkbox.child}</div>}
          </li>
        ))}
      </ul>
    );
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);
    return visibleChildren.length === 0 ? null : visibleChildren.length === 1 ? (
      visibleChildren[0]
    ) : (
      <ul>
        {visibleChildren.map((child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    );
  },
  YesNo: (question, positive, child) => {
    const transformedQuestion = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, '')
        .replace(/^Are you\s/g, '')
    );
    return positive ? (
      <div>
        <div>{transformedQuestion}</div>
        {child ? <div>{child}</div> : null}
      </div>
    ) : null;
  },
  OneOf: (label, options, answer) =>
    answer ? (
      <div>
        <div>
          {label}: {answer.label}
        </div>
        {answer.child && <div>{answer.child}</div>}
      </div>
    ) : null,
  Medication: value => (value ? <span>{value}</span> : null),
  BodyMassIndex: (heightFt, heightIn, weightLb) => null,
  DateOfBirth: date => (date ? <span>{format(date, 'MM/DD/YYYY')}</span> : null),
  Date: date => (date ? <span>{format(date, 'MM/DD/YYYY')}</span> : null),
});

const ClinicalItem = ({ title, content }) =>
  content && (
    <>
      <div style={{ fontSize: '16px' }}>
        <div style={{ padding: '0' }}><b>{title}</b></div>
        <div>{content}</div>
      </div>
    </>
  );

export const getSelectorContent = selectorValue => (selectorValue && medicalHistoryReducer(...selectorValue)) || null;

const ClinicalList = ({ questionnaire, questionnaireAnswers }) => {
  const {
    cardiac,
    pulmonary,
    renal,
    hepatic,
    neurologic,
    gi,
    vascular,
    endocrine,
    musculoskeletal,
    psychiatric,
    oncologic,
    infectious,
    pain,
  } = useMemo(
    () => ({
      cardiac: getSelectorContent(createSelector(['Cardiac'])(questionnaire, questionnaireAnswers)),
      pulmonary: getSelectorContent(createSelector(['Pulmonary'])(questionnaire, questionnaireAnswers)),
      renal: getSelectorContent(createSelector(['Renal'])(questionnaire, questionnaireAnswers)),
      hepatic: getSelectorContent(createSelector(['Hepatic'])(questionnaire, questionnaireAnswers)),
      neurologic: getSelectorContent(createSelector(['Neurologic'])(questionnaire, questionnaireAnswers)),
      gi: getSelectorContent(createSelector(['GI'])(questionnaire, questionnaireAnswers)),
      vascular: getSelectorContent(createSelector(['Vascular'])(questionnaire, questionnaireAnswers)),
      endocrine: getSelectorContent(createSelector(['Endocrine'])(questionnaire, questionnaireAnswers)),
      musculoskeletal: getSelectorContent(createSelector(['Musculoskeletal'])(questionnaire, questionnaireAnswers)),
      psychiatric: getSelectorContent(createSelector(['Psychiatric'])(questionnaire, questionnaireAnswers)),
      oncologic: getSelectorContent(createSelector(['Oncologic'])(questionnaire, questionnaireAnswers)),
      infectious: getSelectorContent(createSelector(['Infectious'])(questionnaire, questionnaireAnswers)),
      pain: getSelectorContent(createSelector(['Pain'])(questionnaire, questionnaireAnswers)),
    }),
    [questionnaire, questionnaireAnswers]
  );

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '33% 33% 33%',
          gridGap: '10px',
          alignContent: 'start',
        }}
      >
        <div style={{ borderRight: '0.5px solid lightgray' }}>
          <ClinicalItem title={'Cardiac (Heart)'} content={cardiac} />
          <ClinicalItem title={'Pulmonary (Lung)'} content={pulmonary} />
          <ClinicalItem title={'Renal (Kidney)'} content={renal} />
          <ClinicalItem title={'Hepatic (Liver)'} content={hepatic} />
          <ClinicalItem title={'Neurologic (Nervous System)'} content={neurologic} />
          <ClinicalItem title={'GI (Stomach and Intestines)'} content={gi} />
        </div>
        <div style={{ borderRight: '0.5px solid lightgray' }}>
          <ClinicalItem title={'Vascular diseases'} content={vascular} />
          <ClinicalItem title={'Endocrine (Glands)'} content={endocrine} />
          <ClinicalItem title={'Musculoskeletal (Muscles/Bones)'} content={musculoskeletal} />
          <ClinicalItem title={'Psychiatric/Social'} content={psychiatric} />
          <ClinicalItem title={'Oncologic (Cancer)'} content={oncologic} />
          <ClinicalItem title={'Infectious Diseases'} content={infectious} />
          <ClinicalItem title={'Pain'} content={pain} />
        </div>
        <div style={{ borderRight: '0.5px solid lightgray' }}>
          <div style={{ ...styles.div, fontSize: '16px' }}>
            <div style={{ fontWeight: 'bold' }}>PERSONAL INFORMATION:</div>
            <MedicalPassportField
              label="Primary Language"
              name="demographicInfoForm.primaryLanguage"
              path={['demographicInformation', 'primary language']}
              reducer={toStringWithoutQuestions}
              fontSize={16}
            />
            <MedicalPassportCheckbox
              label="Okay to leave a message on machine."
              name="demographicInfoForm.okay.value"
              path={['demographicInformation', 'okay']}
            />
            <div>If not, where can we leave a message:</div>
            <MedicalPassportField
              name="demographicInfoForm.okay.message"
              path={['demographicInformation', 'okay', false]}
              reducer={toStringWithoutQuestions}
              fontSize={16}
            />
            <div style={{ fontWeight: 'bold' }}>RESPONSIBLE PARTY:</div>
            <MedicalPassportField
              label="Name"
              name="contactInfoForm.responsibleParty.name"
              path={['contactinformation', 'responsibleparty', true, 'name']}
              fontSize={16}
            />
            <MedicalPassportField
              label="Mobile Phone"
              name="contactInfoForm.responsibleParty.mobilePhone"
              path={['contactinformation', 'responsibleparty', true, 'Mobile Phone']}
              fontSize={16}
            />
            <div style={{ fontWeight: 'bold' }}>RIDE HOME INFORMATION:</div>
            <MedicalPassportCheckbox
              name="page1.sameAsResParty"
              label="Same as responsible party"
              style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}
            />
            <MedicalPassportField
              label="Name"
              name="contactInfoForm.rideHomeInformation.name"
              path={['contactinformation', 'ridehomeinformation', true, 'name']}
              fontSize={16}
            />
            <MedicalPassportField
              label="Phone"
              name="contactInfoForm.rideHomeInformation.phone"
              path={['contactinformation', 'ridehomeinformation', true, 'phone']}
              fontSize={16}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Page1 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaire, questionnaireAnswers } = useValue();

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  const pageRef = useRef(null);
  const [pageStyle, setPageStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (pageRef.current) {
        const pageHeight = pageRef.current.offsetHeight;
        if (pageHeight > 1079) {
          setPageStyle({ minHeight: 2114}); 
        } else {
          setPageStyle({});
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="page1" className="page" style={{ fontFamily: 'Arial',...pageStyle }}>
      <div ref={pageRef}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          gridGap: '5px',
          alignContent: 'start',
          marginBottom: '10px',
        }}
      >
        <div style={styles.div}>
          <h3>{(hospitalName || '').toUpperCase()}</h3>
          <MedicalPassportTextArea name="procedure.name" label="Procedure" style={{ display: 'flex' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
          <div
            style={{
              border: '1px solid black',
              padding: '10px',
              lineHeight: '18px',
              fontSize: '12px'
            }}
          >
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40% 60%',
                alignContent: 'start',
              }}
            >
              <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
              <MedicalPassportField
                name="age"
                label="Age"
                path={['demographicInformation', 'dob']}
                reducer={toAge}
                warning={age > 70}
                fontSize={12} />
              <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} />
              <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex}
                fontSize={12} />
            </div>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon"
              fontSize={12} />
            <MedicalPassportField
              name="procedure.pcp"
              path={['demographicInformation', 'primaryCareDoctor']}
              label="PCP"

              fontSize={12} />
          </div>
        </div>
      </div>
      {lastPreOpCompletedEvent && (
        <div style={styles.div}>
          Pre-Op Performed By {lastPreOpCompletedEvent?.user?.name || ''}, Date{' '}
          {lastPreOpCompletedEvent?.timestamp ? format(lastPreOpCompletedEvent.timestamp, 'MM/DD/YYYY HH:mm') : ''}
        </div>
      )}
      <div>
        <div
          style={{
            gridColumn: '1 / span 2',
            height: '1px',
            background: 'black',
          }}
        />
        <div>
          <div>
            <div style={{ fontWeight: 'bold' }}>CLINICALLY SIGNIFICANT ITEMS:</div>
            <ClinicalList questionnaire={questionnaire} questionnaireAnswers={questionnaireAnswers} />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Page1;
