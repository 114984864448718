import React, { Fragment } from 'react';
import { ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import {
  Column,
  Label,
  Row,
  SubtleValue,
  TwoItemSection,
  Value,
} from '../../../se/components/collection/mobile/shared';
import RouterLink from '../../../se/components/RouterLink';
import ListSeparator from '../../ListSeparator';

const GatewayMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  highlighted,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return <ListSeparator title={item.label} />;
  }

  const nameColumn = columns.find(_ => _.title === 'ID');
  const lastSeen = columns.find(_ => _.title === 'GW Last Seen');
  const room = columns.find(_ => _.title === 'Room');
  const locationInRoom = columns.find(_ => _.title === 'Location in Room');
  const strengthAverage = columns.find(_ => _.title === 'Strength / Average');
  const threshold = columns.find(_ => _.title === 'Threshold');

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
        highlighted={highlighted}
      >
        <Row style={{ marginBottom: '1.5rem' }}>
          <nameColumn.Component data={nameColumn.lens(item, context)} />
          <SubtleValue>
            <lastSeen.Component data={lastSeen.lens(item, context)} />
          </SubtleValue>
        </Row>
        <TwoItemSection style={{ marginBottom: '1rem' }}>
          <Column>
            <Label>{room.title}</Label>
            <Value>
              <room.Component data={room.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '1rem' }}>
          <Column>
            <Label>{locationInRoom.title}</Label>
            <Value>
              <locationInRoom.Component data={locationInRoom.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection>
          <Column>
            <Label>{strengthAverage.title}</Label>
            <Value>
              <strengthAverage.Component data={strengthAverage.lens(item, context)} />
            </Value>
          </Column>
          <Column>
            <Label>{threshold.title}</Label>
            <Value>
              <threshold.Component data={threshold.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default GatewayMobileListItem;
