import React from 'react';
import { alpha, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const ScheduleRoomsHeaderCell = ({ rooms }: { rooms: any[] }) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      {rooms.map((room, i) => (
        <Box className={classes.orNameWrapper} key={`${room.id}-${i}`}>
          <Typography
            color="textSecondary"
            variant={rooms.length < 10 ? 'body1' : 'caption'}
            className={classes.orName}
          >
            {room.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  orNameWrapper: {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    flexFlow: 'column',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  orName: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRight: alpha(theme.palette.text.primary, 0.08),
    lineHeight: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default ScheduleRoomsHeaderCell;
