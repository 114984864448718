import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

interface RootProps {
  hasMoreThan6ORsView: boolean;
  hasMoreThan6ORs: boolean;
  isKiosk?: boolean;
  children: ReactNode;
}

const Root = ({ hasMoreThan6ORsView, hasMoreThan6ORs, isKiosk, children }: RootProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, {
        [classes.overflowX]: hasMoreThan6ORs,
        [classes.hasMoreThan6ORsView]: hasMoreThan6ORsView,
        [classes.hasMoreThan6ORsViewAndIsKiosk]: hasMoreThan6ORsView && isKiosk,
        [classes.rootIsKiosk]: isKiosk,
      })}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: '1em',
    fontFamily: 'Roboto Condensed, sans-serif',
  },
  overflowX: {
    overflowX: 'auto',
  },
  hasMoreThan6ORsView: {
    overflow: 'auto',
    flex: '0 1 auto',
    height: 'auto',
  },
  hasMoreThan6ORsViewAndIsKiosk: {
    overflow: 'hidden',
    flex: '1 0 0',
  },
  rootIsKiosk: {
    flex: '1 0 0',
  },
}));

export default Root;
