import { Accordion, Typography } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import update from 'lodash/fp/update';
import omit from 'lodash/omit';
import React from 'react';
import { Link } from 'react-router-dom';
import scheme from '../../../graph/hospitals';
import EntityView from '../../../se/components/entity/EntityView';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import { withSession } from '../../../state/Session';
import HospitalInput from '../../inputs/hospitals/HospitalInput';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { allColumns, baseColumns } from './columns';
import HospitalDocumentUploader from './HospitalDocumentUploader';
import HospitalMobileListItem from './HospitalMobileListItem';

const Empty = { illustration: theme => theme.illustrations.rooms };

export const List = {
  columns: [...baseColumns],
  tableKey: 'Hospitals',
  Empty,
  MobileItemComponent: HospitalMobileListItem,
};

const updateLinkedUsers = flow(update('monitors', map(get('id'))), update('groupAdmins', map(get('id'))));

const updateTimezone = update('timezone', timezone => (timezone && timezone.id ? timezone.id : timezone));

const setDefaultSessionTimeout = update('sessionTimeout', sessionTimeout => sessionTimeout || 10 * 60);

export const Create = {
  Input: HospitalInput,
  Empty,
  prepareCreateData: flow(updateLinkedUsers, setDefaultSessionTimeout, updateTimezone),
};

export const ShowActions = ({ data = {} }) => (
  <Button variant="contained" component={Link} to={`/su/${data.id}`}>
    Manage
  </Button>
);

const ShowView = props => {
  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box flex={1} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h2">General info</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <EntityView {...props} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="h2">Uploads</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HospitalDocumentUploader organizationId={props.data.id} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const Show = {
  columns: allColumns,
  View: ShowView,
  fetchPolicy: 'network-only',
  Empty,
  Actions: ShowActions,
};

export const Edit = {
  Input: HospitalInput,
  Empty,
  prepareUpdateData: flow(updateLinkedUsers, setDefaultSessionTimeout, updateTimezone),
};

const CRUD = GraphQLCRUD({
  entityName: 'Hospital',
  scheme,
  List,
  Create,
  Show,
  Edit,
});

const RU = GraphQLCRUD({
  entityName: 'Hospital',
  scheme: omit(scheme, 'remove'),
  List,
  Show,
});

const Hospitals = ({ isGroupAdmin }) => (
  <React.Fragment>
    <PWAInstallBanner />
    {isGroupAdmin ? <RU /> : <CRUD />}
  </React.Fragment>
);

export default withSession(unpackSessionObject)(Hospitals);
