import React from 'react';
import { Box, Drawer, Icon, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface ScheduleMobileFooterProps {
  children: React.ReactNode;
}

const ScheduleMobileFooter = ({ children }: ScheduleMobileFooterProps) => {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  return (
    <>
      <Box className={classes.toggleButton} onClick={toggleDrawer(true)}>
        <Icon color="primary">person</Icon>
        <Box flex={1}>
          <Typography>Tap to view and edit staff schedule.</Typography>
        </Box>
        <Icon>keyboard_arrow_up</Icon>
      </Box>{' '}
      <Drawer anchor="bottom" open={state} onClose={toggleDrawer(false)}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </Drawer>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  toggleButton: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export default ScheduleMobileFooter;
