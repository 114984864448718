import { useMutation } from '@apollo/client';
import { ZonedDateTime } from '@js-joda/core';
import { Box, Button, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { setPatientEventTimestamp } from '../../../../graph/procedureSteps';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import { withLabel } from '../../../../se/components/Label';
import { RouterlessModal } from '../../../../se/components/Modal';
import { CancelableForm, DateInput, formatTime } from '../../../inputs/timeEdit/TimeEditForm';
import { makeStyles } from '@material-ui/core/styles';

export const convertToDateTime = (timestamp, time) => {
  const dateTime = new Date(timestamp);
  const [hours, minutes] = time.split(':').map(e => parseInt(e, 10));
  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  return dateTime;
};

const getEventType = event => {
  switch (event?.type) {
    case 'Created':
    case 'Discharged':
    case 'BlockNerve':
    case 'CleaningCompleted':
      return { __type: `${event?.type}$` };
    case 'Entered':
      return { __type: 'Entered', roomId: event?.room?.id, manualEntryBy: null, manualTimestamp: null };
    case 'Exited':
      return { __type: 'Exited', roomId: event?.room?.id, manualEntryBy: null };
    case 'ProcedureStatusSet':
      return {
        __type: 'ProcedureStatusSet',
        status: { [event?.status]: {} },
        manualEntryBy: null,
        manualTimestamp: null,
      };
    default:
      return null;
  }
};

export const FormInput = eventText =>
  withProps({
    schema: {
      time: compose(
        withLabel(eventText),
        withProps({
          autoFocus: true,
        })
      )(DateInput),
    },
  })(ObjectInput);

const PatientEventEditButton = ({ patientId, event, eventText }) => {
  const [edit, setEdit] = useState(false);
  const [save] = useMutation(setPatientEventTimestamp);
  const classes = useStyles();

  const toggleEdit = () => {
    setEdit(prev => !prev);
  };

  const saveTimestamp = async time => {
    const eventType = getEventType(event);
    const newDateTime = convertToDateTime(event?.timestamp, time);
    newDateTime.toISOString() !== new Date(event?.timestamp).toISOString() &&
      patientId &&
      eventType &&
      (await save({
        variables: {
          patientId,
          eventType: JSON.stringify(eventType),
          timestamp: newDateTime,
        },
      }));
    toggleEdit();
  };

  const onSubmit = async val => {
    await saveTimestamp(val?.time);
  };

  return (
    <>
      <Box>
        <IconButton color="primary" onClick={toggleEdit} className={classes.mobileButton}>
          <EditIcon />
        </IconButton>
      </Box>
      <Button
        variant="outlined"
        size="small"
        onClick={toggleEdit}
        startIcon={<EditIcon />}
        className={classes.desktopButton}
      >
        Edit
      </Button>
      {edit && (
        <RouterlessModal title="Update Time" onClose={toggleEdit}>
          <CancelableForm
            autoFocus
            label="Update"
            onClose={toggleEdit}
            initialValue={{
              time: formatTime(ZonedDateTime.parse(event?.timestamp).toLocalDateTime().toString()),
            }}
            input={FormInput(eventText)}
            onSubmit={onSubmit}
            busy={false}
          />
        </RouterlessModal>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  mobileButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default PatientEventEditButton;
