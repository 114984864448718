import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Dot = () => {
  const classes = useStyles();

  return <Box className={classes.dot} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  dot: {
    borderRadius: '50%',
    width: '0.5em',
    height: '0.5em',
    marginTop: '-0.25em',
    marginLeft: 'calc(0.25em + 1px)',
    backgroundColor: 'rgba(232, 29, 29, 0.8)',
  },
}));

export default Dot;
