import React, { useEffect, useState } from 'react';
import { RouterlessModal } from '../../../se/components/Modal';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { breakpoint } from '../../../se/utilities/responsive';
import ReactPlayer from 'react-player';
import config from '../../../config';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { PlayCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import EntityLoadingState from '../../../se/components/entity-states/EntityLoadingState';
import { Alert } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.main,
  },
}));

const playerConfig = {
  file: {
    attributes: {
      controlsList: 'nodownload',
      disablePictureInPicture: true,
    },
  },
};

const Training = () => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [error, setError] = useState();
  const [item, setItem] = useState();
  const isMobile = window.innerWidth < breakpoint.md;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(config.assetsURL, {
          method: 'GET',
          cache: 'no-store',
        });

        if (response && response.status === 200) {
          const data = await response.json();
          const training = get(data, 'training', []).sort((a, b) => a.category.localeCompare(b.category));
          const grouped = groupBy(training, 'category');
          setData(grouped);
        }
      } catch (e) {
        console.error(e);
        setError(
          'There was an error loading training videos, please try again.\nContact our customer support if the error persists.'
        );
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!data) {
    return <EntityLoadingState />;
  }

  return (
    <Box mt={4}>
      <Typography variant="h2">Video Tutorials</Typography>
      {item && (
        <RouterlessModal width="50rem" onClose={() => setItem(null)} fullWindow={isMobile}>
          <ReactPlayer controls={true} url={item} playing config={playerConfig} width={'100%'} height={'auto'} />
        </RouterlessModal>
      )}
      {Object.keys(data).map((category, index) => (
        <Box my={4} key={index}>
          <Typography>{category}</Typography>
          <List>
            {data[category].map((tutorial, index) => (
              <ListItem button onClick={() => setItem(tutorial.url)} key={index}>
                <ListItemIcon>
                  <PlayCircleOutline />
                </ListItemIcon>
                <ListItemText className={classes.listItem} primary={tutorial.title} />
              </ListItem>
            ))}
          </List>
          {Object.keys(data).length !== index + 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};

export default Training;
