import styled from 'styled-components';
import { DataField } from '../../../../se/components/entity/EntityView';
import responsive from '../../../../se/utilities/responsive';
import { withTheme } from '../../../../se/theme';

export const EventLogItem = styled.div`
  border-top: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  border-right: 0;
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.4;

  &:last-of-type {
    border-bottom: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  }

  ${responsive.md.andSmaller`
    flex-flow: column-reverse;
    align-items: flex-start;

    > div:first-of-type {
      margin-top: .25rem;
    }
  `}
`;

export const EventLogTime1 = styled.div`
  opacity: 0.5;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EventLogEntry = styled.div`
  border-radius: 0.25rem;
  display: flex;
  flex-flow: row;
  flex: 2;
  align-items: flex-start;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;

  > div {
    display: inline-block;
  }

  @media (max-width: 1200px) {
    flex: 1;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 0.25em;
  opacity: 0.3;

  > i {
    vertical-align: middle;
  }
`;

export const Panel = styled.div`
  display: flex;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 0.25rem;
  margin-top: 1rem;

  ${responsive.md.andSmaller`
    flex-flow: column;
  `};
`;

export const DataFieldCustom = styled(DataField)`
  border: none;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusIndicator = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.5rem;
  background-color: ${props => (props.isAssociated ? '#7ed321' : 'gray')};
  margin-right: 0.5rem;
`;

export const ValueAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
