import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';
import CloseIcon from '@material-ui/icons/Close';
import { Destination } from '../../../../../types/NotificationTemplate';
import ScheduleUser from '../../../../../types/ScheduleUser';
import Entity from '../../../../../types/Entity';
import ScheduleAccess from '../ScheduleAccess';
import { Alert } from '@material-ui/lab';
import SendNotificationDialogTitle from '../scheduleNotificationDialog/DialotTitle';

export type Contact = {
  id: number;
  phoneNumber: string;
};

export type Recipient = Entity & ScheduleUser & Contact;

type RecipientCheckboxes = { [recipientId: string]: boolean };

const SendNotificationDialog: FC<{
  date: Date;
  open: boolean;
  handleClose: () => void;
  recipients: Recipient[];
  sendNotifications: (recipients: Contact[], message: String) => Promise<void>;
  destination: Destination;
}> = ({ date, open, handleClose, recipients, sendNotifications, destination }) => {
  const { data: notification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ScheduleCreated', destination: destination },
  });
  const theme = useTheme();

  const notificationText = notification?.getNotificationTemplate[0]?.content || '';
  const [message, setMessage] = useState<string>('');

  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [openToast, setOpenToast] = React.useState(sent);

  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  useEffect(() => {
    setOpenToast(sent);
  }, [sent]);

  useEffect(() => {
    setMessage(notificationText);
  }, [notificationText]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  const classes = useStyles();

  useEffect(() => {
    setSent(false);
  }, [open]);

  const [checkboxes, setCheckboxes] = useState<RecipientCheckboxes>({});

  const selectAllCheckboxes = useCallback(() => {
    setCheckboxes(
      recipients.reduce(
        (acc: any, curr: Recipient) => ({
          ...acc,
          [curr.id]: curr.hasScheduleAccess && !!curr.phoneNumber,
        }),
        {}
      )
    );
  }, [setCheckboxes, recipients]);

  useEffect(() => {
    selectAllCheckboxes();
  }, [recipients]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const doSend = async () => {
    setSending(true);
    setSent(false);

    await sendNotifications(
      recipients
        .filter(e => checkboxes[e.id] && e.phoneNumber)
        .map(e => ({
          id: e.id,
          phoneNumber: e.phoneNumber,
        })),
      message
    );

    setSending(false);
    setSent(true);
  };

  const selectAll = () => selectAllCheckboxes();

  const deselectAll = () => setCheckboxes({});

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <DialogTitle>
        <Typography variant="h6">
          Send notification of the new schedule to the{' '}
          {`${destination
            .split(/(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join(' ')}${recipients.length > 1 ? 's' : ''}`}
        </Typography>
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={7}>
            <Box display="flex" style={{ gap: theme.spacing(1) }}>
              <Button color="primary" variant="text" onClick={selectAll} style={{ width: 'fit-content' }}>
                Select all
              </Button>
              <Button color="primary" variant="text" onClick={deselectAll} style={{ width: 'fit-content' }}>
                Deselect all
              </Button>
            </Box>
            <TableContainer style={{ width: '100%' }}>
              <Table aria-labelledby="selectRecipientTable" aria-label="table of recipients">
                <TableHead>
                  <TableCell></TableCell>
                  <TableCell>Select Anesthesiologist</TableCell>
                  <TableCell>Current Access</TableCell>
                  <TableCell>Change Access</TableCell>
                </TableHead>
                <TableBody>
                  {recipients.map((e: Recipient) => (
                    <TableRow key={e.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={(checkboxes as any)[e.id] || false}
                          onChange={handleChange}
                          name={`${e.id}`}
                          disabled={!(e.hasScheduleAccess && e.phoneNumber)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{e.name}</Typography>
                        <Typography>{e.phoneNumber || '(No Phone Number)'}</Typography>
                      </TableCell>
                      <ScheduleAccess date={date} scheduleUser={e} destination={destination} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Typography gutterBottom>
              Make sure to enter Anesthesiologist Members with their phone numbers, in order for them to receive
              messages&#40;add in anesthesiologist member profile&#41;
            </Typography>
            <TextField
              label="Send message with schedule link"
              multiline
              minRows={10}
              variant="filled"
              fullWidth={true}
              value={message}
              onChange={handleMessageChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert severity="success" variant="filled" onClose={handleCloseToast}>
            Notifications sent
          </Alert>
        </Snackbar>
        <Button variant="contained" color="primary" onClick={doSend} disabled={sending}>
          Send Notifications
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default SendNotificationDialog;
