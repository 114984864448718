import React, { Fragment } from 'react';
import EntityView from '../../../../se/components/entity/EntityView';
import PatientLocationWidget from './PatientLocationWidget';
import get from 'lodash/get';
import PatientEvents from '../patientJourney/PatientEvents';

const LocationPatientView = props => {
  const events = props?.data?.log || [];

  return (
    <Fragment>
      <EntityView {...props} />
      <PatientLocationWidget
        id={get(props, 'data.id')}
        name={get(props, 'data.name')}
        room={get(props, 'data.room')}
        discharged={!!get(props, 'data.dischargedAt')}
        events={get(props, 'data.events')}
      />
      {events.length !== 0 ? <PatientEvents events={events} debug={false} patientId={props?.data?.id} /> : null}
    </Fragment>
  );
};

export default LocationPatientView;
