import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const Rooms = ({
  columns,
  hasMoreThan6ORs,
  hasMoreThan6ORsView,
  children,
}: {
  columns: number;
  hasMoreThan6ORs: boolean;
  hasMoreThan6ORsView: boolean;
  children: any;
}) => {
  const classes = useStyles(columns);

  return (
    <Box
      className={clsx(classes.rooms, {
        [classes.NotHasMoreThan6ORsViewAndHasMoreThan6ORs]: hasMoreThan6ORs && !hasMoreThan6ORsView,
      })}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  rooms: {
    display: 'grid',
    gridTemplateColumns: columns => `repeat(${columns}, 1fr)`,
    marginLeft: '4em',
    [theme.breakpoints.down('md')]: {
      marginLeft: '2.5em',
    },
  },
  NotHasMoreThan6ORsViewAndHasMoreThan6ORs: {
    width: 'calc(100% + 1.6 * 100%)',
  },
}));

export default Rooms;
