import React from 'react';
import { withProps } from 'recompose';
import styled from 'styled-components';
import { Paragraph, TruncatedText } from '../../../se/components/typography';
import AddressLink from '../../../se/components/html/AddressLink';
import PhoneLink from '../../../se/components/html/PhoneLink';
import get from 'lodash/get';
import { LinkColumn } from '../hospitals/columns';
import CovidScreeningToggle from '../covidScreening/CovidScreeningToggle';
import AddMultipleLocations from '../locations/AddMultipleLocations';

const Empty = withProps({
  children: 'Empty',
})(styled.span`
  color: ${props => props.theme.textColor.string()};
  opacity: 0.25;
  font-style: italic;
`);

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => item.name,
  Component: props => props.data,
};

export const baseColumns = [
  nameColumn,
  {
    title: 'Address',
    key: 'address',
    lens: item => item.address,
    Component: props => (props.data ? <AddressLink>{props.data}</AddressLink> : '-'),
  },
  {
    title: 'Contact',
    key: 'contact',
    lens: item => item.contact,
    Component: props => (props.data ? <PhoneLink>{props.data}</PhoneLink> : '-'),
  },
];

export const allColumns = [
  ...baseColumns,
  {
    title: 'Session Expiration Timeout',
    lens: item => item.sessionTimeout,
    Component: props => (
      <Paragraph style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{props.data ? props.data : <Empty />}</Paragraph>
    ),
  },
  {
    title: 'Notes',
    lens: item => item.notes,
    span: 2,
    Component: props => (
      <Paragraph style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{props.data ? props.data : <Empty />}</Paragraph>
    ),
  },
  {
    title: 'Google Review Integration',
    lens: item => get(item, 'reviewIntegrations.google', {}),
    Component: LinkColumn,
  },
  {
    title: 'Yelp! Review Integration',
    span: 1,
    lens: item => get(item, 'reviewIntegrations.yelp', {}),
    Component: LinkColumn,
  },
  {
    title: 'Timezone',
    span: 1,
    lens: item => get(item, 'timezone.name', ''),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Document Exchange Only',
    span: 1,
    lens: item => get(item, 'exchangeOnly', false),
    Component: props => <TruncatedText>{props.data ? 'Yes' : 'No'}</TruncatedText>,
  },
  {
    title: 'Modules',
    lens: item => item,
    span: 2,
    Component: CovidScreeningToggle,
  },
  {
    title: 'Locations',
    lens: item => item,
    span: 3,
    Component: AddMultipleLocations,
  },
];
