import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import { Column, Label, Value, TwoItemSection } from '../../../se/components/collection/mobile/shared';
import RouterLink from '../../../se/components/RouterLink';

const PhysicianMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.key === 'name');
  const expectedPreOpDurationColumn = columns.find(_ => _.key === 'expectedPreOpDuration');
  const expectedDurationColumn = columns.find(_ => _.key === 'expectedDuration');
  const expectedPacuDurationColumn = columns.find(_ => _.key === 'expectedPacuDuration');

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <TwoItemSection style={{ marginBottom: '0.75rem' }}>
          <Column>
            <Label>{nameColumn.title}</Label>
            <Value trimText>{nameColumn.lens(item, context)}</Value>
          </Column>
          <Column>
            <Label>{expectedPreOpDurationColumn.title}</Label>
            <Value trimText>{expectedPreOpDurationColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>

        <TwoItemSection>
          <Column>
            <Label>{expectedDurationColumn.title}</Label>
            <Value trimText>{expectedDurationColumn.lens(item, context)}</Value>
          </Column>
          <Column>
            <Label>{expectedPacuDurationColumn.title}</Label>
            <Value trimText>{expectedDurationColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default PhysicianMobileListItem;
