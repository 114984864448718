import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import RouterLink from '../../../se/components/RouterLink';
import { Column, Label, Row, TwoItemSection, Value } from '../../../se/components/collection/mobile/shared';
import { format } from 'date-fns';

const ScheduleRequestMobileListItem = ({
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  const requestFromColumn = columns.find(_ => _.title === 'Request From');
  const patientNameColumn = columns.find(_ => _.title === 'Patient Name');
  const dateOfServiceColumn = columns.find(_ => _.title === 'Date of Service');
  const physicianColumn = columns.find(_ => _.title === 'Physician');
  const statusColumn = columns.find(_ => _.title === 'Status');

  return (
    <Fragment>
      <ListItemLink
        component={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <Row style={{ marginBottom: '0.75rem', alignItems: 'center' }}>
          <requestFromColumn.Component data={requestFromColumn.lens(item, context)} />
        </Row>

        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{dateOfServiceColumn.title}</Label>
            <Value>{format(dateOfServiceColumn.lens(item, context), 'MM/DD/YYYY')}</Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{patientNameColumn.title}</Label>
            <Value>
              <patientNameColumn.Component data={patientNameColumn.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{physicianColumn.title}</Label>
            <Value>
              <physicianColumn.Component data={physicianColumn.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection>
          <Column>
            <Label>{statusColumn.title}</Label>
            <Value>
              <statusColumn.Component data={statusColumn.lens(item, context)} />
            </Value>
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default ScheduleRequestMobileListItem;
