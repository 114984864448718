import { Subscription } from '@apollo/client/react/components';
import { withQuery } from '@apollo/client/react/hoc';
import { alpha, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { addDays, format, getDay, getDaysInMonth, getMonth, getYear, isWeekend, subDays } from 'date-fns';
import chunk from 'lodash/chunk';
import first from 'lodash/first';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { listProceduresFromToSubscription } from '../../../../../graph/procedures';
import rooms from '../../../../../graph/rooms';
import { ROOM_TYPES } from '../../../../entities/room/enums';
import ScheduleRoomsHeaderCell from '../ScheduleRoomsHeaderCell';
import { WeeklyGrid } from '../WeekView/Week';
import { padWeek } from '../WeekView/WeekSchedule';
import Day from './Day';

const StyledBox = styled(Box)`
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 2em 0;
  gap: 0.25em;
`;

const WeeklyHeaderGrid = styled('div')`
  display: flex;
  position: relative;
  overflow: hidden;
  gap: 0.5em;
  padding: 0 2em;
`;

const padWeeks = (days, showWeekends) => {
  const differenceBefore = getDay(first(days)) - (showWeekends ? 0 : 1);
  const differenceAfter = (showWeekends ? 6 : 5) - getDay(last(days));
  const padLeft = [...new Array(differenceBefore)].map((_, i) => subDays(first(days), differenceBefore - i));
  const padRight = [...new Array(differenceAfter)].map((_, i) => addDays(last(days), i + 1));
  return [...padLeft, ...days, ...padRight];
};

const thisMonthDays = (date, showWeekends) => {
  const month = getMonth(date);
  const year = getYear(date);

  return [...new Array(getDaysInMonth(date))]
    .map((_, i) => new Date(year, month, i + 1))
    .filter(_ => showWeekends || !isWeekend(_));
};

const MonthlyScheduleCalendar = ({
  data,
  date,
  weeks,
  rooms,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
}) => {
  const classes = useStyles();
  const weeklyProcedures = groupBy(get(data, 'proceduresFromTo', []), ({ date }) => `${date}`);
  if (!weeks || !weeks.length) return null;

  return (
    <StyledBox>
      <WeeklyHeaderGrid>
        {weeks[0].map(day => (
          <Box style={{ gap: '.25em', minWidth: 0, overflow: 'hidden' }} display="flex" flexDirection="column" flex={1}>
            <Box className={classes.day}>
              <Typography color="textSecondary" variant="caption">
                {format(day, 'dddd')}
              </Typography>
            </Box>
            <ScheduleRoomsHeaderCell rooms={rooms} />
          </Box>
        ))}
      </WeeklyHeaderGrid>
      {weeks.map(week => (
        <WeeklyGrid style={{ padding: '0 2em' }}>
          {week.map(day => (
            <Day
              day={day}
              date={date}
              rooms={rooms}
              procedures={weeklyProcedures?.[format(day, 'YYYY-MM-DD')] || []}
              setEditProcedureMonthly={setEditProcedureMonthly}
              openProcedureForm={openProcedureForm}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
            />
          ))}
        </WeeklyGrid>
      ))}
    </StyledBox>
  );
};

const useStyles = makeStyles(theme => ({
  day: {
    backgroundColor: alpha(theme.palette.text.primary, 0.08),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

const MonthlySchedule = ({
  data,
  date,
  physician,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  showWeekends,
}) => {
  const rooms = get(data, 'rooms', [])
    .filter(_ => _.type === ROOM_TYPES.OR)
    .sort((a, b) => a.name.localeCompare(b.name));

  const weeks = useMemo(
    () => chunk(padWeeks(thisMonthDays(date, showWeekends), showWeekends), showWeekends ? 7 : 5),
    [date, showWeekends]
  );

  const week = useMemo(() => padWeek(date, showWeekends), [date, showWeekends]);

  return (
    <Fragment>
      <Subscription
        subscription={listProceduresFromToSubscription}
        variables={{
          from: format(first(first(weeks)), 'YYYY-MM-DD'),
          to: format(last(last(weeks)), 'YYYY-MM-DD'),
          physician: physician ? parseInt(physician, 10) : undefined,
          isCanceled: false,
        }}
      >
        {({ data }) => (
          <MonthlyScheduleCalendar
            week={week}
            rooms={rooms}
            weeks={weeks}
            data={data}
            date={date}
            setEditProcedureMonthly={setEditProcedureMonthly}
            openProcedureForm={openProcedureForm}
            canUseScheduleViewProcedure={canUseScheduleViewProcedure}
          />
        )}
      </Subscription>
    </Fragment>
  );
};

export default withQuery(rooms.list)(MonthlySchedule);
