import React, { useMemo, useEffect, useState, useRef } from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import getAgeAndBmi from './pageUtils';
import MedicalPassportRow from '../../components/MedicalPassportRow';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  hepatic,
  infectious,
  musculoskeletal,
  neurological,
  oncologic,
  pain,
  psychiatric,
  pulmonary,
  renal,
  vascular,
} from '../paths';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import toHeight from '../../../../../../questionnaire/toHeight';
import toWeight from '../../../../../../questionnaire/toWeight';
import toBMI from '../../../../../../questionnaire/toBMI';
import { MedicalPassportCheckboxStyledWrapper } from './Page3';
import Barcode from '../../../Barcode';
import toHighlightsString5 from '../../../../../../questionnaire/toHighlightsString5';
import toHighlightsString4 from '../../../../../../questionnaire/toHighlightsString4';
import toHighlightsString3 from '../../../../../../questionnaire/toHighlightsString3';
import toHighlightsString2 from '../../../../../../questionnaire/toHighlightsString2';

const CommonSubPartOneOriginalVariant = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>Anesthetic Plan:</div>
    <div style={{ lineHeight: '15px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '6px' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
        <MedicalPassportCheckbox name="page4.assessment2.other" />
        <MedicalPassportField name="page4.assessment2.otherText" label="Other" />
      </div>
    </div>
  </div>
);

const CommonSubPartOneAlteredVariant = () => (
  <div style={{ lineHeight: '12px' }}>
    <div style={{ display: 'flex', }}>
      <div style={{ paddingTop: '3px' }}>Anesthetic Plan:</div>
      <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
      <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
      <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
      <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
      <div style={{ width: '25%' }}></div>
    </div>
    <div style={{ display: 'flex' }}>
      <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
      <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
      <MedicalPassportCheckbox name="page4.assessment2.other" />
      <MedicalPassportField name="page4.assessment2.otherText" label="Other" fontSize={9} />
    </div>

  </div>
);

const CommonPartOne = ({ variant }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '45% 55%',
    }}
  >
    <div style={{ border: '1px solid black', padding: '5px', borderRight: 0 }}>
      <div style={{ display: 'flex', gap: '40px' }}>
        <div>ASA Class:</div>
        <MedicalPassportCheckbox label="1" name="page4.asaClass1" />
        <MedicalPassportCheckbox label="2" name="page4.asaClass2" />
        <MedicalPassportCheckbox label="3" name="page4.asaClass3" />
        <MedicalPassportCheckbox label="4" name="page4.asaClass4" />
      </div>
      <div style={{ lineHeight: '15px' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
          <div>NPO Status Verified?</div>
          <div>
            <MedicalPassportCheckbox name="page4.NPOStatusVerifiedYes" label="Yes" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page4.NPOStatusVerifiedNo" label="No" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
          <div>Pt. Reassessed Day of Surgery?</div>
          <div>
            <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryYes" label="Yes" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryNo" label="No" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
          <div>Postoperative Plan:</div>
          <div>
            <MedicalPassportCheckbox name="page4.pacu" label="PACU/Home" />
          </div>
        </div>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid black',
        padding: '2px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Assessment:</div>
        <div style={{ lineHeight: '15px' }}>
          <MedicalPassportCheckbox name="page4.noContradictionAnesthesia" label="No contradiction to anesthesia" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MedicalPassportCheckbox name="page4.assessment1.other" />
            <MedicalPassportField name="page4.assessment1.otherText" label="Other" fontSize={9} />
          </div>
        </div>
      </div>
      {variant === 'original' ? <CommonSubPartOneOriginalVariant /> : <CommonSubPartOneAlteredVariant />}
    </div>
  </div>
);

const Page4 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );

  const pageRef = useRef(null);
  const [pageStyle, setPageStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (pageRef.current) {
        const pageHeight = pageRef.current.offsetHeight;
        if (pageHeight > 1079) {
          setPageStyle({ minHeight: 2114 });
        } else {
          setPageStyle({});
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="page4" className="page" style={{ fontSize: '11px', fontFamily: 'Arial', ...pageStyle }}>
      <div ref={pageRef}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.2px' }}>
            <h2>ANESTHESIA ASSESSMENT </h2>
            <div>{hospitalName || ''}</div>
          </div>
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '55% 45%',
              borderBottom: '1px solid black',
            }}
          >
            <div>
              <div
                style={{
                  border: '1px solid black',
                  borderTop: 0,
                  minHeight: '60px',
                  height: '100%',
                  padding: '1px 2px 0 0',
                  borderBottom: '0px',
                }}
              >
                <div
                  style={{
                    paddingBottom: '35px',
                  }}
                >
                  <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={12} />
                </div>
                <div
                  style={{
                    border: '1px solid black',
                    padding: '2px 1px 0 0',
                    borderBottom: 0,
                    borderRight: 0,
                    borderLeft: 0,
                  }}
                >
                  <MedicalPassportField
                    name="page4.activityLevel"
                    label="Activity Level"
                    path={['basicscreening', 'level of physical activity']}
                    reducer={toHighlightsString}
                    fontSize={12}
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ bottom: '5px', paddingLeft: '3px' }}>
                <div style={{ marginBottom: '2px' }}>
                  <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                  <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                  <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} />
                  <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12} />
                  <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} fontSize={12} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="cardiologist"
                    label="Cardiologist"
                    path={['demographicInformation', 'cardiologist']}
                    fontSize={12} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="procedure.pcp"
                    path={['demographicInformation', 'primaryCareDoctor']}
                    label="PCP"
                    fontSize={12} />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderLeft: '1px solid black',
              borderBottom: '1px solid black',
            }}
          >
            <MedicalPassportTextArea
              label="Med Allergies/Sensitivities"
              name="allergies1"
              path={['allergies']}
              warning={true}
              fontSize={12}
              reducer={toHighlightsString5}
              />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',
              borderLeft: '1px solid black',
              height: '20px',
            }}
          >
            <MedicalPassportCheckboxStyledWrapper
              borderLeft={'0'}
              children={
                <MedicalPassportCheckbox path={['basicscreening', 'smoking']} label="Smoking Hx" name="page3.smoking" />
              }
            />
            <MedicalPassportCheckboxStyledWrapper
              children={<MedicalPassportCheckbox path={['Psychiatric', 'drinking']} label="ETOH Hx" name="page3.etoh" />}
            />
            <MedicalPassportCheckboxStyledWrapper
              children={<MedicalPassportCheckbox path={['Psychiatric', 'drugs']} label="Drug Hx" name="page3.drugHx" />}
            />
            <MedicalPassportCheckboxStyledWrapper
              children={
                <MedicalPassportField name="ht" label="Ht" path={['demographicInformation', 'bmi']} reducer={toHeight} fontSize={10} />
              }
              fontSize={10} />
            <MedicalPassportCheckboxStyledWrapper
              children={
                <MedicalPassportField name="wt" label="Wt" path={['demographicInformation', 'bmi']} reducer={toWeight} fontSize={10} />
              }
            />
            <MedicalPassportCheckboxStyledWrapper
              children={
                <MedicalPassportField
                  name="bmi"
                  label="BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                  warning={bmi !== '-' ? bmi > 40 : false}
                />
              }
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
          >
            <div style={styles.medicationsInnerDiv}>
              <MedicalPassportTextArea
                label="Current Medications"
                name="medications1"
                path={['medications']}
                fontSize={12}
                reducer={toHighlightsString}
              />
            </div>
            <div style={{ ...styles.medicationsInnerDiv }}>
              <MedicalPassportTextArea
                label="Surgical/Anesthesia Hx"
                name="anesthesia1"
                path={['priorSurgeries']}
                fontSize={12}
                reducer={toHighlightsString}
              />
            </div>
          </div>
        </div>
        <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
          <MedicalPassportTextArea
            label={'Hx of Anesthesia Complications'}
            name="page4.hxAnesthesiaComplications"
            path={['anesthesia complications']}
            fontSize={12}
          />
        </div>
        <section style={{ breakInside: 'avoid' }}>
          <div style={styles.greyBackground}>
            <div>Review of Systems</div>
          </div>
          <div
            style={{
              border: '1px solid black',
              lineHeight: '18px',
              borderLeft: 0,
              borderRight: 0,
            }}
          >
            <MedicalPassportRow
              label="Cardiovascular"
              name="systems.cardiovascular"
              paths={cardiovascular}
              style={{ borderTop: 0 }}
              index={0}
              fontSize={12} />
            <MedicalPassportRow label="Pulmonary" name="systems.pulmonary" paths={pulmonary} index={1} fontSize={12} />
            <MedicalPassportRow label="Renal" name="systems.renal" paths={renal} index={2} fontSize={12} />
            <MedicalPassportRow label="Hepatic" name="systems.hepatic" paths={hepatic} index={3} fontSize={12} />
            <MedicalPassportRow label="Neurological" name="systems.neurological" paths={neurological} index={4} fontSize={12} />
            <MedicalPassportRow
              label="Gastrointestinal"
              name="systems.gastrointestinal"
              paths={gastrointestinal}
              index={5}
              fontSize={12} />
            <MedicalPassportRow label="Vascular" name="systems.vascular" paths={vascular} index={6} fontSize={12} />
            <MedicalPassportRow label="Endocrine/Metabolic" name="systems.endocrine2" paths={endocrine} index={7} fontSize={12} />
            <MedicalPassportRow
              label="Musculoskeletal"
              name="systems.musculoskeletal"
              paths={musculoskeletal}
              index={8}
              fontSize={12} />
            <MedicalPassportRow label="Psychiatric" name="systems.psychiatric" paths={psychiatric} index={9} fontSize={12} />
            <MedicalPassportRow label="Oncology" name="systems.ontological" paths={oncologic} index={10} fontSize={12} />
            <MedicalPassportRow label="Infectious Disease" name="systems.infDis" paths={infectious} index={11} fontSize={12} />
            <MedicalPassportRow label="Other" name="systems.other" paths={pain} index={12} fontSize={12} />
          </div>
        </section>
        <section style={{ breakInside: 'avoid' }}>
          <div style={styles.greyBackground}>Physical Exam/Labs/Anesthesia Plan</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '0.5fr 1fr 1fr 1fr 1fr 1fr 1fr',
                padding: '2px'
              }}
            >
              Vital Signs
              <MedicalPassportField name="page4.temp" label="Temp" fontSize={10} />
              <MedicalPassportField name="page4.bp" label="BP" fontSize={10} />
              <MedicalPassportField name="page4.pulse" label="Pulse" fontSize={10} />
              <MedicalPassportField name="page4.resp" label="Resp" fontSize={10} />
              <MedicalPassportField name="page4.o2Sat" label="O2Sat" fontSize={10} />
              <MedicalPassportField name="page4.giu" label="GLU" fontSize={10} />
            </div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6,1fr)' }}>
            <div style={{ border: '1px solid black', borderRight: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px' }}>
                <div>General</div>
                <MedicalPassportCheckbox
                  name="page4.generalWnl"
                  label="WNL"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
            </div>
            <div style={{ border: '1px solid black', borderRight: 0 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px' }}>
                  <div>Heart</div>
                  <MedicalPassportCheckbox
                    name="page4.heartWnl"
                    label="WNL"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MedicalPassportCheckbox
                    name="page4.heartRegularRateRhythm"
                    label="Regular Rate + Rhythm"
                    style={{ display: 'flex' }}
                  />
                </div>
              </div>
            </div>
            <div style={{ border: '1px solid black', borderRight: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '2px' }}>
                <div>Lungs</div>
                <div>
                  <MedicalPassportCheckbox
                    name="page4.lungsWnl"
                    label="WNL"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                  <MedicalPassportCheckbox
                    name="page4.lungsCta"
                    label="CTA"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
              </div>
            </div>
            <div style={{ border: '1px solid black', borderRight: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px' }}>
                <div>Teeth</div>
                <MedicalPassportCheckbox
                  name="page4.teethWnl"
                  label="WNL"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
            </div>
            <div style={{ border: '1px solid black', padding: '.4em .25em', paddingBottom: '9px' }}>
              Airway Assessment:
            </div>
            <div
              style={{
                border: '1px solid black',
                borderLeft: 0,
                padding: '2px',
              }}
            >
              <div>Other</div>
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3,1fr)',
              border: '1px solid black',
              borderBottom: 0,
              borderTop: 0,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '2px' }}>
              <div>Labs reviewed</div>
              <MedicalPassportCheckbox
                name="page4.labYes"
                label="Yes"
                style={{ display: 'flex', alignItems: 'center' }}
              />
              <MedicalPassportCheckbox name="page4.labNo" label="N/A" style={{ display: 'flex', alignItems: 'center' }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '2px' }}>
              <div>EKG/ECHO reviewed</div>
              <MedicalPassportCheckbox
                name="page4.ekgYes"
                label="Yes"
                style={{ display: 'flex', alignItems: 'center' }}
              />
              <MedicalPassportCheckbox name="page4.ekgNo" label="N/A" style={{ display: 'flex', alignItems: 'center' }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '2px' }}>
              <div>HCG</div>
              <MedicalPassportCheckbox
                name="page4.pregnantYes"
                label="Neg"
                style={{ display: 'flex', alignItems: 'center' }}
              />
              <MedicalPassportCheckbox
                name="page4.pregnantNo"
                label="N/A"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </div>
          </div>

          <CommonPartOne variant={'altered'} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <div style={{ textAlign: 'center' }}>
              <div>________________________________</div>
              <div>Anesthesia</div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div>________________________________</div>
              <div>Date/Time</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Page4;
