import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { addMinutes, differenceInHours, format, getHours, parse, setHours, setMinutes, setSeconds } from 'date-fns';
import get from 'lodash/get';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useScope } from '../../../../../hooks/useScope';
import { withTheme } from '../../../../../se/theme';
import { light } from '../../../../../theme';
import ScheduleRoomsHeaderCell from '../ScheduleRoomsHeaderCell';
import { HeaderCell, HeaderCellWithCount } from './Cell';
import ProcedureSlotPopover from './ProcedureSlotPopover';

const Slots = styled.div`
  display: grid;
  grid-column: 2 / span 1;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0.1666666667em;
  margin-bottom: 0.08333333333em;
`;

const Row = styled(Slots)`
  grid-template-rows: auto;
  margin-bottom: 0;
`;

const DayNumber = styled.div`
  font-size: 1.25em;
  color: ${withTheme(theme => theme.textColor.string())};
`;

export const DateHeader = ({ date, procedureCount = 0 }) => (
  <Row>
    <Fragment key={`${date}`}>
      <HeaderCellWithCount>
        <span>{procedureCount} procedures</span>
        <div className="day-wrapper">
          <DayNumber>{format(date, 'D')}</DayNumber>
        </div>
      </HeaderCellWithCount>
    </Fragment>
  </Row>
);

const Day = ({
  procedures = {},
  dateSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  date,
  hideRoomName,
}) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const scope = useScope();
  const createProcedureScheduleModule = scope?.hospital?.modules?.createProcedureSchedule;
  const dayStart = setSeconds(setMinutes(setHours(date, 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(date, 18), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const MIN_INTERVAL = 5; // in minutes, impacts grid precision
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const dateString = format(date, 'YYYY-MM-DD');
  const procedureCounts = {
    [dateString]: Object.keys(procedures)
      .filter(key => key.includes(dateString))
      .reduce((sum, key) => sum + procedures[key].length, 0),
  };
  const dayName = format(date, 'dddd');

  // startTime type is Time (string with format HH:mm)
  const calculateGridRow = (startTime, durationInMinutes) => {
    const procStartHour = startTime.split(':')[0];
    const procStartMinute = startTime.split(':')[1];

    const offsetFromDayStartInMinutes = (parseInt(procStartHour) - START_HOUR) * 60 + parseInt(procStartMinute);
    const rowStart = offsetFromDayStartInMinutes / MIN_INTERVAL + 1;
    const rowEnd = rowStart + durationInMinutes / MIN_INTERVAL;

    return {
      rowStart,
      rowEnd,
    };
  };

  const open = Boolean(anchor);

  const handlePopoverOpen = (event, data) => {
    setAnchor({ element: event.currentTarget, data });
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  return (
    <Box style={{ gap: '.25em', minHeight: 250, minWidth: 0 }} display="flex" flexDirection="column" flex={1}>
      {showDayNames && (
        <Fragment key={`${dayName}`}>
          <HeaderCell style={{ height: 40 }}>{dayName}</HeaderCell>
        </Fragment>
      )}

      <DateHeader date={date} procedureCount={procedureCounts[format(date, 'YYYY-MM-DD')]} />

      {!hideRoomName && <ScheduleRoomsHeaderCell rooms={rooms} />}

      <Box
        className={classes.dayGrid}
        style={{
          gridTemplateColumns: `repeat(${rooms.length}, 1fr)`,
          gridTemplateRows: `repeat(${((hours.length - 1) * 60) / MIN_INTERVAL}, 1fr)`,
        }}
      >
        {rooms.map((room, roomIndex) => {
          const proceduresX = get(procedures, `${room.id}_${format(date, 'YYYY-MM-DD')}`, []).map(procedure => ({
            ...procedure,
            start: parse(procedure.startTime),
            end: addMinutes(parse(procedure.startTime), procedure.duration),
            ...calculateGridRow(procedure.startTimeText, procedure.duration),
          }));

          return (
            <>
              {/* Empty slots */}
              {hours.map((hour, i) => {
                return (
                  <div
                    className={classes.emptySlot}
                    style={{
                      gridColumn: roomIndex + 1,
                      gridRow: (i * 60) / MIN_INTERVAL + 1,
                      gridRowEnd: (i * 60) / MIN_INTERVAL + 60 / MIN_INTERVAL + 1,
                    }}
                    key={hour}
                    onClick={
                      createProcedureScheduleModule && canUseScheduleViewProcedure
                        ? () => {
                            openProcedureForm(undefined, rooms, room, date, { startTime: hour, duration: 60 });
                          }
                        : undefined
                    }
                  ></div>
                );
              })}
              {proceduresX.map((procedure, i) => (
                <Box
                  className={classes.procedure}
                  style={{
                    gridColumn: roomIndex + 1,
                    gridRow: procedure.rowStart,
                    gridRowEnd: procedure.rowEnd,
                    cursor: 'pointer',
                  }}
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen(e, procedure)}
                  onMouseLeave={handlePopoverClose}
                  onClick={
                    canUseScheduleViewProcedure
                      ? e => {
                          e.stopPropagation();
                          createProcedureScheduleModule
                            ? openProcedureForm(procedure, rooms, room, date)
                            : setEditProcedureMonthly(procedure);
                        }
                      : undefined
                  }
                >
                  <Typography variant="textSecondary" component="div">
                    {procedure.procedureType}
                  </Typography>
                  <Typography variant="textSecondary" component="div">
                    {procedure.startTimeText}
                  </Typography>
                  <ProcedureSlotPopover
                    isOpen={open}
                    onClose={handlePopoverClose}
                    anchorElement={anchor?.element}
                    data={anchor?.data}
                  ></ProcedureSlotPopover>
                </Box>
              ))}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  dayGrid: {
    display: 'grid',
    gridTemplateRows: 'auto',
    marginBottom: '0.6em',
    flex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    minHeight: 0,
  },
  procedure: {
    gridColumn: '3 / span 1',
    gridRow: '3 / span 3',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '0.25em',
    minWidth: 0,
    overflow: 'hidden',
    padding: '0.5em',
  },
  popover: {
    backgroundColor: light.popover.background.string(),
    pointerEvents: 'none',
    boxShadow: 'none',
    maxWidth: 500,
  },
  emptySlot: {
    backgroundColor: 'transparent',
    '&:hover': {
      background: '#273a60',
    },
    transition: 'all 0.2s ease',
  },
}));

export default Day;
