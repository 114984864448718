import React from 'react';
import Header from './components/Header';
import PatientInfo from './components/PatientInfo';
import styles, { getGridHeaderChildStyles, getGridParentStyles, getGridChildStyles2 } from './styles';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import { useValue } from '../ValueContext';
import MedicationReconciliationField from '../components/MedicationReconciliationField';
import MedicalPassportRadioGroup from '../components/MedicalPassportRadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import { CSSProperties } from 'react';
import get from 'lodash/get';
import Footer from './components/Footer';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicationTextArea from '../components/MedicationTextArea';

const Page3 = () => {
  const { questionnaireAnswers } = useValue();

  // allergy
  function yesNoAllergies() {
    const allergyQ1 = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[0]', false);
    const allergyQ2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
    const allergyQ3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

    return allergyQ1 || allergyQ2 || allergyQ3;
  }
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }
  let allergySize = 15;

  if (allergyName.length > 15) {
    allergySize = allergyName.length;
  }

  return (
    <div id="page3" className="page" style={{...styles.root, fontFamily:'Arial', gap:'10px'}}>
      <Header pageNumber={4} pageCount={12} />
      <div><b>ALLERGY RECORD</b></div>
      <PatientInfo />
      <div style={getGridParentStyles(2, true)}>
        <div style={{ ...getGridHeaderChildStyles(2), textAlign: 'left',fontSize:'16px' }}>
          Allergies (Patient Answered{' '}
          <span>
            <input
              type="checkbox"
              name="allergiesYes"
              label="Yes"
              style={{ display: 'inline' }}
              checked={yesNoAllergies()}
            />
          </span>
          )
        </div>
        <div style={{ ...getGridHeaderChildStyles(1), textAlign: 'center' }}>
          Name of Allergy
          {[...Array(allergySize)].map((_, i) => (
            <>
              <div>
                <MedicationReconciliationField
                  name={`allergy.r${i}.c0`}
                  value={i === 0 && allergyName.length === 0 ? 'NO KNOWN DRUG ALLERGIES' : allergyName[i]}
                />
              </div>
            </>
          ))}
        </div>
        <div style={{ ...getGridHeaderChildStyles(1), textAlign: 'center' }}>
          Reaction of sensitivity
          {[...Array(allergySize)].map((_, i) => (
            <>
              <div>
                <MedicationReconciliationField name={`reaction.r${i}.c0`} value={reaction[i]} />
              </div>
            </>
          ))}
        </div>
      </div>
      <div style={{ textAlign: 'left', fontSize:'14px' }}>
        <br />
        Pre-Op Nurse Acknowledgement: _________________________________
        <br />
        <br />
        <br />
        Circulating Nurse Acknowledgement: _________________________________
        <br />
        <br />
        <br />
        PACU Nurse Acknowledgement: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
        <br />
        <br />
        <br />
        Relief RN: _________________________________
      </div>
    </div>
  );
};

const medicationTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '40% 10% 10% 20% 20%' };
const prescriptionTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '50% 50%' };

export default Page3;
