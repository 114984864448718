import React from 'react';
import { Link, Typography } from '@material-ui/core';

export default ({ children }) => (
  <Typography>
    <Link
      onClick={e => {
        e.stopPropagation();
      }}
      href={`tel:${children}`}
      target="_blank"
    >
      {children}
    </Link>
  </Typography>
);
