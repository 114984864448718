import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Icon } from '../../SchedulePage';
import AnesthesiaType from './AnesthesiaType';
import AnesthesiologistName from './AnesthesiologistName';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

const PhysicianName = styled.div<{ hasError?: boolean }>`
  display: ${props => (props.hasError ? 'flex' : 'initial')};
  align-items: center;
  white-space: nowrap;
  color: ${props => (props.hasError ? '#FF8900' : 'inherit')};
  line-height: 1.0625em;
  letter-spacing: -0.015em;
  span {
    font-size: 0.75em;
  }
`;

export const PhysicianIndicator = styled.div<{ color?: string }>`
  width: 0.4em;
  height: 0.4em;
  border-radius: 0.75em;
  background-color: ${props => (props.color ? props.color : 'rgba(255,255,255,.1)')};
  margin-right: 0.3em;
  flex-shrink: 0;
`;

const Anes = styled.div<{ inline?: boolean; condensView?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: ${props => (props.inline ? '0 1 auto' : '1 0 auto')};
  justify-content: ${props => (props.condensView ? 'space-between' : 'flex-end')};
  background-color: ${props => (props.condensView ? 'rgba(255,255,255,0.1)' : undefined)};
  padding: ${props => (props.condensView ? '0px 5px 0px 3px;' : undefined)};
  margin-bottom: ${props => (props.condensView ? '0' : undefined)};
  font-size: ${props => (props.condensView ? '1.33em' : 'inherit')};
  line-height: inherit;
`;

export const Physician = ({
  color,
  physicianName,
  inline,
}: {
  color: string;
  physicianName?: string;
  inline?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.physicianWrapper, { [classes.physicianWrapperInline]: inline })}>
      {physicianName && <PhysicianIndicator color={color} />}
      <PhysicianName hasError={!physicianName}>
        {physicianName ? (
          physicianName
        ) : (
          <Fragment>
            <Icon style={{ color: '#FF8900', marginRight: '.25em' }}>error_outline</Icon>{' '}
            <div>Physician Unassigned</div>
          </Fragment>
        )}
      </PhysicianName>
    </Box>
  );
};

export const PhysicianAnesthesiologistView = ({
  physicianName,
  inline,
}: {
  physicianName?: string;
  inline?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.physicianWrapper, { [classes.physicianWrapperInline]: inline })}>
      <PhysicianName style={{ fontWeight: 800 }} hasError={!physicianName}>
        {physicianName ? (
          physicianName
        ) : (
          <Fragment>
            <Icon style={{ color: '#FF8900', marginRight: '.25em' }}>error_outline</Icon>{' '}
            <div>Physician Unassigned</div>
          </Fragment>
        )}
      </PhysicianName>
    </Box>
  );
};

export const Anesthesiologist = ({
  date,
  procedureId,
  editableAnesthesiologist,
  anesthesiologistName,
  anesthesiaType,
  inline,
  style,
  condensView,
}: {
  date?: Date;
  procedureId: number;
  editableAnesthesiologist?: boolean;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  inline?: boolean;
  condensView?: boolean;
  style?: React.CSSProperties;
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.anesthesiologist, {
        [classes.condensedView]: condensView,
        [classes.inline]: inline,
      })}
      style={style}
      onClick={e => e.stopPropagation()}
    >
      <AnesthesiologistName
        date={date}
        procedureId={procedureId}
        editableAnesthesiologist={editableAnesthesiologist}
        anesthesiologistName={anesthesiologistName}
        condensView={condensView}
      />
      <AnesthesiaType
        procedureId={procedureId}
        editableAnesthesiologist={editableAnesthesiologist}
        anesthesiaType={anesthesiaType}
      />
    </Box>
  );
};

export const AnesthesiologistAnesthesiologistView = ({
  date,
  procedureId,
  editableAnesthesiologist,
  anesthesiologistName,
  inline,
  style,
  condensView,
}: {
  date?: Date;
  procedureId: number;
  editableAnesthesiologist?: boolean;
  anesthesiologistName?: string;
  inline?: boolean;
  condensView?: boolean;
  style?: React.CSSProperties;
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.anesthesiologist, {
        [classes.condensedView]: condensView,
        [classes.inline]: inline,
      })}
      style={style}
      onClick={e => e.stopPropagation()}
    >
      <AnesthesiologistName
        date={date}
        procedureId={procedureId}
        editableAnesthesiologist={editableAnesthesiologist}
        anesthesiologistName={anesthesiologistName}
        condensView={condensView}
      />
    </Box>
  );
};

export const Personnel = ({
  date,
  procedureId,
  color,
  physicianName,
  editableAnesthesiologist,
  anesthesiologistName,
  anesthesiaType,
  style,
  condensView,
}: {
  date?: Date;
  procedureId: number;
  color: string;
  editableAnesthesiologist: boolean;
  anesthesiologistName?: string;
  physicianName: string;
  anesthesiaType: string;
  condensView?: boolean;
  style: React.CSSProperties;
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.wrapper, {
        [classes.hasError]: !physicianName,
        [classes.condensedView]: condensView,
      })}
      style={style}
    >
      <Physician color={color} physicianName={physicianName} />
      {!condensView && (
        <Anesthesiologist
          date={date}
          procedureId={procedureId}
          editableAnesthesiologist={editableAnesthesiologist}
          anesthesiologistName={anesthesiologistName}
          anesthesiaType={anesthesiaType}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontWeight: 400,
    fontSize: '1.333333em',
    color: theme.palette.text.primary,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    marginBottom: 1,
    padding: '0.375em',
  },
  hasError: {
    backgroundColor: alpha(theme.palette.error.main, 0.25),
  },
  condensedView: {
    padding: '0.375em 0 0.375em 0.375em',
  },
  anesthesiologist: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flex: '1 0 auto',
    justifyContent: 'space-between',
    lineHeight: 'inherit',
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    borderRadius: '0.25em',
  },
  inline: {
    flex: '0 1 auto',
  },
  condensView: {
    justifyContent: 'flex-end',
    padding: '0px 5px 0px 3px',
    marginBottom: 0,
    fontSize: '1.33em',
  },
  physicianWrapper: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    marginRight: '0.375em',
    overflow: 'visible',
  },
  physicianWrapperInline: {
    flex: '0 1 auto',
  },
}));
