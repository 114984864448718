import { FormFulfillmentCell } from '../columns';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const FormFulfillmentWrapper = styled.div`
  flex: 1;
  padding-right: 2rem;
  margin-right: 2rem;
`;

const QuestionnaireFulfilment: FC<{ formProgress: number; signatureProgress?: number; hasSignedForms?: boolean }> = ({
  formProgress,
  signatureProgress,
  hasSignedForms,
}) => (
  <FormFulfillmentWrapper>
    <Box flex={1}>
      <Typography variant="body1" color="textSecondary">
        Form Fulfillment
      </Typography>
      <FormFulfillmentCell percentage={isFinite(formProgress) ? roundQuestionnaireProgress(formProgress) : 0} />
    </Box>
    {hasSignedForms && (
      <Box flex={1} mt={3}>
        <Typography variant="body1" color="textSecondary">
          Signature Fulfillment
        </Typography>
        <FormFulfillmentCell
          percentage={isFinite(signatureProgress || 0) ? roundQuestionnaireProgress(signatureProgress || 0) : 0}
        />
      </Box>
    )}
  </FormFulfillmentWrapper>
);

export default QuestionnaireFulfilment;

function roundQuestionnaireProgress(progress: number): number {
  const p = progress * 100;
  return p >= 50 ? Math.floor(p) : Math.ceil(p);
}
